import {
	type Application,
	useApplicationByReferenceLazyQuery,
} from 'api/core/generated';
import { AppApiVersions, AppSearchParams } from 'app-constants';
import { useSearchParams } from 'react-router-dom';

export const useGetApplicationByReference = (cacheFirst = false) => {
	const [searchParams] = useSearchParams();
	const reference_key = searchParams.get(AppSearchParams.referenceKey) ?? '';

	const [getApplication, { data, loading, error }] =
		useApplicationByReferenceLazyQuery({
			context: { apiVersion: AppApiVersions.core },
			fetchPolicy: cacheFirst ? 'cache-first' : 'network-only',
		});

	return {
		application: (data?.application ?? {}) as Application,
		applicationLoading: loading,
		applicationError: error,
		applicationReferenceKey: reference_key,
		getApplication: () => getApplication({ variables: { reference_key } }),
	};
};
