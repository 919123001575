export const LOCIZE_LANGUAGE_DETECTION_PLACES = {
	localStorage: 'localStorage',
	queryString: 'querystring',
};

export enum LocizeNamespaces {
	common = 'common',
	offer = 'offer',
	success = 'success',
	reject = 'reject',
	signing = 'signing',
	pending = 'pending',
	contact = 'contact',
	purposeOfLoan = 'purpose-of-loan',
	contactExtra = 'contact-extra',
	errors = 'errors',
	login = 'login',
	checkout = 'checkout',
	accountScoring = 'account-scoring',
	user = 'user',
	spouseConsent = 'spouse-consent',
	emtaConsent = 'emta-consent',
	schedule = 'schedule',
	afterDirectPayment = 'after-direct-payment',
}

export const LOCIZE_ERROR_TRANSLATION_KEYS = {
	errorCode_1201: '1201', // Mobile authentication was not successful
	errorCode_1202: '1202', // Mobile signing was not successful
	errorCode_1203: '1203', // SSL certificate verification failure
	errorCode_1204: '1204', // The provided authentication certificate or issuer is not valid
	errorCode_1205: '1205', // Wrong username or password
	errorCode_1206: '1206', // Signing by ID card was not successful
	errorCode_1208: '1208', // Smart ID authentication was not successful
	errorCode_1214: '1214', // You are using the basic version of Smart-ID
	errorCode_1217: '1217', // You do not have the Mobile-ID service activated or the certificates are not valid
	errorCode_1219: '1219', // A technical error occurred
	errorCode_1220: '1220', // Mobile-ID authentication was canceled from the phone
	errorCode_1221: '1221', // A technical error occurred
	errorCode_1222: '1222', // Cannot establish a connection with your phone
	errorCode_1223: '1223', // Your phone's SIM card's settings do not match with the Mobile-ID service
	errorCode_1304: '1304', // This application couldn't be retrieved
	errorCode_1305: '1305', // The purchase amount is not within the allowed range
	errorCode_1502: '1502', // The new password and password confirmation do not match
	errorCode_1503: '1503', // The new password is too short
	errorCode_1507: '1507', // The document number is not correct
	errorCode_2401: '2401', // We apologize, but the service is currently unavailable
	errorCode_2804: '2804', // Unfortunately you cannot withdraw credit
	errorCode_2805: '2805', // Withdrawals cannot be made at this time
	generalError: 'general', // Something went wrong!
	validationError: 'validation', // Please fill out all required fields
	uploadFileQuantity: 'upload-file-quantity',
	uploadFileFormat: 'upload-file-format',
	uploadFileMaxSize: 'upload-file-max-size',
	authenticationErrorLabel: 'authentication-error-label',
	idCardLoginError: 'id-card-login-error',
};

export const LOCIZE_LOGIN_TRANSLATION_KEYS = {
	loginButtonLabel: 'login-button-label',
	smartIdMethodButtonLabel: 'smart-id-method-button-label',
	mobileIdMethodButtonLabel: 'mobile-id-method-button-label',
	idCardMethodButtonLabel: 'id-card-method-button-label',
	passwordMethodButtonLabel: 'password-method-button-label',
	banklinkMethodButtonLabel: 'banklink-method-button-label',
	eParakstsMobileMethodButtonLabel: 'eparaksts-mobile-method-button-label',
	eParakstsSmartCardMethodButtonLabel:
		'eparaksts-smart-card-method-button-label',
	passwordFieldLabel: 'password-field-label',
	userNameFieldLabel: 'username-field-label',
	idCodeFieldLabel: 'id-code-field-label',
	idCodeFieldLabelLV: 'id-code-field-label-lv',
	emailFieldLabel: 'email-field-label',
	loginConfirmationProcessingDisclaimer:
		'login-confirmation-processing-disclaimer',
	customerAwaitingPatienceDisclaimer: 'customer-awaiting-patience-disclaimer',
	customerAwaitingEmailAddressDisclaimer:
		'customer-awaiting-email-address-disclaimer',
	customerAwaitingLoginLinkDisclaimer:
		'customer-awaiting-login-link-disclaimer',
	loginPageHeading: 'login-page-heading',
	phoneFieldLabel: 'phone-field-label',
	idCardLoginDisclaimer: 'id-card-login-disclaimer',
	smartIdFullVersionDisclaimer: 'smart-id-full-version-disclaimer',
};

export const LOCIZE_CHECKOUT_TRANSLATION_KEYS = {
	installmentsPeriodSelectTitle: 'installments-period-select-label',
	downPaymentTooltip: 'down-payment-tooltip',
	downPaymentFieldLabel: 'down-payment-field-label',
	purchaseAmount: 'purchase-amount',
	smallLoanMonthlyPaymentDisclaimer: 'sl-monthly-payment-disclaimer',
	smallLoanMarketingHeading: 'sl-marketing-heading',
	smallLoanMarketingText: 'sl-marketing-text',
	creditLineMonthlyPaymentDisclaimer: 'cl-monthly-payment-disclaimer',
	creditLinePageTitle: 'cl-page-title',
	creditLinePageSubtitle: 'cl-page-subtitle',
	creditLineInfoTitle: 'cl-info-title',
	creditLineInfoStep1Title: 'cl-info-step1-title',
	creditLineInfoStep1Subtitle: 'cl-info-step1-subtitle',
	creditLineInfoStep2Title: 'cl-info-step2-title',
	creditLineInfoStep2Subtitle: 'cl-info-step2-subtitle',
	creditLineInfoStep3Title: 'cl-info-step3-title',
	creditLineInfoStep3Subtitle: 'cl-info-step3-subtitle',
	exactMonthlyPaymentDisclaimerTitle: 'exact-monthly-payment-disclaimer-title',
	noInterestOrFeesDisclaimerTitle: 'no-interest-or-fees-disclaimer-title',
	contractFeeTitle: 'contract-fee-title',
	smallLoanMarketingTitle: 'small-loan-marketing-title',
	fastLoanMarketingTitle: 'fast-loan-marketing-title',
	renovationLoanMarketingTitle: 'renovation-loan-marketing-title',
	vehicleLoanMarketingTitle: 'vehicle-loan-marketing-title',
	smallLoanMarketingSubtitle: 'small-loan-marketing-subtitle',
	fastLoanMarketingSubtitle: 'fast-loan-marketing-subtitle',
	renovationLoanMarketingSubtitle: 'renovation-loan-marketing-subtitle',
	vehicleLoanMarketingSubtitle: 'vehicle-loan-marketing-subtitle',
	smallLoanGroupCampaignText: 'small-loan-group-campaign-text',
	creditLimitIncreaseTitle: 'clr-title',
	creditLimitIncreaseDisclaimer: 'clr-disclaimer',
	smallLoanGroupNoManagementCampaignText:
		'small-loan-group-no-management-campaign-text',
	specialOfferDescriptionMonth: 'special-offer-description-month',
	specialOfferDescriptionMonths: 'special-offer-description-months',
	paymentScheduleTitle: 'payment-schedule-title',
};

export const LOCIZE_USER_TRANSLATION_KEYS = {
	politicalExposurePrefix: 'political-exposure-',
};

export const LOCIZE_SIGNING_TRANSLATION_KEYS = {
	acceptTermsOfContractWithContractLink:
		'accept-terms-of-contract-with-contract-link',
	acceptTermsOfContractAlternativeWithContractLink:
		'accept-terms-of-contract-alternative-with-contract-link',
	makingDownPaymentDisclaimer: 'making-downpayment-disclaimer',
	anotherLoginMethodDisclaimer: 'another-login-method-disclaimer',
	viewContract: 'view-contract',
	downPaymentFieldLabel: 'down-payment-field-label',
	signingPageHeading: 'signing-page-heading',
	creditLimitSigningHeading: 'credit-line-signing-heading',
	signContract: 'sign-contract',
	signContractWithSmartId: 'sign-contract-with-smart-id',
	signContractWithIdCard: 'sign-contract-with-id-card',
	confirmSignature: 'confirm-signature',
	creditLineSigningWithdrawDisclaimer: 'cl-signing-withdraw-disclaimer',
	maxLimitOfferDisclaimer: 'max-limit-offer-disclaimer',
	moreThanMaxLoanDisclaimer: 'more-than-max-loan-disclaimer',
	signContractWithMobileId: 'sign-contract-with-mobile-id',
	signContractWithBanklink: 'sign-contract-with-banklink',
	signingContractLabel: 'signing-contract-label',
	lvAdditionalDisclaimer: 'lv-additional-disclaimer',
	contractFeeTitle: 'contract-fee-title',
	paymentScheduleTitle: 'payment-schedule-title',
	instantPayoutMethodTypeTitle: 'payout-method-type.instant.title',
	instantPayoutMethodTypeDescription: 'payout-method-type.instant.description',
	regularPayoutMethodTypeTitle: 'payout-method-type.regular.title',
	regularPayoutMethodTypeDescription: 'payout-method-type.regular.description',
	newsletterAgreementText: 'newsletter-agreement.text',
	newsletterAgreementPrivacyPolicy: 'newsletter-agreement.privacy-policy',
	installmentsPeriodSelectLabel: 'installments-period-select-label',
	adjustedOfferActionButton: 'adjusted-offer.action-button',
	adjustedOfferTitle: 'adjusted-offer.title',
	adjustedOfferDescription: 'adjusted-offer.description',
};

export const LOCIZE_CONTACT_TRANSLATION_KEYS = {
	termsAndConditionsCheckboxLabel: 'terms-conditions-checkbox-label',
	postalCodeFieldLabel: 'postal-code-field-label',
	phoneFieldLabel: 'phone-field-label',
	politicallyExposedPersonPlaceholderLabel: 'pep-placeholder-label',
	politicallyExposedPersonFieldLabel: 'pep-field-label',
	politicallyExposedPersonTooltipLabel: 'pep-tooltip-label',
	politicallyExposedPersonDisclaimerLabel: 'pep-disclaimer-label',
	newsletterCheckboxLabel: 'newsletter-checkbox-label',
	nameFieldLabel: 'name-field-label',
	cityFieldLabel: 'city-field-label',
	emailFieldLabel: 'email-field-label',
	addressFieldLabel: 'address-field-label',
	ibanFieldLabel: 'iban-field-label',
	ibanTooltipLabel: 'iban-tooltip-label',
};

export const LOCIZE_COMMON_TRANSLATION_KEYS = {
	test: 'test',
	continue: 'continue',
	loginToContinue: 'login-to-continue',
	submit: 'submit',
	confirm: 'confirm',
	confirmed: 'confirmed',
	footerTopText: 'footer-top-text',
	footerBotText: 'footer-bot-text',
	contactInfoDisclaimer: 'contact-info-disclaimer',
	contactUsDisclaimer: 'contact-us-disclaimer',
	contactUsAffiliateApplicationSigningDisclaimer:
		'contact-us-affiliate-application-signing-disclaimer',
	installmentTableDateColumnName: 'installment-table-date-column-name',
	installmentTableTotalColumnName: 'installment-table-total-column-name',
	pageTitleSmallLoan: 'page-title-small-loan',
	pageTitleFastLoan: 'page-title-fast-loan',
	pageTitleRenovationLoan: 'page-title-renovation-loan',
	pageTitleVehicleLoan: 'page-title-vehicle-loan',
	pageTitleHP: 'page-title-hire-purchase',
	pageTitleFreeHP: 'page-title-free-hire-purchase',
	pageTitleCL: 'page-title-credit-line',
	chooseLanguage: 'choose-language',
	chooseFileToUpload: 'choose-file-to-upload',
	loanAmountTitle: 'loan-amount-title',
	loanAmountTip: 'loan-amount-tip',
	monthlyPaymentTitle: 'monthly-payment-title',
	monthlyInterestTitle: 'monthly-interest-title',
	signingFeeTitle: 'signing-fee-title',
	contractFeeTitle: 'contract-fee-title',
	paymentScheduleTitle: 'payment-schedule-title',
	managementFeeTitle: 'management-fee-title',
	yourMonthlyPaymentTitle: 'your-monthly-payment-title',
	yourMaximumMonthlyPaymentTitle: 'your-maximum-monthly-payment-title',
	yourMaximumAvailableLimitTitle: 'your-maximum-available-limit-title',
	periodTitle: 'period-title',
	monthTitle: 'month-title',
	monthsTitle: 'months-title',
	estoAccount: 'esto-account',
	estoAccountDisclaimer: 'esto-account-disclaimer',
	estoAccountDisclaimerWithCreditAmount:
		'esto-account-disclaimer-with-credit-amount',
	logoutButtonLabel: 'logout-button-label',
	dontClosePageLabel: 'dont-close-page-label',
	pinConfirmationCodeDisclaimerPin: 'pin-confirmation-code-disclaimer-pin',
	chooseOptionLabel: 'choose-option-label',
	signLabel: 'sign-label',
	successLabel: 'success-label',
	appInProcessLabel: 'app-in-process-label',
	cancelLabel: 'cancel-label',
	defaultDelayedMessageTitle: 'default-delayed-message-title',
	defaultDelayedMessageText: 'default-delayed-message-text',
	specialOfferTitle: 'special-offer-title',
	specialOfferDescriptionMonths: 'special-offer-description-months',
	specialOfferDescriptionMonth: 'special-offer-description-month',
	specialOfferDescriptionDays: 'special-offer-description-days',
	checkIncomeDisclaimer: 'check-income-disclaimer',
	specialOfferPaymentLeaveDescription:
		'special-offer-payment-leave-description',
	specialOfferPaymentLeaveSmallLoanDescription:
		'special-offer-payment-leave-small-loan-description',
	specialOfferPaymentLeaveTitle: 'special-offer-payment-leave-title',
	firstPaymentDateTitle: 'first-payment-date-title',
};

export const LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS = {
	incomeLiabilitiesDisclaimer: 'income-liabilities-disclaimer',
	accessBank: 'access-bank',
	analysingBankStatement: 'analysing-bank-statement',
	finalising: 'finalising',
	followInstructionsDisclaimer: 'follow-instructions-disclaimer',
	followInstructionsInTab: 'follow-instructions-in-tab',
	makingDecision: 'making-decision',
	selectBankDisclaimer: 'select-bank-disclaimer',
	talkingToBank: 'talking-to-bank',
	uploadStatementDisclaimer: 'upload-statement-disclaimer',
	uploadStatementDisclaimer1: 'upload-statement-disclaimer-1',
	verifyingFinancialInfo: 'verifying-financial-info',
};

export const LOCIZE_REJECT_TRANSLATION_KEYS = {
	faq: 'faq',
	reasonsDisclaimer: 'reasons-disclaimer',
	defaultRejectionDisclaimer: 'default-rejection-disclaimer',
	returnToMerchant: 'return-to-merchant',
	smallLoanRejectionHeading: 'small-loan-rejection-heading',
	smallLoanRejectionCredyHeading: 'small-loan-rejection-credy-heading',
	creditLineRejectionCredyHeading: 'credit-line-rejection-credy-heading',
	creditLineRejectionHeading: 'credit-line-rejection-heading',
	smallLoanDefaultRejectionDisclaimer:
		'small-loan-default-rejection-disclaimer',
	smallLoanCredyRejectionDisclaimer: 'small-loan-credy-rejection-disclaimer',
	creditLineCredyRejectionDisclaimer: 'credit-line-credy-rejection-disclaimer',
	creditLineDefaultRejectionDisclaimer: 'credit-line-rejection-disclaimer',
	credyDisclaimer: 'credy-disclaimer',
	credyHeading: 'credy-heading',
	rejectReasonsDisclaimer: 'reject-reasons-disclaimer',
};

export const LOCIZE_SUCCESS_TRANSLATION_KEYS = {
	afterMakingDownpaymentDisclaimer: 'after-making-down-payment-disclaimer',
	backToMerchant: 'back-to-merchant',
	clickButtonDisclaimer: 'click-button-disclaimer',
	proceedToWithdrawal: 'proceed-to-withdrawal',
	makeDownpayment: 'make-down-payment',
	signStandingPayment: 'sign-standing-payment',
	successfulSigningDisclaimer: 'successful-signing-disclaimer',
	successfulSmallLoanSigningHeading: 'successful-sl-signing-heading',
	successfulCreditLineSigningHeading: 'successful-cl-signing-heading',
	successfulSmallLoanSigningDisclaimer: 'successful-sl-signing-disclaimer',
	successfulSmallLoanSigningWithSPDisclaimer:
		'successful-sl-signing-disclaimer-with-standing-payment',
	successfulSmallLoanSigningWithoutSPDisclaimer:
		'successful-sl-signing-disclaimer-without-standing-payment',
	successfulAffiliateSmallLoanSigningDisclaimer:
		'successful-affiliate-sl-signing-disclaimer',
	successfulCreditLineSigningDisclaimer: 'successful-cl-signing-disclaimer',
	successfulInstantPayoutDisclaimer:
		'successful-sl-signing-disclaimer-instant-payout',
};

export const LOCIZE_CONTACT_EXTRA_TRANSLATION_KEYS = {
	financialDataDisclaimer: 'financial-data-disclaimer',
	perMonthPlaceholder: 'per-month-placeholder',
	netIncomeLabel: 'net-income-label',
	netIncomeTooltipLabel: 'net-income-tooltip-label',
	totalExpensesLabel: 'total-expenses-label',
	expenditureLabel: 'expenditure-label',
	expenditureTooltipLabel: 'expenditure-tooltip-label',
	dependentsLabel: 'dependents-label',
	livingExpensesPerMonthLabel: 'living-expenses-per-month-label',
	livingExpensesPerMonthTooltipLabel: 'living-expenses-per-month-tooltip-label',
	employmentDateLabel: 'employment-date-label',
	employmentDateFirstOptionLabel: 'employment-date-first-option-label',
	employmentDateSecondOptionLabel: 'employment-date-second-option-label',
	employmentDateThirdOptionLabel: 'employment-date-third-option-label',
	planningNewDebtsLabel: 'planning-new-debts-label',
	planningNewDebtsCheckboxLabel: 'planning-new-debts-checkbox-label',
	futureReducedEarningsLabel: 'future-reduced-earnings-label',
	futureReducedEarningsCheckboxLabel: 'future-reduced-earnings-checkbox-label',
	ultimateBeneficialOwnerLabel: 'ultimate-beneficial-owner-label',
	ultimateBeneficialOwnerTooltip: 'ultimate-beneficial-owner-tooltip',
	instructionsSendingMethodLabel: 'instructions-sending-method-label',
	instructionsSendingMethodPlaceholder:
		'instructions-sending-method-placeholder',
	phoneOptionLabel: 'phone-option-label',
	emailOptionLabel: 'email-option-label',
	spouseEmailFieldLabel: 'spouse-email-field-label',
	spousePhoneFieldLabel: 'spouse-phone-field-label',
	personalSectionTitle: 'personal-section-title',
	spouseSectionTitle: 'spouse-section-title',
	spouseSectionIntro: 'spouse-section-intro',
	spouseSectionSubmitButton: 'spouse-section-submit-button',
	instructionsSentDisclaimer: 'instructions-sent-disclaimer',
	changeInstructionsButton: 'change-instructions-button',
	occupationCategoryFieldLabel: 'occupation-category-field-label',
	occupationCategoryFieldPlaceholder: 'occupation-category-field-placeholder',
	legalPersonFieldPlaceholder: 'legal-person-field-placeholder',
	addLegalPersonFieldLabel: 'add-legal-person-field-label',
	occupationCategoryPrefix: 'occupation-category',
	overdueDebtLabel: 'overdue-debt-label',
	overdueDebtCheckboxLabel: 'overdue-debt-checkbox-label',
	overdueDebtTooltipLabel: 'overdue-debt-tooltip-label',
};

export const LOCIZE_PENDING_TRANSLATION_KEYS = {
	pendingHeading: 'pending-heading',
	pendingDisclaimer: 'pending-disclaimer',
	spouseConsentPendingDisclaimer: 'spouse-consent-pending-disclaimer',
	outsideWorkingHoursPendingDisclaimer:
		'outside-working-hours-pending-disclaimer',
	checkIncomeButtonLabel: 'check-income-button-label',
	manualScoringNeededDisclaimer: 'manual-scoring-needed-disclaimer',
};

export const LOCIZE_LOAN_TYPE_TRANSLATION_KEYS = {
	pageTitle: 'page-title',
	personalLoan: 'personal-loan',
	personalLoanDescription: 'personal-loan-description',
	familyLoan: 'family-loan',
	familyLoanDescription: 'family-loan-description',
	pendingDisclaimer: 'family-loan-description',
};

export const LOCIZE_SPOUSE_CONSENT_TRANSLATION_KEYS = {
	spouseConsentTitle: 'spouse-consent-title',
	spouseConsentSubtitle: 'spouse-consent-subtitle',
	chooseSigningMethodsLabel: 'choose-signing-methods-label',
	spousePendingHeading: 'spouse-pending-heading',
};

export const LOCIZE_EMTA_CONSENT_TRANSLATION_KEYS = {
	emtaConsentTitle: 'emta-consent-title',
	emtaConsentDescription: 'emta-consent-description',
	emtaConsentButtonTitle: 'emta-consent-button-title',
	emtaConsentButtonDescription: 'emta-consent-button-description',
	accountScoringButtonTitle: 'account-scoring-button-title',
	accountScoringButtonDescription: 'account-scoring-button-description',
};

export const LOCIZE_SCHEDULE_TRANSLATION_KEYS = {
	regularScheduleTitle: 'regular-schedule-title',
	regularScheduleDescription: 'regular-schedule-description',
	xScheduleDescription: 'x-schedule-description',
	payLaterScheduleDescription: 'pay-later-schedule-description',
	payScheduleDescription: 'pay-schedule-description',
	choosePaymentMethod: 'choose-payment-method',
};

export const LOCIZE_AFTER_DIRECT_PAYMENT_TRANSLATION_KEYS = {
	newsletterModalActionBtn: 'newsletter-modal-action-btn',
	newsletterModalDisclaimer: 'newsletter-modal-disclaimer',
	newsletterModalTitle: 'newsletter-modal-title',
	paymentFailureBackToMerchantBtn: 'payment-failure-back-to-merchant-btn',
	paymentFailureDisclaimer: 'payment-failure-disclaimer',
	paymentFailureEstoAccountBtn: 'payment-failure-esto-account-btn',
	paymentFailureRetryBtn: 'payment-failure-retry-btn',
	paymentFailureTitle: 'payment-failure-title',
	paymentSuccessDisclaimer: 'payment-success-disclaimer',
	paymentSuccessEstoAccountBtn: 'payment-success-esto-account-btn',
	paymentSuccessBackToMerchantBtn: 'payment-success-back-to-merchant-btn',
	paymentSuccessNewsletterBtn: 'payment-success-newsletter-btn',
	paymentSuccessSwitchBtn: 'payment-success-switch-btn',
	paymentSuccessTitle: 'payment-success-title',
	emailFieldPlaceholder: 'email-field-placeholder',
	newsletterSubscriptionSuccess: 'newsletter-subscription-success',
};

export const LOCIZE_OFFER_TRANSLATION_KEYS = {
	disclaimerTitle: 'disclaimer-title',
	disclaimerDescription: 'disclaimer-description',
	disclaimerHirePurchaseDescription: 'disclaimer-hire-purchase-description',
	purchaseAmount: 'purchase-amount',
	paymentPeriod: 'payment-period',
	monthlyPaymentTitle: 'monthly-payment-title',
};
