import {
	type AppLanguages,
	ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP,
} from 'app-constants';
import { zendeskChatKeyCode } from 'environment';
import i18n from 'i18next';
import scssVars from 'styles/export.module.scss';

export const changeZendeskLocale = (locale: string): void => {
	if (!zendeskChatKeyCode) return;

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	(window as any).zE('webWidget', 'setLocale', locale);
};

export const setZendeskChatSelectSettings = (locale: string): void => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	(window as any).zE('webWidget', 'updateSettings', {
		webWidget: {
			chat: {
				departments: {
					select:
						ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP[locale as AppLanguages],
				},
			},
			color: {
				launcher: scssVars.colorWhite,
				launcherText: scssVars.colorPrimary,
				header: scssVars.colorPrimary,
			},
		},
	});
};

export const loadZendeskWidget = (locale = i18n.language): void => {
	if (!zendeskChatKeyCode) return;

	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.id = 'ze-snippet';
	script.async = true;
	script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatKeyCode}`;
	script.addEventListener('load', () => {
		changeZendeskLocale(locale);
		setZendeskChatSelectSettings(locale);
	});
	document.getElementsByTagName('body')[0].appendChild(script);
};
