import type { ReactNode } from 'react';

type AppExternalLinkProps = {
	to: string;
	className?: string;
	openInNewTab?: boolean;
	disabled?: boolean;
	children?: ReactNode;
};

const AppExternalLink = ({
	to,
	className,
	openInNewTab = true,
	children,
}: AppExternalLinkProps) => (
	<a
		className={className}
		href={to}
		rel="noreferrer"
		target={openInNewTab ? '_blank' : ''}
	>
		{children}
	</a>
);

export default AppExternalLink;
