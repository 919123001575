import c from 'classnames';
import styles from './AppOpenCloseButton.module.scss';

type AppOpenCloseButtonProps = {
	isOpen: boolean;
	onClick: () => void;
};

const AppOpenCloseButton = ({ isOpen, onClick }: AppOpenCloseButtonProps) => {
	return (
		<button
			className={c(styles['open-close-button'], {
				[styles['open-close-button--is-open']]: isOpen,
			})}
			onClick={onClick}
			type="button"
		>
			<div className={styles.icon} />
		</button>
	);
};

export default AppOpenCloseButton;
