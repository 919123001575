import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const BanklinkIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 33"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M29.2245 26.3111H3.77546C2.90701 26.3103 2.07437 25.9649 1.46028 25.3508C0.846196 24.7367 0.500837 23.9041 0.5 23.0356V8.96845C0.500837 8.1 0.846196 7.26735 1.46028 6.65327C2.07437 6.03918 2.90701 5.69383 3.77546 5.69299H29.2245C30.093 5.69383 30.9256 6.03918 31.5397 6.65327C32.1538 7.26735 32.4992 8.1 32.5 8.96845V23.0356C32.4992 23.9041 32.1538 24.7367 31.5397 25.3508C30.9256 25.9649 30.093 26.3103 29.2245 26.3111ZM3.77546 6.81432C3.20348 6.81488 2.65509 7.04234 2.25064 7.44679C1.84619 7.85124 1.61872 8.39963 1.61816 8.97161V23.0388C1.61872 23.6108 1.84619 24.1592 2.25064 24.5636C2.65509 24.9681 3.20348 25.1955 3.77546 25.1961H29.2245C29.7964 25.1952 30.3447 24.9677 30.7491 24.5633C31.1534 24.1589 31.381 23.6107 31.3818 23.0388V8.96845C31.381 8.39655 31.1534 7.84833 30.7491 7.44394C30.3447 7.03955 29.7964 6.81199 29.2245 6.81116L3.77546 6.81432Z"
						fill={color}
					/>
					<path
						d="M27.789 13.9912H23.6557C23.491 13.9912 23.3574 14.1247 23.3574 14.2895V17.7156C23.3574 17.8803 23.491 18.0139 23.6557 18.0139H27.789C27.9537 18.0139 28.0872 17.8803 28.0872 17.7156V14.2895C28.0872 14.1247 27.9537 13.9912 27.789 13.9912Z"
						fill={color}
					/>
					<path
						d="M13.6364 18.1952H5.48047V19.3134H13.6364V18.1952Z"
						fill={color}
					/>
					<path
						d="M13.6364 21.1428H5.48047V22.261H13.6364V21.1428Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
