import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const SuccessIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		opacity = DEFAULT_ICON_OPACITY,
		color = DEFAULT_ICON_COLOR,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				height={height}
				viewBox="0 0 48 48"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M21.7223 33.6144L9.93663 21.8429C9 21 10.5 19 11.5651 20.2144L21.7223 30.3859L45.5934 6.51434C46.5794 6 47.5 7 47.2081 8.1286L21.7223 33.6144Z"
						fill={color}
					/>
					<path
						d="M24.0078 48C10.7794 48 0.0078125 37.2284 0.0078125 24C0.0078125 10.7716 10.7794 0 24.0078 0C29.5554 0.0046038 34.9305 1.92773 39.2221 5.44294C39.5 6.5 39 7.5 37.7794 7.21415C33.8955 4.03041 29.0297 2.28892 24.0078 2.28557C12.0363 2.28557 2.29339 12.0285 2.29339 24C2.29339 35.9715 12.0363 45.7144 24.0078 45.7144C35.9794 45.7144 45.7222 35.9715 45.7222 24C45.7252 21.4867 45.2899 18.9923 44.4365 16.6285C44.4365 15.5 45.7222 15 46.5794 15.8571C47.5198 18.4688 47.9982 21.2243 47.9936 24C48.0078 37.2284 37.2362 48 24.0078 48Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
