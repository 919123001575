import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const EparakstsMobileIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 32"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						clipRule="evenodd"
						d="M0.538574 32V0H32.5377V32H0.538574ZM29.3322 3.23293V28.7947H3.76949V3.23293H29.3322ZM27.8303 4.74222H5.28503V27.2595H27.8303V4.74222ZM11.205 21.3335H12.7408V13.5419L15.9548 19.6802H16.7507L20.3017 13.4333V21.3335H21.8713V10.6647H20.5692L16.4036 17.5636L12.4902 10.6647H11.205V21.3335Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
