import { STEP_COUNTER_COMPONENT_ID, TOTAL_STEPS_COUNT } from 'app-constants';
import c from 'classnames';
import { useRootContext } from 'context/root';
import { memo } from 'react';
import { v4 as uuid } from 'uuid';
import style from './AppStepCounter.module.scss';

type AppStepCounterProps = {
	totalStepCount?: number;
};

const AppStepCounter = memo(
	({ totalStepCount = TOTAL_STEPS_COUNT }: AppStepCounterProps) => {
		const { currentStepCount } = useRootContext();

		return (
			<div className={style.container} id={STEP_COUNTER_COMPONENT_ID}>
				{currentStepCount ? (
					Array(totalStepCount)
						.fill('')
						.map((_, index, array) => {
							const isLastStepItem = array.length - 1 === index;
							return (
								<div
									key={uuid()}
									className={c(style.step, {
										[style['step--last']]: isLastStepItem,
									})}
								>
									<div
										className={c(style.counter, {
											[style['counter--active']]:
												currentStepCount === index + 1,
										})}
									/>
									{!isLastStepItem ? <div className={style.line} /> : null}
								</div>
							);
						})
				) : (
					<div className={style.line} />
				)}
			</div>
		);
	},
);

export default AppStepCounter;
