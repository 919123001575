import type { AnyObject } from '../models/core.models';
import { AppLanguages } from './app.constants';

export enum IconNames {
	sheet = 'sheet',
	bag = 'bag',
	password = 'password',
	smartId = 'smart-id',
	mobileId = 'mobile-id',
	banklink = 'banklink',
	checkbox = 'checkbox',
	estoLogo = 'esto-logo',
	leftArrow = 'left-arrow',
	topRightArrow = 'top-right-arrow',
	bottomArrow = 'bottom-arrow',
	questionMark = 'question-mark',
	englishFlag = 'english-flag',
	latvianFlag = 'latvian-flag',
	estonianFlag = 'estonian-flag',
	lithuanianFlag = 'lithuanian-flag',
	russianFlag = 'russian-flag',
	chevron = 'chevron',
	scrap = 'scrap',
	eParakstsSmartCard = 'eParaksts-smart-card',
	eParakstsMobile = 'eParaksts-mobile',
	circledCheckMark = 'circled-check-mark',
	reject = 'reject',
	calendar = 'calendar',
	clock = 'clock',
	person = 'person',
	family = 'family',
	eyeClosedPassword = 'eye-closed-password',
	eyeOpenedPassword = 'eye-opened-password',
	contract = 'contract',
	checkMark = 'check-mark',
	card = 'card',
	idCard = 'id-card',
	discountTag = 'discount-tag',
	failureIcon = 'failure-icon',
	successIcon = 'success-icon',
	walletIcon = 'wallet-icon',
	getBackIcon = 'get-back-icon',
	mailIcon = 'mail-icon',
	closeIcon = 'close-icon',
}

export const DEFAULT_ICON_SIZE = {
	width: 25,
	height: 25,
};
export const DEFAULT_ICON_COLOR = '#000';
export const DEFAULT_ICON_OPACITY = '1';

export const FLAG_ICONS_BY_LANGUAGE_MAP: AnyObject = {
	[AppLanguages.en]: IconNames.englishFlag,
	[AppLanguages.lv]: IconNames.latvianFlag,
	[AppLanguages.ru]: IconNames.russianFlag,
	[AppLanguages.lt]: IconNames.lithuanianFlag,
	[AppLanguages.et]: IconNames.estonianFlag,
};
