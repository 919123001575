import { AppLoaderSizes } from 'app-constants';
import c from 'classnames';
import { memo, useEffect, useState } from 'react';
import styles from './AppLoader.module.scss';
import { AppLoaderDelayedMessage } from './AppLoaderDelayedMessage';

type AppLoaderProps = {
	visible?: boolean;
	className?: string;
	size?: AppLoaderSizes;
	noAnimation?: boolean;
	isRelative?: boolean;
	withDelayedMessage?: boolean;
	delayedMessageTitle?: string;
	delayedMessageText?: string;
	delayedMessageTimeout?: number;
};

const DEFAULT_DELAYED_MESSAGE_TIMEOUT = 10000;

const AppLoader = memo(
	({
		className,
		visible = true,
		isRelative = false,
		size = AppLoaderSizes.medium,
		noAnimation = false,
		withDelayedMessage = true,
		delayedMessageTitle,
		delayedMessageText,
		delayedMessageTimeout = DEFAULT_DELAYED_MESSAGE_TIMEOUT,
	}: AppLoaderProps) => {
		const [shouldShowDelayedMessage, setShouldShowDelayedMessage] =
			useState(false);

		const circleClassname = c(styles.circle, {
			[styles['circle--small']]: size === AppLoaderSizes.small,
			[styles['circle--no-animation']]: noAnimation,
		});

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			let timeoutId: ReturnType<typeof setTimeout>;

			if (visible && withDelayedMessage) {
				timeoutId = setTimeout(() => {
					setShouldShowDelayedMessage(true);
				}, delayedMessageTimeout);
			}

			return () => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				setShouldShowDelayedMessage(false);
			};
		}, [visible]);

		if (!visible) {
			return null;
		}

		return (
			<div
				className={c(styles.loader, className, {
					[styles['loader--relative']]: isRelative,
					[styles['loader--with-delayed-message']]: shouldShowDelayedMessage,
				})}
			>
				{shouldShowDelayedMessage ? (
					<AppLoaderDelayedMessage
						{...{ delayedMessageTitle, delayedMessageText }}
					/>
				) : null}
				<div className={styles.container}>
					<div className={circleClassname} />
					<div className={circleClassname} />
					<div className={circleClassname} />
				</div>
			</div>
		);
	},
);

export default AppLoader;
