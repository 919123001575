import { useStoreCreditAccountMutation } from 'api/core/generated';
import { AppApiVersions, AppSearchParams } from 'app-constants';
import { useSearchParams } from 'react-router-dom';

export const useStoreCreditAccount = () => {
	const [searchParams] = useSearchParams();

	const landed_from = searchParams.get(AppSearchParams.landedFrom) || undefined;

	const [storeCA, { data, loading, error }] = useStoreCreditAccountMutation({
		context: { apiVersion: AppApiVersions.core },
	});

	const storeCreditAccount = (user_id: number) =>
		storeCA({ variables: { user_id, landed_from } });

	return {
		storeCreditAccount,
		processingCreditAccountStorage: loading,
		creditAccountStorageData: data,
		creditAccountStorageError: error,
	};
};
