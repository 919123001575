import { AppOpenCloseButton } from 'components/app-open-close-button';
import { useBoolean } from 'hooks';
import { AppMobileHeaderMenuModal } from './AppMobileHeaderMenuModal';

type AppHeaderMobileContentProps = {
	shouldShowLogoutButton: boolean;
};

const AppHeaderMobileContent = ({
	shouldShowLogoutButton,
}: AppHeaderMobileContentProps) => {
	const { value, setFalse, toggle } = useBoolean(false); // header menu open state management

	return (
		<>
			<AppOpenCloseButton isOpen={value} onClick={toggle} />

			{value ? (
				<AppMobileHeaderMenuModal
					onClose={setFalse}
					shouldShowLogoutButton={shouldShowLogoutButton}
				/>
			) : null}
		</>
	);
};

export default AppHeaderMobileContent;
