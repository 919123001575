import type { ApplicationScheduleType } from 'api/core/generated';
import { AppRoutePaths } from 'app-constants';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useApplicationPrivateLabel } from './use-application-private-label';

export const useGetApplicationPrivateInfo = (
	applicationType?: ApplicationScheduleType,
) => {
	const { pathname } = useLocation();
	const {
		applicationPrivateInfo,
		applicationPrivateInfoLoading,
		getApplicationPrivateInfo,
	} = useApplicationPrivateLabel();

	const shouldHaveApplicationInfo = [
		AppRoutePaths.HIRE_PURCHASE,
		AppRoutePaths.SMALL_LOAN,
	].some((routePath) => pathname.split('/')[1] === routePath);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (shouldHaveApplicationInfo) {
			getApplicationPrivateInfo();
		}
	}, [shouldHaveApplicationInfo, applicationType]);

	return {
		getApplicationPrivateInfo,
		applicationPrivateInfo,
		applicationPrivateInfoLoading,
	};
};
