import type { GoogleAnalyticsEventData } from 'models/google-analytics.models';
import { AppProductType } from './app.constants';

export enum GoogleAnalyticsEvents {
	checkoutCompleted = 'checkout-completed',
	loginCompleted = 'login-completed',
	contactCompleted = 'contact-completed',
	contactExtraCompleted = 'contact-extra-completed',
	contractSigned = 'contract-signed',
}

export const GOOGLE_ANALYTICS_EVENTS_DEFAULT_DATA_BY_PRODUCT_AND_EVENT_MAP: Record<
	Exclude<
		AppProductType,
		| AppProductType.HIRE_PURCHASE
		| AppProductType.CREDIT_LIMIT_RECALCULATION
		| AppProductType.CREDIT_LINE_WITHDRAWAL
	>,
	Record<GoogleAnalyticsEvents, GoogleAnalyticsEventData>
> = {
	[AppProductType.SMALL_LOAN]: {
		[GoogleAnalyticsEvents.contractSigned]: {
			event: 'small_loan_success',
		},
		[GoogleAnalyticsEvents.checkoutCompleted]: {
			event: '1_step_small_loan',
			description: 'checkout_loan_amount_period',
		},
		[GoogleAnalyticsEvents.loginCompleted]: {
			event: '2_step_small_loan',
			description: 'login',
		},
		[GoogleAnalyticsEvents.contactCompleted]: {
			event: '3_step_small_loan',
			description: 'contact_form_completed',
		},
		[GoogleAnalyticsEvents.contactExtraCompleted]: {
			event: '4_step_small_loan',
			description: 'billing_form_completed',
		},
	},
	[AppProductType.CREDIT_LINE]: {
		[GoogleAnalyticsEvents.contractSigned]: {
			event: 'creditline_application_success',
		},
		[GoogleAnalyticsEvents.checkoutCompleted]: {
			event: '0_step_credit_line',
			description: 'checkout_loan_amount_period',
		},
		[GoogleAnalyticsEvents.loginCompleted]: {
			event: '1_step_credit_line',
			description: 'login',
		},
		[GoogleAnalyticsEvents.contactCompleted]: {
			event: '2_step_credit_line',
			description: 'contact_form_completed',
		},
		[GoogleAnalyticsEvents.contactExtraCompleted]: {
			event: '3_step_credit_line',
			description: 'billing_form_completed',
		},
	},
};
