import {
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocizeNamespaces,
	LogoutButtonVariants,
} from 'app-constants';
import c from 'classnames';
import { useRootContext } from 'context/root';
import { useLogout } from 'hooks';
import { useTranslation } from 'react-i18next';
import styles from './AppLogOutButton.module.scss';

type AppLogOutButtonProps = {
	type?: string;
	className?: string;
	onLogoutButtonClick?: () => void;
};

const AppLogOutButton = ({
	type = LogoutButtonVariants.primary,
	className,
	onLogoutButtonClick,
}: AppLogOutButtonProps) => {
	const { t } = useTranslation(LocizeNamespaces.common);

	const { logOut } = useLogout();
	const { getUser, getPageUrlAndNavigate } = useRootContext();

	const onLogOutClick = () => {
		if (onLogoutButtonClick) {
			onLogoutButtonClick();
		}

		logOut().then(() => {
			getUser();
			getPageUrlAndNavigate();
		});
	};

	return (
		<div className={styles['logout-button-container']}>
			{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
			<div
				className={c(styles.logout, className, {
					[styles['logout--center']]: type === LogoutButtonVariants.center,
				})}
				onClick={onLogOutClick}
			>
				{t(LOCIZE_COMMON_TRANSLATION_KEYS.logoutButtonLabel)}
			</div>
		</div>
	);
};

export default AppLogOutButton;
