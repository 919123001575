import {
	LANGUAGES_BY_ABBREVIATIONS_MAP,
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocalStorageKeys,
	LocizeNamespaces,
} from 'app-constants';
import { useRootContext } from 'context/root';
import { useUpdateUserLanguage } from 'hooks';
import { type SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeZendeskLocale, setToStorage } from 'services';
import AppLanguageSelectorListType from './AppLanguageSelectorListType';
import AppLanguageSelectorSelectType from './AppLanguageSelectorSelectType';

type AppLanguageSelectorProps = {
	isSelect?: boolean;
	onLanguageSelected?: () => void;
};

const AppLanguageSelector = ({
	isSelect,
	onLanguageSelected,
}: AppLanguageSelectorProps) => {
	const { user } = useRootContext();
	const { updateUserLanguage } = useUpdateUserLanguage();
	const { i18n, t } = useTranslation(LocizeNamespaces.common);
	const [dropdownOpened, setDropdownOpened] = useState(false);

	const languageSelectorTitle = t(
		LOCIZE_COMMON_TRANSLATION_KEYS.chooseLanguage,
	);

	const onOptionClick = useCallback(
		(event: SyntheticEvent<HTMLDivElement>, language_abbr: string) => {
			event.stopPropagation();

			const language = LANGUAGES_BY_ABBREVIATIONS_MAP[language_abbr];

			i18n.changeLanguage(language).then(() => {
				setToStorage(LocalStorageKeys.language, language);
				changeZendeskLocale(language);

				if (user) {
					updateUserLanguage({
						user_id: user.id,
						language_abbr,
					});
				}
			});

			setDropdownOpened(false);

			if (onLanguageSelected) {
				onLanguageSelected();
			}
		},
		[i18n, user, updateUserLanguage, onLanguageSelected],
	);

	const onSelectClick = (event: SyntheticEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setDropdownOpened(true);
	};

	return isSelect ? (
		<AppLanguageSelectorSelectType
			dropdownOpened={dropdownOpened}
			onCloseDropdown={() => setDropdownOpened(false)}
			onOptionClick={onOptionClick}
			onSelectClick={onSelectClick}
		/>
	) : (
		<AppLanguageSelectorListType
			onOptionClick={onOptionClick}
			title={languageSelectorTitle}
		/>
	);
};

export default AppLanguageSelector;
