import {
	ABBREVIATIONS_BY_LANGUAGES_MAP,
	FLAG_ICONS_BY_LANGUAGE_MAP,
	LANGUAGES_BY_ABBREVIATIONS_MAP,
} from 'app-constants';
import { AppLoader } from 'components/app-loader';
import { useGetLanguages } from 'hooks';
import type { SyntheticEvent } from 'react';
import { v4 as uuid } from 'uuid';
import { AppIcon } from '../app-icon';
import styles from './AppLanguageSelector.module.scss';

type AppLanguageSelectorListTypeProps = {
	title: string;
	onOptionClick: (
		event: SyntheticEvent<HTMLDivElement>,
		string: string,
	) => void;
};

const AppLanguageSelectorListType = ({
	title,
	onOptionClick,
}: AppLanguageSelectorListTypeProps) => {
	const { languages, languagesLoading } = useGetLanguages();
	const renderOptions = () =>
		languages
			.map(({ abbreviation }) => LANGUAGES_BY_ABBREVIATIONS_MAP[abbreviation])
			.map((language: string) => (
				// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
				<div
					key={uuid()}
					className={styles.option}
					onClick={(event) =>
						onOptionClick(event, ABBREVIATIONS_BY_LANGUAGES_MAP[language])
					}
				>
					<div className={styles.label}>
						<AppIcon name={FLAG_ICONS_BY_LANGUAGE_MAP[language]} />
						<div className={styles['label-text']}>{language.toUpperCase()}</div>
					</div>
				</div>
			));

	if (languagesLoading) {
		return <AppLoader isRelative />;
	}

	return (
		<div className={styles['list-language-selector']}>
			<h3 className={styles['list-language-selector__heading']}>{title}</h3>

			<div className={styles['list-language-selector__selection']}>
				{renderOptions()}
			</div>
		</div>
	);
};

export default AppLanguageSelectorListType;
