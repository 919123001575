import type { AnyObject } from 'models/core.models';
import {
	CreditLineRoutePaths,
	HirePurchaseRoutePaths,
	SmallLoanRoutePaths,
} from './routes.constants';

export const TOTAL_STEPS_COUNT = 3;

export const HIRE_PURCHASE_STEPS_COUNT: AnyObject = {
	[HirePurchaseRoutePaths.LOGIN]: 1,
	[HirePurchaseRoutePaths.CONTACT]: 2,
	[HirePurchaseRoutePaths.CONTACT_EXTRA]: 2,
	[HirePurchaseRoutePaths.SIGNING]: 3,
};

export const SMALL_LOAN_STEPS_COUNT: AnyObject = {
	[SmallLoanRoutePaths.LOGIN]: 1,
	[SmallLoanRoutePaths.CONTACT]: 2,
	[SmallLoanRoutePaths.CONTACT_EXTRA]: 2,
	[SmallLoanRoutePaths.SIGNING]: 3,
};

export const CREDIT_LINE_STEPS_COUNT: AnyObject = {
	[CreditLineRoutePaths.LOGIN]: 1,
	[CreditLineRoutePaths.CONTACT]: 2,
	[CreditLineRoutePaths.CONTACT_EXTRA]: 2,
	[CreditLineRoutePaths.SIGNING]: 3,
};
