import { AppSearchParams } from 'app-constants';
import { useSearchParams } from 'react-router-dom';

export const useShouldShowSchedulePage = () => {
	const [searchParams] = useSearchParams();
	return (
		(searchParams.get(AppSearchParams.displayScheduleTypes)?.split(',') || [])
			.length > 1
	);
};
