import {
	AppProductType,
	GOOGLE_ANALYTICS_EVENTS_DEFAULT_DATA_BY_PRODUCT_AND_EVENT_MAP,
	type GoogleAnalyticsEvents,
} from 'app-constants';
import { googleAnalyticsId } from 'environment';
import type { GoogleAnalyticsEventData } from 'models';

export const initializeGoogleAnalytics = (): void => {
	if (googleAnalyticsId) {
		// register google tag manager
		const gTagManagerScript = document.createElement('script');
		gTagManagerScript.innerHTML = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleAnalyticsId}');
`;
		document.head.appendChild(gTagManagerScript);

		// google tag manager
		const gaScript = document.createElement('noscript');
		const iframeTag = document.createElement('iframe');
		iframeTag.setAttribute(
			'src',
			`https://www.googletagmanager.com/ns.html?id=${googleAnalyticsId}`,
		);
		iframeTag.setAttribute('height', '0');
		iframeTag.setAttribute('width', '0');
		iframeTag.setAttribute('style', 'display:none;visibility:hidden');
		gaScript.append(iframeTag);
		document.body.prepend(gaScript);
	}
};

const trackGAEvent = (eventData: GoogleAnalyticsEventData) => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	(window as any).dataLayer = (window as any).dataLayer || [];
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	(window as any).dataLayer.push(eventData);
};

export const trackGoogleAnalyticsEvent = (
	productType: AppProductType | null,
	eventName: GoogleAnalyticsEvents,
	eventData:
		| GoogleAnalyticsEventData
		| undefined = {} as GoogleAnalyticsEventData,
) => {
	if (
		!productType ||
		!eventName ||
		[
			AppProductType.HIRE_PURCHASE,
			AppProductType.CREDIT_LIMIT_RECALCULATION,
		].includes(productType)
	) {
		return;
	}

	const data = {
		...(GOOGLE_ANALYTICS_EVENTS_DEFAULT_DATA_BY_PRODUCT_AND_EVENT_MAP[
			productType as Exclude<
				AppProductType,
				| AppProductType.HIRE_PURCHASE
				| AppProductType.CREDIT_LIMIT_RECALCULATION
				| AppProductType.CREDIT_LINE_WITHDRAWAL
			>
		][eventName as GoogleAnalyticsEvents] ?? {}),
		...eventData,
	};

	trackGAEvent(data);
};
