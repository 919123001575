import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const RejectIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 64 64"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M39.5431 39.5422L24.4582 24.4572M24.4582 39.5422L39.5431 24.4572M32.0007 58.6663C46.7282 58.6663 58.6673 46.7273 58.6673 31.9997C58.6673 17.2721 46.7282 5.33301 32.0007 5.33301C17.2731 5.33301 5.33398 17.2721 5.33398 31.9997C5.33398 46.7273 17.2731 58.6663 32.0007 58.6663Z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
				</g>
			</svg>
		);
	},
);
