import {
	ABBREVIATIONS_BY_LANGUAGES_MAP,
	FLAG_ICONS_BY_LANGUAGE_MAP,
	IconNames,
	LANGUAGES_BY_ABBREVIATIONS_MAP,
	LocizeNamespaces,
} from 'app-constants';
import c from 'classnames';
import { useGetLanguages, useOutsideClick } from 'hooks';
import { type SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '../app-icon';
import styles from './AppLanguageSelector.module.scss';

type AppLanguageSelectorSelectTypeProps = {
	dropdownOpened: boolean;
	onSelectClick: (event: SyntheticEvent<HTMLDivElement>) => void;
	onOptionClick: (
		event: SyntheticEvent<HTMLDivElement>,
		string: string,
	) => void;
	onCloseDropdown: () => void;
};

const AppLanguageSelectorSelectType = ({
	dropdownOpened,
	onSelectClick,
	onOptionClick,
	onCloseDropdown,
}: AppLanguageSelectorSelectTypeProps) => {
	const { i18n } = useTranslation(LocizeNamespaces.common);
	const { languages } = useGetLanguages();
	const languageSelectorRef = useRef<HTMLDivElement>(null);

	const renderOptions = () =>
		languages
			.map(({ abbreviation }) => LANGUAGES_BY_ABBREVIATIONS_MAP[abbreviation])
			.filter((language) => i18n.language !== language)
			.map((language: string) => (
				// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
				<div
					key={language}
					className={styles.option}
					onClick={(event) =>
						onOptionClick(event, ABBREVIATIONS_BY_LANGUAGES_MAP[language])
					}
				>
					<div className={styles.label}>
						<AppIcon name={FLAG_ICONS_BY_LANGUAGE_MAP[language]} />
						<div className={styles['label-text']}>{language.toUpperCase()}</div>
					</div>
				</div>
			));

	useOutsideClick({
		elementRef: languageSelectorRef,
		callback: onCloseDropdown,
	});

	return (
		// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
		<div
			ref={languageSelectorRef}
			className={c(styles.container, {
				[styles['container--opened']]: dropdownOpened,
			})}
			onClick={onSelectClick}
		>
			<div className={styles['selected-language']}>
				<AppIcon
					className={styles['selected-language-icon']}
					name={FLAG_ICONS_BY_LANGUAGE_MAP[i18n.language]}
				/>
				<div className={styles['selected-language-label']}>{i18n.language}</div>
				<AppIcon
					className={c(styles['select-icon'], {
						[styles['select-icon--reversed']]: dropdownOpened,
					})}
					name={IconNames.bottomArrow}
				/>
			</div>
			<div
				className={c(styles['select-options'], {
					[styles.none]: !dropdownOpened,
				})}
			>
				{renderOptions()}
			</div>
		</div>
	);
};

export default AppLanguageSelectorSelectType;
