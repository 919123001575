import {
	type UpdateUserLanguageMutationVariables,
	useUpdateUserLanguageMutation,
} from 'api/core/generated';
import { AppApiVersions } from 'app-constants';

export const useUpdateUserLanguage = () => {
	const [updateUserLanguageRequest, { loading, error }] =
		useUpdateUserLanguageMutation({
			context: { apiVersion: AppApiVersions.core },
		});

	const updateUserLanguage = (variables: UpdateUserLanguageMutationVariables) =>
		updateUserLanguageRequest({ variables });

	return {
		updateUserLanguage,
		updateUserLanguageProcessing: loading,
		updateUserLanguageError: error,
	};
};
