import { type Application, ApplicationScheduleType } from 'api/core/generated';
import {
	AppProductType,
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocizeNamespaces,
} from 'app-constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setPageTitle } from 'services';
import { useGetProductTypeByUrl } from './use-get-product-type-by-url';

type SmallLoanApplicationScheduleTypes =
	| ApplicationScheduleType.SMALL_LOAN
	| ApplicationScheduleType.FAST_LOAN
	| ApplicationScheduleType.RENOVATION_LOAN
	| ApplicationScheduleType.VEHICLE_LOAN;

export const useSetPageTitle = (application: Application) => {
	const { schedule_type, free_hp_enabled } = application;

	const { t, i18n } = useTranslation(LocizeNamespaces.common);
	const productType = useGetProductTypeByUrl();

	const smallLoanGroupPageTitlesMap: Record<
		SmallLoanApplicationScheduleTypes,
		string
	> = {
		[ApplicationScheduleType.SMALL_LOAN]: t(
			LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleSmallLoan,
		),
		[ApplicationScheduleType.FAST_LOAN]: t(
			LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleFastLoan,
		),
		[ApplicationScheduleType.RENOVATION_LOAN]: t(
			LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleRenovationLoan,
		),
		[ApplicationScheduleType.VEHICLE_LOAN]: t(
			LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleVehicleLoan,
		),
	};

	const setHirePurchasePageTitle = () => {
		const pageTitle = free_hp_enabled
			? t(LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleFreeHP)
			: t(LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleHP);

		setPageTitle(pageTitle);
	};

	const setSmallLoanPageTitle = () => {
		setPageTitle(
			smallLoanGroupPageTitlesMap[
				schedule_type as SmallLoanApplicationScheduleTypes
			] ?? '',
		);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		switch (productType) {
			case AppProductType.HIRE_PURCHASE:
				setHirePurchasePageTitle();
				break;
			case AppProductType.SMALL_LOAN:
				setSmallLoanPageTitle();
				break;
			case AppProductType.CREDIT_LINE:
				setPageTitle(t(LOCIZE_COMMON_TRANSLATION_KEYS.pageTitleCL));
				break;
			default:
				break;
		}
	}, [productType, application, i18n.language]);
};
