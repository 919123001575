import {
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocizeNamespaces,
} from 'app-constants';
import { useTranslation } from 'react-i18next';

import styles from './AppLoader.module.scss';

type AppLoaderDelayedMessageProps = {
	delayedMessageTitle?: string;
	delayedMessageText?: string;
};

export const AppLoaderDelayedMessage = ({
	delayedMessageTitle,
	delayedMessageText,
}: AppLoaderDelayedMessageProps) => {
	const { t } = useTranslation(LocizeNamespaces.common);

	return (
		<div className={styles['delayed-message']}>
			<div className={styles['delayed-message__title']}>
				{delayedMessageTitle ||
					t(LOCIZE_COMMON_TRANSLATION_KEYS.defaultDelayedMessageTitle)}
			</div>
			<div className={styles['delayed-message__text']}>
				{delayedMessageText ||
					t(LOCIZE_COMMON_TRANSLATION_KEYS.defaultDelayedMessageText)}
			</div>
		</div>
	);
};
