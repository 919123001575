import type { GoogleAnalyticsEvents } from 'app-constants';
import type { GoogleAnalyticsEventData } from 'models';
import { useCallback, useState } from 'react';
import { trackGoogleAnalyticsEvent as trackGAEvent } from 'services';
import { useGetProductTypeByUrl } from './use-get-product-type-by-url';

export const useTrackGoogleAnalytics = () => {
	const [eventsTriggered, setEventsTriggered] = useState<Array<string>>([]);

	const productType = useGetProductTypeByUrl();

	const trackGoogleAnalyticsEvent = useCallback(
		(eventName: GoogleAnalyticsEvents, data?: GoogleAnalyticsEventData) => {
			setEventsTriggered((prevEventsTriggered) => [
				...prevEventsTriggered,
				eventName,
			]);

			const shouldTriggerGoogleAnalyticsEvent =
				!eventsTriggered.includes(eventName);

			if (shouldTriggerGoogleAnalyticsEvent) {
				trackGAEvent(productType, eventName, data);
			}
		},
		[eventsTriggered, productType],
	);

	return trackGoogleAnalyticsEvent;
};
