import { DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const IdCardIcon = memo(
	({ opacity, color, size = DEFAULT_ICON_SIZE }: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 22 22"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						clipRule="evenodd"
						d="M2.29167 4.58335C1.53646 4.58335 0.916667 5.20315 0.916667 5.95835V16.0417C0.916667 16.7969 1.53646 17.4167 2.29167 17.4167H19.7083C20.4635 17.4167 21.0833 16.7969 21.0833 16.0417V5.95835C21.0833 5.20315 20.4635 4.58335 19.7083 4.58335H2.29167ZM0 5.95835C0 4.69689 1.0302 3.66669 2.29167 3.66669H19.7083C20.9698 3.66669 22 4.69689 22 5.95835V16.0417C22 17.3032 20.9698 18.3334 19.7083 18.3334H2.29167C1.0302 18.3334 0 17.3032 0 16.0417V5.95835Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M5.0415 11.9167C4.79047 11.9167 4.58317 12.124 4.58317 12.375V14.6667H3.6665V12.375C3.6665 11.6177 4.28421 11 5.0415 11H9.62484C10.3821 11 10.9998 11.6177 10.9998 12.375V15.5833H10.0832V12.375C10.0832 12.124 9.87587 11.9167 9.62484 11.9167H5.0415Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M7.33333 7.33335C6.82707 7.33335 6.41667 7.74376 6.41667 8.25002C6.41667 8.75628 6.82707 9.16669 7.33333 9.16669C7.83959 9.16669 8.25 8.75628 8.25 8.25002C8.25 7.74376 7.83959 7.33335 7.33333 7.33335ZM5.5 8.25002C5.5 7.2375 6.32081 6.41669 7.33333 6.41669C8.34586 6.41669 9.16667 7.2375 9.16667 8.25002C9.16667 9.26254 8.34586 10.0834 7.33333 10.0834C6.32081 10.0834 5.5 9.26254 5.5 8.25002Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M12.8335 7.33331H18.3335V8.24998H12.8335V7.33331Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M12.8335 9.16669H18.3335V10.0834H12.8335V9.16669Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
