import {
	AppSearchParams,
	LOCIZE_LANGUAGE_DETECTION_PLACES,
	LocalStorageKeys,
	LocizeNamespaces,
} from 'app-constants';
import {
	availableLanguages,
	locizeProjectId,
	regionLanguage,
} from 'environment';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

export const i18Next = i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		supportedLngs: availableLanguages,
		fallbackLng: regionLanguage,
		ns: [LocizeNamespaces.errors],
		backend: {
			projectId: locizeProjectId,
			referenceLng: regionLanguage,
		},
		detection: {
			order: [
				LOCIZE_LANGUAGE_DETECTION_PLACES.queryString,
				LOCIZE_LANGUAGE_DETECTION_PLACES.localStorage,
			],
			caches: [LOCIZE_LANGUAGE_DETECTION_PLACES.localStorage],
			lookupQuerystring: AppSearchParams.language,
			lookupLocalStorage: LocalStorageKeys.i18NextLanguage,
		},
	});
