import { LocalStorageKeys } from 'app-constants';
import { filterObjectByKey } from './utility-service';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const setToStorage = (storageKey: string, dataToStore: any) => {
	localStorage.setItem(storageKey, JSON.stringify(dataToStore));
};

export const getFromStorage = (storageKey: string) => {
	const dataFromStorage = localStorage.getItem(storageKey);
	return typeof dataFromStorage === 'string'
		? JSON.parse(dataFromStorage)
		: dataFromStorage;
};

export const removeFromStorage = (storageKey: string) => {
	localStorage.removeItem(storageKey);
};

const setDataToObjectInStorage = (
	storageKey: LocalStorageKeys,
	objectKey: string | number,
	objectValue: string | number,
) => {
	const storedObject = getFromStorage(storageKey);

	if (storedObject) {
		setToStorage(storageKey, {
			...storedObject,
			[objectKey]: objectValue,
		});
	} else {
		setToStorage(storageKey, {
			[objectKey]: objectValue,
		});
	}
};

const getDataFromObjectInStorage = (
	storageKey: LocalStorageKeys,
	objectKey: string | number,
) => {
	const storedObject = getFromStorage(storageKey);

	return storedObject?.[objectKey] ?? null;
};

const removeDataFromObjectInStorage = (
	storageKey: LocalStorageKeys,
	objectKey: string | number,
) => {
	const id = objectKey.toString();
	const storedObject = getFromStorage(storageKey);

	if (storedObject?.[id]) {
		setToStorage(storageKey, filterObjectByKey(storedObject, id));
	}
};

// SMALL LOAN AMOUNT

export const setSmallLoanAmountToStorage = (
	amount: number,
	applicationId: number,
) => {
	setDataToObjectInStorage(
		LocalStorageKeys.smallLoanInitialRequestedAmount,
		applicationId,
		amount,
	);
};

export const getSmallLoanAmountFromStorage = (
	applicationId: number | string,
) => {
	return getDataFromObjectInStorage(
		LocalStorageKeys.smallLoanInitialRequestedAmount,
		applicationId,
	);
};

export const removeSmallLoanAmountFromStorage = (applicationId: number) => {
	removeDataFromObjectInStorage(
		LocalStorageKeys.smallLoanInitialRequestedAmount,
		applicationId,
	);
};
