import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const ScrapIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 35"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M14.7344 10.9382L19.7564 15.9602C21.3474 17.5513 21.327 20.5351 19.6447 22.0294V22.0294C17.5257 23.9116 14.9923 23.051 12.9881 21.0469L3.16075 11.2194C1.84608 9.90468 0.952667 8.14852 1.30494 6.32297C1.71761 4.18447 2.92592 1.54893 6.33408 1.05412C8.06123 0.803365 9.688 1.74807 10.9221 2.98216L30.1555 22.2155C31.3256 23.3856 32.1468 24.894 32.0797 26.5475C31.9556 29.605 30.7552 34.3277 24.5039 34.2172C22.8779 34.1885 21.3972 33.3386 20.2473 32.1887L10.0729 22.0144"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</svg>
		);
	},
);
