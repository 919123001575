import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

/** Attributes of a page including fields to show */
export type PageAttributes = {
  /** Page attributes in json format such as fields to show. */
  attributes?: Maybe<Scalars['String']['output']>;
  /** Name of the page. */
  name?: Maybe<Scalars['String']['output']>;
};

/** Url of the page */
export type PageUrl = {
  /** Url for external redirect e.g. Customer profile or external service */
  external_url?: Maybe<Scalars['String']['output']>;
  /** Url of the current page */
  url: Scalars['String']['output'];
};

export type Query = {
  /** Get the page attributes including fields to show. */
  page_attributes?: Maybe<PageAttributes>;
  /** Get the url of the next or previous page. */
  page_url?: Maybe<PageUrl>;
};


export type QueryPageAttributesArgs = {
  url: Scalars['String']['input'];
};


export type QueryPageUrlArgs = {
  next?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type PageAttributesQueryVariables = Exact<{
  url: Scalars['String']['input'];
}>;


export type PageAttributesQuery = { page_attributes?: { name?: string | null, attributes?: string | null } | null };

export type PageUrlQueryVariables = Exact<{
  url: Scalars['String']['input'];
  next?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PageUrlQuery = { page_url?: { url: string, external_url?: string | null } | null };


export const PageAttributesDocument = gql`
    query PageAttributes($url: String!) {
  page_attributes(url: $url) {
    name
    attributes
  }
}
    `;

/**
 * __usePageAttributesQuery__
 *
 * To run a query within a React component, call `usePageAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageAttributesQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function usePageAttributesQuery(baseOptions: Apollo.QueryHookOptions<PageAttributesQuery, PageAttributesQueryVariables> & ({ variables: PageAttributesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageAttributesQuery, PageAttributesQueryVariables>(PageAttributesDocument, options);
      }
export function usePageAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageAttributesQuery, PageAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageAttributesQuery, PageAttributesQueryVariables>(PageAttributesDocument, options);
        }
export function usePageAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageAttributesQuery, PageAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageAttributesQuery, PageAttributesQueryVariables>(PageAttributesDocument, options);
        }
export type PageAttributesQueryHookResult = ReturnType<typeof usePageAttributesQuery>;
export type PageAttributesLazyQueryHookResult = ReturnType<typeof usePageAttributesLazyQuery>;
export type PageAttributesSuspenseQueryHookResult = ReturnType<typeof usePageAttributesSuspenseQuery>;
export type PageAttributesQueryResult = Apollo.QueryResult<PageAttributesQuery, PageAttributesQueryVariables>;
export const PageUrlDocument = gql`
    query PageUrl($url: String!, $next: Boolean) {
  page_url(url: $url, next: $next) {
    url
    external_url
  }
}
    `;

/**
 * __usePageUrlQuery__
 *
 * To run a query within a React component, call `usePageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageUrlQuery({
 *   variables: {
 *      url: // value for 'url'
 *      next: // value for 'next'
 *   },
 * });
 */
export function usePageUrlQuery(baseOptions: Apollo.QueryHookOptions<PageUrlQuery, PageUrlQueryVariables> & ({ variables: PageUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
      }
export function usePageUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageUrlQuery, PageUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
        }
export function usePageUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PageUrlQuery, PageUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
        }
export type PageUrlQueryHookResult = ReturnType<typeof usePageUrlQuery>;
export type PageUrlLazyQueryHookResult = ReturnType<typeof usePageUrlLazyQuery>;
export type PageUrlSuspenseQueryHookResult = ReturnType<typeof usePageUrlSuspenseQuery>;
export type PageUrlQueryResult = Apollo.QueryResult<PageUrlQuery, PageUrlQueryVariables>;