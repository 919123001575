import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const MobileIdIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 32"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M20.7209 0.291313C20.1286 0.0891101 19.4846 0.405283 19.2824 0.997505C19.0802 1.58973 19.3964 2.23374 19.9886 2.43594C26.1474 4.53875 30.131 10.1768 29.9796 15.9696C29.8041 22.7177 24.0338 28.8387 16.3934 29.4489V19.7669C16.3934 19.1411 15.8861 18.6338 15.2603 18.6338C14.6345 18.6338 14.1272 19.1411 14.1272 19.7669V30.6365C14.1272 31.2623 14.6345 31.7696 15.2603 31.7696C15.3156 31.7696 15.37 31.7657 15.4231 31.758C15.4585 31.7606 15.4944 31.7615 15.5306 31.7607C24.7465 31.5615 32.0291 24.3326 32.245 16.0288C32.4245 9.16262 27.7371 2.68688 20.7209 0.291313Z"
						fill={color}
					/>
					<path
						d="M15.4274 0.23885C16.0532 0.23885 16.5605 0.746153 16.5605 1.37194V12.2415C16.5605 12.8673 16.0532 13.3746 15.4274 13.3746C14.8016 13.3746 14.2943 12.8673 14.2943 12.2415V4.65181C13.6949 5.43054 13.0206 6.28784 12.3636 7.11467C11.5332 8.15991 10.7269 9.16091 10.1285 9.9003C9.82917 10.2701 9.58169 10.5747 9.40895 10.7869L9.1378 11.1195L8.26041 10.4025L7.37243 11.1064L7.13012 10.8C6.97526 10.604 6.75268 10.3219 6.48148 9.97701C5.93918 9.28742 5.20202 8.34647 4.42309 7.34168C3.79373 6.52982 3.12933 5.6664 2.51618 4.85661V24.4491C2.51618 25.0749 2.00888 25.5822 1.38309 25.5822C0.757303 25.5822 0.25 25.0749 0.25 24.4491V1.37211C0.25 0.746316 0.757303 0.239014 1.38309 0.239014C1.60228 0.239014 1.80694 0.301251 1.98035 0.409018C2.08861 0.476029 2.18688 0.562216 2.26967 0.666431C2.2953 0.698594 2.31923 0.732184 2.34129 0.767062C2.98022 1.74091 4.65698 3.94458 6.21413 5.95324C6.9889 6.95266 7.72265 7.88926 8.26282 8.57615L8.27361 8.58986L8.36692 8.47461C8.96269 7.73849 9.76444 6.74312 10.5893 5.70492C11.415 4.66575 12.2603 3.58812 12.9445 2.68615C13.6544 1.75037 14.1256 1.0875 14.2649 0.831196C14.4842 0.42779 14.9129 0.210847 15.3429 0.241955C15.3708 0.239897 15.399 0.23885 15.4274 0.23885Z"
						fill={color}
					/>
					<path
						d="M7.37243 11.1064C7.37243 11.1064 7.37251 11.1065 8.26041 10.4025C9.1377 11.1197 9.1378 11.1195 9.1378 11.1195L8.24692 12.2094L7.37243 11.1064Z"
						fill={color}
					/>
					<path
						d="M15.2713 14.6239C14.4572 14.6239 13.7973 15.2838 13.7973 16.0981C13.7973 16.9125 14.4572 17.5724 15.2713 17.5724C16.0854 17.5724 16.7453 16.9125 16.7453 16.0981C16.7453 15.2838 16.0854 14.6239 15.2713 14.6239Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
