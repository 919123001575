import {
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocizeNamespaces,
} from 'app-constants';
import c from 'classnames';
import { AppExternalLink } from 'components/app-external-link';
import { AppLocalizationComponent } from 'components/app-localization-component';
import type { AnyObject } from 'models';
import { useTranslation } from 'react-i18next';

import { REDIRECT_URLS } from '../../app-constants/redirect-urls.constants';
import { CONTACT_INFO } from '../../environment/environment';
import styles from './AppFooter.module.scss';

type AppFooterProps = {
	className?: string;
	visible?: boolean;
};

const AppFooter = ({ className, visible = true }: AppFooterProps) => {
	const { t, i18n } = useTranslation(LocizeNamespaces.common);
	const { contactName, contactPhone, contactEmail, contactAddress } =
		CONTACT_INFO;

	if (!visible) return null;

	return (
		<div className={c(styles.footer, className)}>
			<AppLocalizationComponent
				className={styles.description}
				components={{
					site_link: (
						<AppExternalLink
							className={styles['footer-link']}
							to={`mailto:${contactEmail}`}
						/>
					),
				}}
				locizeKey={LOCIZE_COMMON_TRANSLATION_KEYS.footerTopText}
				t={t}
				values={{
					contact_name: contactName,
					contact_address: contactAddress,
					contact_phone: contactPhone,
					contact_email: contactEmail,
				}}
			/>
			<AppLocalizationComponent
				className={styles.description}
				components={{
					site_link: (
						<AppExternalLink
							className={styles['footer-link']}
							to={(REDIRECT_URLS.termsPageUrs as AnyObject)[i18n.language]}
						/>
					),
				}}
				locizeKey={LOCIZE_COMMON_TRANSLATION_KEYS.footerBotText}
				t={t}
				values={{
					contact_name: contactName,
				}}
			/>
		</div>
	);
};

export default AppFooter;
