import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const SmartIdIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 32"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M19.4758 11.1911C20.114 11.1911 20.6313 10.6738 20.6313 10.0356C20.6313 9.39749 20.114 8.88016 19.4758 8.88016C18.8376 8.88016 18.3203 9.39749 18.3203 10.0356C18.3203 10.6738 18.8376 11.1911 19.4758 11.1911Z"
						fill={color}
					/>
					<path
						d="M25.4292 29.0749C25.2361 29.0749 25.048 29.014 24.8916 28.9009C24.7352 28.7877 24.6185 28.6281 24.5582 28.4447C24.4978 28.2614 24.4968 28.0636 24.5554 27.8797C24.614 27.6958 24.7291 27.535 24.8844 27.4204C27.2911 25.6533 29.078 23.1711 29.9899 20.3281C30.9019 17.485 30.8923 14.4266 29.9626 11.5893C29.0329 8.75202 27.2305 6.28102 24.8128 4.52909C22.3951 2.77716 19.4857 1.83387 16.4999 1.83389C15.8104 1.8344 15.1218 1.88416 14.4394 1.98277V22.0168C14.4394 22.1372 14.4156 22.2565 14.3695 22.3677C14.3235 22.479 14.2559 22.5801 14.1708 22.6652C14.0857 22.7503 13.9846 22.8179 13.8733 22.864C13.7621 22.91 13.6428 22.9338 13.5224 22.9338C13.402 22.9338 13.2828 22.91 13.1715 22.864C13.0603 22.8179 12.9592 22.7503 12.874 22.6652C12.7889 22.5801 12.7214 22.479 12.6753 22.3677C12.6292 22.2565 12.6055 22.1372 12.6055 22.0168V1.20794C12.6053 0.996187 12.6785 0.790902 12.8127 0.627095C12.9469 0.463288 13.1337 0.351087 13.3414 0.309597C16.9155 -0.411857 20.6288 0.108886 23.8666 1.78563C27.1044 3.46237 29.6726 6.19456 31.1458 9.52988C32.6191 12.8652 32.9091 16.6036 31.968 20.1263C31.0268 23.6489 28.9109 26.7445 25.9705 28.9007C25.813 29.0146 25.6235 29.0756 25.4292 29.0749Z"
						fill={color}
					/>
					<path
						d="M16.5013 32C13.1284 32.0028 9.84089 30.9394 7.10865 28.9616C4.37641 26.9838 2.33928 24.193 1.28839 20.988C0.237497 17.783 0.226634 14.3277 1.25735 11.1162C2.28807 7.90458 4.30762 5.10101 7.02737 3.10611C7.22357 2.96939 7.46542 2.91473 7.70135 2.95378C7.93728 2.99284 8.14862 3.12251 8.29029 3.31516C8.43197 3.50782 8.49279 3.74821 8.45976 3.98506C8.42673 4.22191 8.30246 4.43649 8.11348 4.58303C5.70559 6.34897 3.91744 8.83076 3.00448 11.6738C2.09151 14.5169 2.10046 17.5757 3.03002 20.4134C3.95959 23.2511 5.76222 25.7223 8.18039 27.4742C10.5985 29.226 13.5085 30.1688 16.4946 30.1678C17.1835 30.1675 17.8716 30.1177 18.5535 30.0189V16.8755C18.5535 16.6323 18.6501 16.3991 18.822 16.2271C18.994 16.0552 19.2272 15.9585 19.4704 15.9585C19.7136 15.9585 19.9468 16.0552 20.1188 16.2271C20.2907 16.3991 20.3873 16.6323 20.3873 16.8755V30.7904C20.3875 31.0021 20.3143 31.2074 20.1801 31.3712C20.0459 31.535 19.8591 31.6472 19.6514 31.6887C18.6141 31.8955 17.559 31.9998 16.5013 32Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
