import type { ApplicationScheduleType } from 'api/core/generated';
import {
	AppProductType,
	AppRoutePaths,
	type AppSearchParams,
	CREDIT_LINE_STEPS_COUNT,
	CreditLimitRecalculationRoutePaths,
	CreditLineRoutePaths,
	CreditLineWithdrawalRoutePaths,
	HIRE_PURCHASE_STEPS_COUNT,
	HirePurchaseRoutePaths,
	SMALL_LOAN_SCHEDULE_TYPES,
	SMALL_LOAN_STEPS_COUNT,
	SmallLoanRoutePaths,
} from 'app-constants';

export const getCurrentHirePurchaseStepCount = (
	pagePath: HirePurchaseRoutePaths,
): number => HIRE_PURCHASE_STEPS_COUNT[pagePath];

export const getCurrentSmallLoanStepCount = (
	pagePath: SmallLoanRoutePaths,
): number => SMALL_LOAN_STEPS_COUNT[pagePath];

export const getCurrentCreditLineStepCount = (
	pagePath: CreditLineRoutePaths,
): number => CREDIT_LINE_STEPS_COUNT[pagePath];

export const getCurrentStepCount = (currentPathname: string): number | null => {
	const [, productType, pagePath] = currentPathname.split('/');

	switch (productType) {
		case AppRoutePaths.HIRE_PURCHASE: {
			return getCurrentHirePurchaseStepCount(
				pagePath as HirePurchaseRoutePaths,
			);
		}
		case AppRoutePaths.SMALL_LOAN: {
			return getCurrentSmallLoanStepCount(pagePath as SmallLoanRoutePaths);
		}
		case AppRoutePaths.CREDIT_LINE: {
			return getCurrentCreditLineStepCount(pagePath as CreditLineRoutePaths);
		}
		default: {
			return null;
		}
	}
};

export const getUrlSearchParamsWithDeletedKeys = (
	arrayOfParamsToDelete: Array<string>,
	searchParams: URLSearchParams,
) => {
	// biome-ignore lint/complexity/noForEach: <explanation>
	arrayOfParamsToDelete.forEach((param) => searchParams.delete(param));

	return searchParams;
};

export const getFilteredUrlSearchParamsObject = (
	paramsToFilter: Record<string, boolean>,
	searchParams: URLSearchParams,
) => {
	return Object.fromEntries(
		Array.from(searchParams.entries()).filter(
			([key]) => !paramsToFilter[key as AppSearchParams],
		),
	);
};

export const getUrlWithReplacedSearchString = (
	urlString: string,
	newSearchString: string,
) => {
	const url = new URL(urlString, window.location.origin);
	url.search = newSearchString;
	return url.pathname + url.search + url.hash;
};

export const getAppRoutePathByProductType = (
	productType: AppProductType | null,
) => {
	if (!productType) {
		return {
			appRoutePath: null,
			productRoutePaths: null,
		};
	}

	return {
		[AppProductType.HIRE_PURCHASE]: {
			appRoutePath: AppRoutePaths.HIRE_PURCHASE,
			productRoutePaths: HirePurchaseRoutePaths,
		},
		[AppProductType.SMALL_LOAN]: {
			appRoutePath: AppRoutePaths.SMALL_LOAN,
			productRoutePaths: SmallLoanRoutePaths,
		},
		[AppProductType.CREDIT_LINE]: {
			appRoutePath: AppRoutePaths.CREDIT_LINE,
			productRoutePaths: CreditLineRoutePaths,
		},
		[AppProductType.CREDIT_LIMIT_RECALCULATION]: {
			appRoutePath: AppRoutePaths.CREDIT_LIMIT_RECALCULATION,
			productRoutePaths: CreditLimitRecalculationRoutePaths,
		},
		[AppProductType.CREDIT_LINE_WITHDRAWAL]: {
			appRoutePath: AppRoutePaths.CREDIT_LINE_WITHDRAWAL,
			productRoutePaths: CreditLineWithdrawalRoutePaths,
		},
	}[productType];
};

export const getAppRoutePathByApplicationScheduleType = (
	scheduleType: string | null,
) => {
	let appRoutePath = null;
	let productRoutePaths = null;

	if (
		SMALL_LOAN_SCHEDULE_TYPES.includes(scheduleType as ApplicationScheduleType)
	) {
		appRoutePath = AppRoutePaths.SMALL_LOAN;
		productRoutePaths = SmallLoanRoutePaths;
	} else {
		appRoutePath = AppRoutePaths.HIRE_PURCHASE;
		productRoutePaths = HirePurchaseRoutePaths;
	}

	return {
		appRoutePath,
		productRoutePaths,
	};
};

type ReplaceOrAddQueryParamInUrlArgs = {
	url: string;
	params: Record<string, string>;
	returnWithBase?: boolean;
};

export const replaceOrAddQueryParamsInUrl = ({
	url,
	params,
	returnWithBase = false,
}: ReplaceOrAddQueryParamInUrlArgs): string => {
	const newUrl = new URL(url, window.location.origin);

	for (const [paramName, paramValue] of Object.entries(params)) {
		if (paramValue) {
			newUrl.searchParams.set(paramName, paramValue);
		}
	}
	const baseUrl = returnWithBase ? newUrl.origin : '';

	return baseUrl + newUrl.pathname + newUrl.search + newUrl.hash;
};

export const removeURLParameter = (url: string, parameter: AppSearchParams) => {
	const urlParts = url.split('?');
	if (urlParts.length >= 2) {
		const prefix = `${encodeURIComponent(parameter)}=`;
		const pars = urlParts[1].split(/[&;]/g);

		for (let i = pars.length; i-- > 0; ) {
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1);
			}
		}

		return urlParts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
	}
	return url;
};
