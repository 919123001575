import {
	LOCIZE_COMMON_TRANSLATION_KEYS,
	LocizeNamespaces,
} from 'app-constants';
import c from 'classnames';
import { useRootContext } from 'context/root';
import { useTranslation } from 'react-i18next';

import styles from './AppTestMode.module.scss';

const AppTestMode = () => {
	const { applicationPrivateInfo } = useRootContext();
	const { is_test } = applicationPrivateInfo ?? {};

	const { t } = useTranslation(LocizeNamespaces.common);
	const testMode = t(LOCIZE_COMMON_TRANSLATION_KEYS.test);

	if (!is_test) return null;

	return <div className={c(styles.disclaimer)}>{testMode}</div>;
};

export default AppTestMode;
