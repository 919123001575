import type { MeUserFragment } from 'api/core/generated';
import { AppSearchParams } from 'app-constants';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
	initializeGoogleAnalytics,
	loadZendeskWidget,
	useSift,
} from 'services';
import { useSmartlook } from './use-smartlook';

export const useInitializeAppServices = (user: Nullable<MeUserFragment>) => {
	const [searchParams] = useSearchParams();
	const productIdentifier =
		searchParams.get(AppSearchParams.referenceKey) ||
		searchParams.get(AppSearchParams.creditAccountHash) ||
		searchParams.get(AppSearchParams.creditLineWithdrawalHash) ||
		searchParams.get(AppSearchParams.creditLimitRecalculationHash);

	const { initializeSmartlook, identifyInSmartlook } = useSmartlook();
	const { initializeSift, identifyUserInSift } = useSift();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		loadZendeskWidget();
		initializeSmartlook(productIdentifier);
		initializeGoogleAnalytics();
		initializeSift();
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		identifyUserInSift(user?.id || null);
		identifyInSmartlook(productIdentifier, user);
	}, [user, productIdentifier]);
};
