import c from 'classnames';
import type { ReactNode } from 'react';
import styles from './AppModal.module.scss';

type AppModalBodyProps = {
	className?: string;
	children: ReactNode;
};

export const AppModalBody = ({ children, className }: AppModalBodyProps) => {
	return <div className={c(styles.body, className)}>{children}</div>;
};
