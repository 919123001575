import { DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const CardIcon = memo(
	({ color, opacity, size = DEFAULT_ICON_SIZE }: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0_5537_11899)" opacity={opacity}>
					<path
						d="M21.9544 4.87012H10.1283C9.19893 4.87012 8.44281 5.62622 8.44281 6.55561V13.6912C8.44281 14.6206 9.19894 15.3767 10.1283 15.3767H21.9544C22.8837 15.3767 23.6399 14.6206 23.6399 13.6912V6.55561C23.6399 5.62622 22.8837 4.87012 21.9544 4.87012ZM9.19328 7.872H22.8894V9.37295H9.19328V7.872ZM10.1283 5.62059H21.9544C22.4699 5.62059 22.8894 6.04003 22.8894 6.55561V7.12153H9.19328V6.55561C9.19328 6.04003 9.61274 5.62059 10.1283 5.62059ZM21.9544 14.6262H10.1283C9.61274 14.6262 9.19328 14.2068 9.19328 13.6912V10.1234H22.8894V13.6912C22.8894 14.2068 22.4699 14.6262 21.9544 14.6262Z"
						fill={color}
					/>
					<path
						d="M7.50467 8.43457H6.56658C5.6355 8.43457 4.87802 9.19205 4.87802 10.1231V11.6241C4.87802 11.8313 5.04602 11.9993 5.25326 11.9993C5.4605 11.9993 5.6285 11.8313 5.6285 11.6241V10.1231C5.6285 9.60586 6.04933 9.18504 6.56659 9.18504H7.50468C7.71193 9.18504 7.87992 9.01703 7.87992 8.8098C7.87991 8.60257 7.71192 8.43457 7.50467 8.43457Z"
						fill={color}
					/>
					<path
						d="M21.3883 10.874H18.1988C17.9916 10.874 17.8236 11.042 17.8236 11.2492V13.313C17.8236 13.5203 17.9916 13.6883 18.1988 13.6883H21.3883C21.5956 13.6883 21.7636 13.5203 21.7636 13.313V11.2492C21.7636 11.042 21.5956 10.874 21.3883 10.874ZM21.0131 12.9378H18.5741V11.6245H21.0131V12.9378Z"
						fill={color}
					/>
					<path
						d="M19.8875 15.9395C19.6803 15.9395 19.5123 16.1074 19.5123 16.3147V17.4404C19.5123 17.9576 19.0914 18.3785 18.5742 18.3785H8.81805C8.6108 18.3785 8.44281 18.5465 8.44281 18.7537C8.44281 18.9609 8.6108 19.1289 8.81805 19.1289H18.5742C19.5053 19.1289 20.2627 18.3715 20.2627 17.4404V16.3147C20.2627 16.1074 20.0947 15.9395 19.8875 15.9395Z"
						fill={color}
					/>
					<path
						d="M7.12926 15.377H1.50073C1.29349 15.377 1.12549 15.545 1.12549 15.7522C1.12549 15.9594 1.29349 16.1274 1.50073 16.1274H7.12926C7.33651 16.1274 7.5045 15.9594 7.5045 15.7522C7.50449 15.545 7.33651 15.377 7.12926 15.377Z"
						fill={color}
					/>
					<path
						d="M7.12948 17.6279H0.375238C0.168003 17.6279 0 17.7959 0 18.0032C0 18.2104 0.168003 18.3784 0.375238 18.3784H7.12948C7.33673 18.3784 7.50472 18.2104 7.50472 18.0032C7.50472 17.7959 7.33673 17.6279 7.12948 17.6279Z"
						fill={color}
					/>
					<path
						d="M7.12959 13.125H2.62676C2.41953 13.125 2.25153 13.293 2.25153 13.5002C2.25153 13.7075 2.41953 13.8755 2.62676 13.8755H7.12959C7.33684 13.8755 7.50483 13.7075 7.50483 13.5002C7.50483 13.293 7.33684 13.125 7.12959 13.125Z"
						fill={color}
					/>
				</g>
				<defs>
					<clipPath id="clip0_5537_11899">
						<rect fill="white" height={height} width={width} />
					</clipPath>
				</defs>
			</svg>
		);
	},
);
