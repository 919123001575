import { type Context, createContext, useContext } from 'react';
import type { AppModalProps } from './app-modal.type';

type AppModalContextValue = Omit<AppModalProps, 'children'>;

export const AppModalContext = createContext(
	{} as AppModalContextValue,
) as Context<AppModalContextValue>;

export const useAppModalContext = () => useContext(AppModalContext);
