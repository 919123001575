import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const BagIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 64 64"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M32.3526 54.3536C32.7096 53.9966 33.2885 53.9966 33.6455 54.3536C34.3931 55.1011 35.6051 55.1011 36.3526 54.3536C36.7096 53.9966 37.2885 53.9966 37.6455 54.3536L38.6455 55.3536L39.3526 54.6465L38.3526 53.6465C37.6051 52.8989 36.3931 52.8989 35.6455 53.6465C35.2885 54.0035 34.7096 54.0035 34.3526 53.6465C33.6051 52.8989 32.3931 52.8989 31.6455 53.6465L30.6455 54.6465L31.3526 55.3536L32.3526 54.3536Z"
						fill={color}
					/>
					<path
						clipRule="evenodd"
						d="M48.499 53C48.499 51.6193 49.6183 50.5 50.999 50.5C52.3797 50.5 53.499 51.6193 53.499 53C53.499 54.3807 52.3797 55.5 50.999 55.5C49.6183 55.5 48.499 54.3807 48.499 53ZM50.999 51.5C50.1706 51.5 49.499 52.1716 49.499 53C49.499 53.8284 50.1706 54.5 50.999 54.5C51.8274 54.5 52.499 53.8284 52.499 53C52.499 52.1716 51.8274 51.5 50.999 51.5Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						clipRule="evenodd"
						d="M27.999 5.5C23.3046 5.5 19.499 9.30558 19.499 14V17.5H18.4144C17.0831 17.5 16.0451 17.5 15.2163 17.5855C14.372 17.6727 13.6928 17.8525 13.0975 18.246C12.7314 18.4879 12.4027 18.7821 12.1218 19.1193C11.665 19.6675 11.4112 20.3226 11.2314 21.1522C11.0549 21.9664 10.9403 22.9979 10.7933 24.321L8.39062 45.9448L9.3845 46.0552L11.784 24.4601C11.9348 23.1024 12.0444 22.1221 12.2087 21.364C12.371 20.6154 12.577 20.135 12.89 19.7594C13.1085 19.4972 13.3642 19.2684 13.6489 19.0802C14.0568 18.8106 14.5569 18.6589 15.3189 18.5802C16.0905 18.5006 17.077 18.5 18.443 18.5H19.499V23.5H15.999V24.5H19.499V27C19.499 28.933 21.066 30.5 22.999 30.5C24.932 30.5 26.499 28.933 26.499 27C26.499 25.067 24.932 23.5 22.999 23.5H20.499V18.5L37.5544 18.5C38.9204 18.5 39.9068 18.5006 40.6784 18.5802C41.4404 18.6589 41.9406 18.8106 42.3484 19.0802C42.6332 19.2684 42.8888 19.4972 43.1073 19.7594C43.4203 20.135 43.6263 20.6154 43.7886 21.364C43.953 22.1221 44.0625 23.1024 44.2134 24.4601L45.6623 37.5H31.999C29.5137 37.5 27.499 39.5147 27.499 42V53.5H15.9987V54.5H27.5265C27.7752 56.75 29.6827 58.5 31.999 58.5H51.999C54.4843 58.5 56.499 56.4853 56.499 54V42C56.499 39.5147 54.4843 37.5 51.999 37.5H46.6684L45.2041 24.3212C45.0571 22.9981 44.9425 21.9665 44.7659 21.1522C44.5861 20.3226 44.3324 19.6675 43.8756 19.1193C43.5947 18.7821 43.2659 18.4879 42.8999 18.246C42.3046 17.8525 41.6254 17.6727 40.7811 17.5855C39.9522 17.5 38.9143 17.5 37.583 17.5H36.499V14C36.499 9.30558 32.6934 5.5 27.999 5.5ZM35.499 17.5V14C35.499 9.85786 32.1412 6.5 27.999 6.5C23.8569 6.5 20.499 9.85786 20.499 14V17.5H35.499ZM20.499 27V24.5H22.999C24.3797 24.5 25.499 25.6193 25.499 27C25.499 28.3807 24.3797 29.5 22.999 29.5C21.6183 29.5 20.499 28.3807 20.499 27ZM31.999 38.5C30.066 38.5 28.499 40.067 28.499 42V43.5L53.499 43.5V44.5L28.499 44.5V54C28.499 55.933 30.066 57.5 31.999 57.5H51.999C53.932 57.5 55.499 55.933 55.499 54V42C55.499 40.067 53.932 38.5 51.999 38.5H31.999Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
