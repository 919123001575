import c from 'classnames';
import { useOutsideClick } from 'hooks';
import { type ReactNode, useRef } from 'react';
import styles from './AppModal.module.scss';
import { useAppModalContext } from './app-modal.context';

type AppModalContainerProps = {
	className?: string;
	children: ReactNode;
};

export const AppModalContainer = ({
	className,
	children,
}: AppModalContainerProps) => {
	const { onClose } = useAppModalContext();

	const modalRef = useRef(null);

	useOutsideClick({
		elementRef: modalRef,
		callback: onClose,
	});
	return (
		<div ref={modalRef} className={c(styles.container, className)}>
			{children}
		</div>
	);
};
