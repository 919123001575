import { NetworkStatus } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { type MeUserFragment, useMeQuery } from 'api/core/generated';
import { AppApiVersions } from 'app-constants';

export const useGetUser = (skip = false) => {
	const { data, loading, error, refetch, networkStatus } = useMeQuery({
		context: { apiVersion: AppApiVersions.core },
		skip,
		onError: (error) => {
			Sentry.captureException('Cannot get user', { data: error });
		},
	});

	const getUser = () => refetch();

	return {
		user: data?.me as MeUserFragment,
		userLoading: loading,
		userError: error,
		userWasFetched: networkStatus === NetworkStatus.ready,
		getUser,
	};
};
