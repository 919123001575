import { AppLanguageSelector } from 'components/app-language-selector';
import { AppLogOutButton } from 'components/app-logout-button';
import { AppModal } from 'components/app-modal';
import { AppOpenCloseButton } from 'components/app-open-close-button';
import styles from './AppHeader.module.scss';

type AppMobileHeaderMenuModalProps = {
	onClose: () => void;
	shouldShowLogoutButton: boolean;
};

export const AppMobileHeaderMenuModal = ({
	onClose,
	shouldShowLogoutButton,
}: AppMobileHeaderMenuModalProps) => {
	return (
		<AppModal onClose={onClose}>
			<AppModal.ModalContainer
				className={styles['mobile-header-menu-modal__container']}
			>
				<AppModal.ModalHeader
					className={styles['mobile-header-menu-modal__header']}
				>
					<AppOpenCloseButton isOpen onClick={onClose} />
				</AppModal.ModalHeader>
				<AppModal.ModalBody>
					<AppLanguageSelector isSelect={false} onLanguageSelected={onClose} />
					{shouldShowLogoutButton ? (
						<AppLogOutButton onLogoutButtonClick={onClose} />
					) : null}
				</AppModal.ModalBody>
			</AppModal.ModalContainer>
		</AppModal>
	);
};
