import type { AppLanguages } from 'app-constants';

export type AvailableLanguage =
	| AppLanguages.et
	| AppLanguages.en
	| AppLanguages.lt
	| AppLanguages.lv
	| AppLanguages.ru;

export enum AppRegions {
	EE = 'EE',
	LV = 'LV',
	LT = 'LT',
}
