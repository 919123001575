import { MODAL_CONTAINER_CLASSNAME } from 'app-constants';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const createPortalContainer = () => {
	const div = document.createElement('div');
	div.classList.add(MODAL_CONTAINER_CLASSNAME);

	return div;
};

const AppPortal = ({ children }) => {
	const [portalContainer] = useState(createPortalContainer());

	useEffect(() => {
		document.getElementById('root').appendChild(portalContainer);

		return () => document.getElementById('root').removeChild(portalContainer);
	}, [portalContainer]);

	return createPortal(children, portalContainer);
};

export default AppPortal;
