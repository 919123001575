import { lazy } from 'react';

// Schedule pages

const HirePurchaseSchedulePage = lazy(() =>
	import('../../pages/hire-purchase/schedule').then((module) => ({
		default: module.HirePurchaseSchedulePage,
	})),
);

// Checkout pages

const CreditLineCheckoutPage = lazy(() =>
	import('../../pages/credit-line/checkout').then((module) => ({
		default: module.CreditLineCheckoutPage,
	})),
);

const HirePurchaseCheckoutPage = lazy(() =>
	import('../../pages/hire-purchase/checkout').then((module) => ({
		default: module.HirePurchaseCheckoutPage,
	})),
);

const SmallLoanCheckoutPage = lazy(() =>
	import('../../pages/small-loan/checkout').then((module) => ({
		default: module.SmallLoanCheckoutPage,
	})),
);

const CreditLineWithdrawalCheckoutPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/checkout').then((module) => ({
		default: module.CreditLineWithdrawalCheckoutPage,
	})),
);

const CreditLimitRecalculationCheckoutPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/checkout').then((module) => ({
		default: module.CreditLimitRecalculationCheckoutPage,
	})),
);

// Login pages

const HirePurchaseLoginPage = lazy(() =>
	import('../../pages/hire-purchase/login').then((module) => ({
		default: module.HirePurchaseLoginPage,
	})),
);

const SmallLoanLoginPage = lazy(() =>
	import('../../pages/small-loan/login').then((module) => ({
		default: module.SmallLoanLoginPage,
	})),
);

const CreditLineLoginPage = lazy(() =>
	import('../../pages/credit-line/login').then((module) => ({
		default: module.CreditLineLoginPage,
	})),
);

const CreditLineWithdrawalLoginPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/login').then((module) => ({
		default: module.CreditLineWithdrawalLoginPage,
	})),
);

const CreditLimitRecalculationLoginPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/login').then((module) => ({
		default: module.CreditLimitRecalculationLoginPage,
	})),
);

// Contact pages

const HirePurchaseContactPage = lazy(() =>
	import('../../pages/hire-purchase/contact').then((module) => ({
		default: module.HirePurchaseContactPage,
	})),
);

const SmallLoanContactPage = lazy(() =>
	import('../../pages/small-loan/contact').then((module) => ({
		default: module.SmallLoanContactPage,
	})),
);

const CreditLineContactPage = lazy(() =>
	import('../../pages/credit-line/contact').then((module) => ({
		default: module.CreditLineContactPage,
	})),
);

const CreditLimitRecalculationContactPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/contact').then((module) => ({
		default: module.CreditLimitRecalculationContactPage,
	})),
);

const CreditLineWithdrawalContactPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/contact').then((module) => ({
		default: module.CreditLineWithdrawalContactPage,
	})),
);

// Purpose of loan pages

const HirePurchasePurposeOfLoanPage = lazy(() =>
	import('../../pages/hire-purchase/purpose-of-loan').then((module) => ({
		default: module.HirePurchasePurposeOfLoanPage,
	})),
);

const SmallLoanPurposeOfLoanPage = lazy(() =>
	import('../../pages/small-loan/purpose-of-loan').then((module) => ({
		default: module.SmallLoanPurposeOfLoanPage,
	})),
);

const CreditLinePurposeOfLoanPage = lazy(() =>
	import('../../pages/credit-line/purpose-of-loan').then((module) => ({
		default: module.CreditLinePurposeOfLoanPage,
	})),
);

const CreditLineWithdrawalPurposeOfLoanPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/purpose-of-loan').then(
		(module) => ({
			default: module.CreditLineWithdrawalPurposeOfLoanPage,
		}),
	),
);

const CreditLimitRecalculationPurposeOfLoanPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/purpose-of-loan').then(
		(module) => ({
			default: module.CreditLimitRecalculationPurposeOfLoanPage,
		}),
	),
);

// Contact extra pages

const HirePurchaseContactExtraPage = lazy(() =>
	import('../../pages/hire-purchase/contact-extra').then((module) => ({
		default: module.HirePurchaseContactExtraPage,
	})),
);

const SmallLoanContactExtraPage = lazy(() =>
	import('../../pages/small-loan/contact-extra').then((module) => ({
		default: module.SmallLoanContactExtraPage,
	})),
);

const CreditLineContactExtraPage = lazy(() =>
	import('../../pages/credit-line/contact-extra').then((module) => ({
		default: module.CreditLineContactExtraPage,
	})),
);

const CreditLimitRecalculationContactExtraPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/contact-extra').then(
		(module) => ({
			default: module.CreditLimitRecalculationContactExtraPage,
		}),
	),
);

const CreditLineWithdrawalContactExtraPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/contact-extra').then((module) => ({
		default: module.CreditLineWithdrawalContactExtraPage,
	})),
);

// Account scoring pages

const HirePurchaseAccountScoringPage = lazy(() =>
	import('../../pages/hire-purchase/account-scoring').then((module) => ({
		default: module.HirePurchaseAccountScoringPage,
	})),
);

const SmallLoanAccountScoringPage = lazy(() =>
	import('../../pages/small-loan/account-scoring').then((module) => ({
		default: module.SmallLoanAccountScoringPage,
	})),
);

const CreditLineAccountScoringPage = lazy(() =>
	import('../../pages/credit-line/account-scoring').then((module) => ({
		default: module.CreditLineAccountScoringPage,
	})),
);

const CreditLimitRecalculationAccountScoringPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/account-scoring').then(
		(module) => ({
			default: module.CreditLimitRecalculationAccountScoringPage,
		}),
	),
);

const CreditLineWithdrawalAccountScoringPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/account-scoring').then(
		(module) => ({
			default: module.CreditLineWithdrawalAccountScoringPage,
		}),
	),
);

// EMTA consent pages

const HirePurchaseEmtaConsentPage = lazy(() =>
	import('../../pages/hire-purchase/emta-consent').then((module) => ({
		default: module.HirePurchaseEmtaConsentPage,
	})),
);

const SmallLoanEmtaConsentPage = lazy(() =>
	import('../../pages/small-loan/emta-consent').then((module) => ({
		default: module.SmallLoanEmtaConsentPage,
	})),
);

const CreditLineEmtaConsentPage = lazy(() =>
	import('../../pages/credit-line/emta-consent').then((module) => ({
		default: module.CreditLineEmtaConsentPage,
	})),
);

const CreditLimitRecalculationEmtaConsentPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/emta-consent').then(
		(module) => ({
			default: module.CreditLimitRecalculationEmtaConsentPage,
		}),
	),
);

const CreditLineWithdrawalEmtaConsentPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/emta-consent').then((module) => ({
		default: module.CreditLineWithdrawalEmtaConsentPage,
	})),
);

// Signing pages

const HirePurchaseSigningPage = lazy(() =>
	import('../../pages/hire-purchase/signing').then((module) => ({
		default: module.HirePurchaseSigningPage,
	})),
);

const SmallLoanSigningPage = lazy(() =>
	import('../../pages/small-loan/signing').then((module) => ({
		default: module.SmallLoanSigningPage,
	})),
);

const CreditLineSigningPage = lazy(() =>
	import('../../pages/credit-line/signing').then((module) => ({
		default: module.CreditLineSigningPage,
	})),
);

// Success pages

const HirePurchaseSuccessPage = lazy(() =>
	import('../../pages/hire-purchase/success').then((module) => ({
		default: module.HirePurchaseSuccessPage,
	})),
);

const SmallLoanSuccessPage = lazy(() =>
	import('../../pages/small-loan/success').then((module) => ({
		default: module.SmallLoanSuccessPage,
	})),
);

const CreditLineSuccessPage = lazy(() =>
	import('../../pages/credit-line/success').then((module) => ({
		default: module.CreditLineSuccessPage,
	})),
);

// Reject pages

const HirePurchaseRejectPage = lazy(() =>
	import('../../pages/hire-purchase/reject').then((module) => ({
		default: module.HirePurchaseRejectPage,
	})),
);

const SmallLoanRejectPage = lazy(() =>
	import('../../pages/small-loan/reject').then((module) => ({
		default: module.SmallLoanRejectPage,
	})),
);

const CreditLineRejectPage = lazy(() =>
	import('../../pages/credit-line/reject').then((module) => ({
		default: module.CreditLineRejectPage,
	})),
);

// Pending pages

const HirePurchasePendingPage = lazy(() =>
	import('../../pages/hire-purchase/pending').then((module) => ({
		default: module.HirePurchasePendingPage,
	})),
);

const SmallLoanPendingPage = lazy(() =>
	import('../../pages/small-loan/pending').then((module) => ({
		default: module.SmallLoanPendingPage,
	})),
);

const CreditLinePendingPage = lazy(() =>
	import('../../pages/credit-line/pending').then((module) => ({
		default: module.CreditLinePendingPage,
	})),
);

const CreditLimitRecalculationPendingPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/pending').then((module) => ({
		default: module.CreditLimitRecalculationPendingPage,
	})),
);

const CreditLineWithdrawalPendingPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/pending').then((module) => ({
		default: module.CreditLineWithdrawalPendingPage,
	})),
);
// Error pages

const ErrorPage = lazy(() =>
	import('../../pages/error-page').then((module) => ({
		default: module.ErrorPage,
	})),
);

// Spouse consent pages

const SmallLoanSpouseConsentPage = lazy(() =>
	import('../../pages/small-loan/spouse-consent').then((module) => ({
		default: module.SmallLoanSpouseConsentPage,
	})),
);

const HirePurchaseSpouseConsentPage = lazy(() =>
	import('../../pages/hire-purchase/spouse-consent').then((module) => ({
		default: module.HirePurchaseSpouseConsentPage,
	})),
);

const CreditLineSpouseConsentPage = lazy(() =>
	import('../../pages/credit-line/spouse-consent').then((module) => ({
		default: module.CreditLineSpouseConsentPage,
	})),
);

const CreditLimitRecalculationSpouseConsentPage = lazy(() =>
	import('../../pages/credit-limit-recalculation/spouse-consent').then(
		(module) => ({
			default: module.CreditLimitRecalculationSpouseConsentPage,
		}),
	),
);

const CreditLineWithdrawalSpouseConsentPage = lazy(() =>
	import('../../pages/credit-line-withdrawal/spouse-consent').then(
		(module) => ({
			default: module.CreditLineWithdrawalSpouseConsentPage,
		}),
	),
);

// Short link Handler Page

const ShortLinkHandlerPage = lazy(() =>
	import('../../pages/short-link-handler').then((module) => ({
		default: module.ShortLinkHandlerPage,
	})),
);

// Hire purchase related pages

const AfterDirectPaymentPage = lazy(() =>
	import('../../pages/hire-purchase/after-direct-payment').then((module) => ({
		default: module.AfterDirectPaymentPage,
	})),
);

const DirectPaymentPage = lazy(() =>
	import('../../pages/hire-purchase/direct-payment').then((module) => ({
		default: module.DirectPaymentPage,
	})),
);

export default {
	// Schedule pages
	HirePurchaseSchedulePage,
	// Checkout pages
	HirePurchaseCheckoutPage,
	SmallLoanCheckoutPage,
	CreditLineCheckoutPage,
	CreditLineWithdrawalCheckoutPage,
	CreditLimitRecalculationCheckoutPage,
	// Login pages
	HirePurchaseLoginPage,
	SmallLoanLoginPage,
	CreditLineLoginPage,
	CreditLineWithdrawalLoginPage,
	CreditLimitRecalculationLoginPage,
	// Contact pages
	HirePurchaseContactPage,
	SmallLoanContactPage,
	CreditLineContactPage,
	CreditLimitRecalculationContactPage,
	CreditLineWithdrawalContactPage,
	// Purpose of loan pages
	HirePurchasePurposeOfLoanPage,
	SmallLoanPurposeOfLoanPage,
	CreditLinePurposeOfLoanPage,
	CreditLineWithdrawalPurposeOfLoanPage,
	CreditLimitRecalculationPurposeOfLoanPage,
	// Contact extra pages
	HirePurchaseContactExtraPage,
	SmallLoanContactExtraPage,
	CreditLineContactExtraPage,
	CreditLimitRecalculationContactExtraPage,
	CreditLineWithdrawalContactExtraPage,
	// Account scoring pages
	HirePurchaseAccountScoringPage,
	SmallLoanAccountScoringPage,
	CreditLineAccountScoringPage,
	CreditLimitRecalculationAccountScoringPage,
	CreditLineWithdrawalAccountScoringPage,
	// EMTA consent pages
	HirePurchaseEmtaConsentPage,
	SmallLoanEmtaConsentPage,
	CreditLineEmtaConsentPage,
	CreditLimitRecalculationEmtaConsentPage,
	CreditLineWithdrawalEmtaConsentPage,
	// Spouse consent pages
	SmallLoanSpouseConsentPage,
	HirePurchaseSpouseConsentPage,
	CreditLineSpouseConsentPage,
	CreditLimitRecalculationSpouseConsentPage,
	CreditLineWithdrawalSpouseConsentPage,
	// Signing pages
	HirePurchaseSigningPage,
	SmallLoanSigningPage,
	CreditLineSigningPage,
	// Success pages
	HirePurchaseSuccessPage,
	SmallLoanSuccessPage,
	CreditLineSuccessPage,
	// Reject pages
	HirePurchaseRejectPage,
	SmallLoanRejectPage,
	CreditLineRejectPage,
	// Pending pages
	HirePurchasePendingPage,
	SmallLoanPendingPage,
	CreditLinePendingPage,
	CreditLimitRecalculationPendingPage,
	CreditLineWithdrawalPendingPage,
	// Error Pages
	ErrorPage,
	// Short Link Handler Page
	ShortLinkHandlerPage,
	// Hire Purchase related pages
	AfterDirectPaymentPage,
	DirectPaymentPage,
};
