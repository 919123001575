export enum ButtonVariants {
	primary = 'button--primary',
	secondary = 'button--secondary',
	white = 'button--white',
	black = 'button--black',
	transparent = 'button--transparent',
	login = 'button--login',
}

export enum ButtonSizes {
	small = 'button--small',
	medium = 'button--medium',
	large = 'button--large',
}

export enum LogoutButtonVariants {
	primary = 'primary',
	center = 'center',
}
