export enum LocalStorageKeys {
	pageUrlQueryVariables = 'page-url-q-vars',
	language = 'language',
	i18NextLanguage = 'i18nextLng',
	purposesOfLoan = 'purposes-of-loan',
	sessionId = 'session-id',
	accountScoringSteps = 'acc-scoring-steps',
	smallLoanInitialRequestedAmount = 'sl-init-requested-amount',
	creditLineChosenAmount = 'cl-chosen-amount',
	storedQueryParams = 'stored-query-params',
	initialApplicationSchedule = 'initial-application-schedule',
	smartlookIdentifier = 'smartlook-identifier',
	isRedirectedFromThirdPartyApi = 'is-redirected-from-third-party-api',
}

export enum LocalStorageBooleanValue {
	true = 'true',
	false = 'false',
}
