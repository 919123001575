import { useApplicationPrivateLabelLazyQuery } from 'api/core/generated';
import { AppApiVersions, AppSearchParams } from 'app-constants';
import type { AnyObject } from 'models';
import { useSearchParams } from 'react-router-dom';

export const useApplicationPrivateLabel = () => {
	const [searchParams] = useSearchParams();
	const reference_key = searchParams.get(AppSearchParams.referenceKey) || '';

	const [getApplicationPrivateInfo, { data, loading, error }] =
		useApplicationPrivateLabelLazyQuery({
			context: { apiVersion: AppApiVersions.core },
			fetchPolicy: 'network-only',
		});

	return {
		getApplicationPrivateInfo: () =>
			getApplicationPrivateInfo({ variables: { reference_key } }),
		applicationPrivateInfo: (data?.applicationInfo ?? null) as AnyObject | null,
		applicationPrivateInfoLoading: loading,
		applicationPrivateInfoError: error,
	};
};
