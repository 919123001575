import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const EyeClosedPasswordIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M4 4L20 20"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.5"
					/>
					<path
						d="M10.8572 5.76582C11.2304 5.72231 11.6116 5.69995 12 5.69995C16.1976 5.69995 19.5599 8.31233 21 12C20.6449 12.9093 20.1728 13.7533 19.6002 14.5088M7.06766 7.06744C5.23159 8.1876 3.811 9.92328 3 12C4.44012 15.6876 7.80243 18.3 12 18.3C13.8338 18.3 15.5082 17.8014 16.9325 16.9323M10.0909 10.0907C9.60224 10.5793 9.3 11.2543 9.3 12C9.3 13.4911 10.5088 14.7 12 14.7C12.7456 14.7 13.4207 14.3977 13.9093 13.9091"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M4.7998 4.80005L19.1998 19.2"
						stroke={color}
						strokeLinecap="round"
						strokeWidth="1.5"
					/>
				</g>
			</svg>
		);
	},
);
