import { DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const CheckMarkIcon = memo(
	({ color, opacity, size = DEFAULT_ICON_SIZE }: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M5 13L8 17L19 6"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.25"
					/>
				</g>
			</svg>
		);
	},
);
