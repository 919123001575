import {
	CREDIT_LINE_FAQ_URLS,
	CREDY_TERMS_PAGE_URLS,
	FAQ_URLS,
	HOMEPAGE_CREDIT_LINE_PAGE_URLS,
	PRIVACY_POLICY_URL,
	TERMS_PAGE_URLS,
	customerProfileUrl,
	newCustomerProfileUrl,
} from 'environment';

export const REDIRECT_URLS = {
	newCustomerProfile: newCustomerProfileUrl,
	customerProfile: customerProfileUrl,
	customerProfileCreditLineOnboarding: `${customerProfileUrl}/#getCredit`,
	customerProfileSubscription: `${customerProfileUrl}/pay/subscription`,
	customerProfileApplication: `${customerProfileUrl}/pay/application`,
	frequentlyAskedQuestionsPageUrls: FAQ_URLS,
	creditLineFrequentlyAskedQuestionsPageUrls: CREDIT_LINE_FAQ_URLS,
	termsPageUrs: TERMS_PAGE_URLS,
	credyTermsPageUrs: CREDY_TERMS_PAGE_URLS,
	homepageCreditLinePageUrls: HOMEPAGE_CREDIT_LINE_PAGE_URLS,
	privacyPolicy: PRIVACY_POLICY_URL,
};
