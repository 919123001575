import {
	APPLICATION_PRODUCT_TYPES,
	LocalStorageKeys,
	MOBILE_SCREEN_SIZE,
	TABLET_SCREEN_SIZE,
} from 'app-constants';
import type { RootLogic } from 'models';
import { useEffect } from 'react';
import { useGetApplicationByReference } from './use-get-application-by-reference';
import { useGetApplicationPrivateInfo } from './use-get-application-private-info';
import { useGetCurrentStepCount } from './use-get-current-step-count';
import { useGetLanguages } from './use-get-languages';
import { useGetPageUrl } from './use-get-page-url';
import { useGetProductTypeByUrl } from './use-get-product-type-by-url';
import { useGetUser } from './use-get-user';
import { useInitializeAppServices } from './use-initialize-app-services';
import { useSetPageTitle } from './use-set-page-title';
import { useTrackGoogleAnalytics } from './use-track-google-analytics';
import { useCheckMaxResolution, useLocalStorage } from './utils';

export const useRootLogic = (): RootLogic => {
	const productType = useGetProductTypeByUrl();
	const { languages } = useGetLanguages();
	const { user, getUser, userLoading, userWasFetched } = useGetUser();
	const { getApplication, application } = useGetApplicationByReference();
	const {
		applicationPrivateInfo,
		applicationPrivateInfoLoading,
		getApplicationPrivateInfo,
	} = useGetApplicationPrivateInfo(application.schedule_type);
	const currentStepCount = useGetCurrentStepCount();
	const isTablet = useCheckMaxResolution(TABLET_SCREEN_SIZE);
	const isMobile = useCheckMaxResolution(MOBILE_SCREEN_SIZE);
	const { getPageUrlAndNavigate, pageUrlAndNavigationProcessing } =
		useGetPageUrl();
	const trackGoogleAnalyticsEvent = useTrackGoogleAnalytics();
	const [initApplicationSchedule, setInitApplicationSchedule] = useLocalStorage<
		Record<number, string>
	>(LocalStorageKeys.initialApplicationSchedule, {});
	useInitializeAppServices(user);
	useSetPageTitle(application);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (
			!application.id &&
			productType &&
			APPLICATION_PRODUCT_TYPES.includes(productType)
		) {
			getApplication();
		}
	}, [productType]);

	useEffect(() => {
		if (application.id && !initApplicationSchedule[application.id]) {
			setInitApplicationSchedule((prev) => ({
				...prev,
				[application.id]: application.schedule_type,
			}));
		}
	}, [
		application.id,
		initApplicationSchedule,
		application.schedule_type,
		setInitApplicationSchedule,
	]);

	return {
		productType,
		applicationPrivateInfo,
		applicationPrivateInfoLoading,
		languages,
		user,
		currentStepCount,
		isTablet,
		isMobile,
		getUser,
		userWasFetched,
		userLoading,
		getPageUrlAndNavigate,
		pageUrlAndNavigationProcessing,
		getApplicationPrivateInfo,
		trackGoogleAnalyticsEvent,
	};
};
