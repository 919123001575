import { wrapUseRoutes } from '@sentry/react';
import {
	AppRoutePaths,
	AppRouterParams,
	CreditLimitRecalculationRoutePaths,
	CreditLineRoutePaths,
	CreditLineWithdrawalRoutePaths,
	ERROR_PAGE_PATH,
	HirePurchaseRoutePaths,
	ROOT_PAGE_PATH,
	SmallLoanRoutePaths,
	UNKNOWN_PAGE_ROUTE_PATH,
} from 'app-constants';
import { useShouldShowSchedulePage } from 'hooks';
import { memo } from 'react';
import { useRoutes } from 'react-router-dom';
import { CreditLineRoot } from './CreditLineRoot';
import { PageUrlCheckedRoute } from './PageUrlCheckedRoute';
import { Redirect } from './Redirect';
import pages from './lazy-pages';

const {
	// Schedule pages
	HirePurchaseSchedulePage,
	// Checkout pages
	HirePurchaseCheckoutPage,
	SmallLoanCheckoutPage,
	CreditLineWithdrawalCheckoutPage,
	CreditLimitRecalculationCheckoutPage,
	CreditLineCheckoutPage,
	// Login pages
	HirePurchaseLoginPage,
	SmallLoanLoginPage,
	CreditLineLoginPage,
	CreditLineWithdrawalLoginPage,
	CreditLimitRecalculationLoginPage,
	// Contact pages
	HirePurchaseContactPage,
	SmallLoanContactPage,
	CreditLineContactPage,
	CreditLimitRecalculationContactPage,
	CreditLineWithdrawalContactPage,
	// Purpose of loan pages
	HirePurchasePurposeOfLoanPage,
	SmallLoanPurposeOfLoanPage,
	CreditLinePurposeOfLoanPage,
	CreditLimitRecalculationPurposeOfLoanPage,
	CreditLineWithdrawalPurposeOfLoanPage,
	// Contact extra pages
	HirePurchaseContactExtraPage,
	SmallLoanContactExtraPage,
	CreditLineContactExtraPage,
	CreditLimitRecalculationContactExtraPage,
	CreditLineWithdrawalContactExtraPage,
	// Account scoring pages
	HirePurchaseAccountScoringPage,
	SmallLoanAccountScoringPage,
	CreditLineAccountScoringPage,
	CreditLimitRecalculationAccountScoringPage,
	CreditLineWithdrawalAccountScoringPage,
	// EMTA consent pages
	HirePurchaseEmtaConsentPage,
	SmallLoanEmtaConsentPage,
	CreditLineEmtaConsentPage,
	CreditLimitRecalculationEmtaConsentPage,
	CreditLineWithdrawalEmtaConsentPage,
	// Spouse consent pages
	HirePurchaseSpouseConsentPage,
	SmallLoanSpouseConsentPage,
	CreditLineSpouseConsentPage,
	CreditLimitRecalculationSpouseConsentPage,
	CreditLineWithdrawalSpouseConsentPage,
	// Signing pages
	HirePurchaseSigningPage,
	SmallLoanSigningPage,
	CreditLineSigningPage,
	// Success pages
	HirePurchaseSuccessPage,
	SmallLoanSuccessPage,
	CreditLineSuccessPage,
	// Reject pages
	HirePurchaseRejectPage,
	SmallLoanRejectPage,
	CreditLineRejectPage,
	// Pending pages
	HirePurchasePendingPage,
	SmallLoanPendingPage,
	CreditLinePendingPage,
	CreditLimitRecalculationPendingPage,
	CreditLineWithdrawalPendingPage,
	// Error pages
	ErrorPage,
	// Short link handler page
	ShortLinkHandlerPage,
	// Hire Purchase related pages
	AfterDirectPaymentPage,
	DirectPaymentPage,
} = pages;

const sharedRoutes = [
	{
		path: ERROR_PAGE_PATH,
		element: <ErrorPage />,
	},
	{
		path: UNKNOWN_PAGE_ROUTE_PATH,
		element: <Redirect to={ERROR_PAGE_PATH} />,
	},
];

const useSentryRoutes = wrapUseRoutes(useRoutes);

const AppRouter = memo(() => {
	const shouldNavigateToSchedulePage = useShouldShowSchedulePage();
	return useSentryRoutes([
		{
			path: `${AppRoutePaths.SHORT_LINK}/:${AppRouterParams.shortReferenceCode}`,
			element: <ShortLinkHandlerPage />,
		},
		{
			path: AppRoutePaths.HIRE_PURCHASE,
			children: [
				{
					path: ROOT_PAGE_PATH,
					element: (
						<Redirect
							to={
								shouldNavigateToSchedulePage
									? HirePurchaseRoutePaths.SCHEDULE
									: HirePurchaseRoutePaths.CHECKOUT
							}
						/>
					),
				},
				{
					path: HirePurchaseRoutePaths.SCHEDULE,
					element: <PageUrlCheckedRoute component={HirePurchaseSchedulePage} />,
				},
				{
					path: HirePurchaseRoutePaths.CHECKOUT,
					element: <PageUrlCheckedRoute component={HirePurchaseCheckoutPage} />,
				},
				{
					path: HirePurchaseRoutePaths.LOGIN,
					element: <PageUrlCheckedRoute component={HirePurchaseLoginPage} />,
				},
				{
					path: HirePurchaseRoutePaths.CONTACT,
					element: <PageUrlCheckedRoute component={HirePurchaseContactPage} />,
				},
				{
					path: HirePurchaseRoutePaths.PURPOSE_OF_LOAN,
					element: (
						<PageUrlCheckedRoute component={HirePurchasePurposeOfLoanPage} />
					),
				},
				{
					path: HirePurchaseRoutePaths.CONTACT_EXTRA,
					element: (
						<PageUrlCheckedRoute component={HirePurchaseContactExtraPage} />
					),
				},
				{
					path: HirePurchaseRoutePaths.SPOUSE,
					element: <HirePurchaseSpouseConsentPage />,
				},
				{
					path: HirePurchaseRoutePaths.ACCOUNT_SCORING,
					element: (
						<PageUrlCheckedRoute component={HirePurchaseAccountScoringPage} />
					),
				},
				{
					path: HirePurchaseRoutePaths.EMTA_CONSENT,
					element: (
						<PageUrlCheckedRoute component={HirePurchaseEmtaConsentPage} />
					),
				},
				{
					path: HirePurchaseRoutePaths.SIGNING,
					element: <PageUrlCheckedRoute component={HirePurchaseSigningPage} />,
				},
				{
					path: HirePurchaseRoutePaths.PENDING,
					element: <PageUrlCheckedRoute component={HirePurchasePendingPage} />,
				},
				{
					path: HirePurchaseRoutePaths.SUCCESS,
					element: <PageUrlCheckedRoute component={HirePurchaseSuccessPage} />,
				},
				{
					path: HirePurchaseRoutePaths.DIRECT_PAYMENT,
					element: <DirectPaymentPage />,
				},
				{
					path: HirePurchaseRoutePaths.AFTER_DIRECT_PAYMENT,
					element: <AfterDirectPaymentPage />,
				},
				{
					path: HirePurchaseRoutePaths.AFTER_DIRECT_CARD_PAYMENT,
					element: <AfterDirectPaymentPage />,
				},
				{
					path: HirePurchaseRoutePaths.REJECT,
					element: <PageUrlCheckedRoute component={HirePurchaseRejectPage} />,
				},
				...sharedRoutes,
			],
		},
		{
			path: AppRoutePaths.SMALL_LOAN,
			children: [
				{
					path: ROOT_PAGE_PATH,
					element: <Redirect to={SmallLoanRoutePaths.CHECKOUT} />,
				},
				{
					path: SmallLoanRoutePaths.CHECKOUT,
					element: <PageUrlCheckedRoute component={SmallLoanCheckoutPage} />,
				},
				{
					path: SmallLoanRoutePaths.LOGIN,
					element: <PageUrlCheckedRoute component={SmallLoanLoginPage} />,
				},
				{
					path: SmallLoanRoutePaths.CONTACT,
					element: <PageUrlCheckedRoute component={SmallLoanContactPage} />,
				},
				{
					path: SmallLoanRoutePaths.PURPOSE_OF_LOAN,
					element: (
						<PageUrlCheckedRoute component={SmallLoanPurposeOfLoanPage} />
					),
				},
				{
					path: SmallLoanRoutePaths.CONTACT_EXTRA,
					element: (
						<PageUrlCheckedRoute component={SmallLoanContactExtraPage} />
					),
				},
				{
					path: SmallLoanRoutePaths.SPOUSE,
					element: <SmallLoanSpouseConsentPage />,
				},
				{
					path: SmallLoanRoutePaths.ACCOUNT_SCORING,
					element: (
						<PageUrlCheckedRoute component={SmallLoanAccountScoringPage} />
					),
				},
				{
					path: SmallLoanRoutePaths.EMTA_CONSENT,
					element: <PageUrlCheckedRoute component={SmallLoanEmtaConsentPage} />,
				},
				{
					path: SmallLoanRoutePaths.SIGNING,
					element: <PageUrlCheckedRoute component={SmallLoanSigningPage} />,
				},
				{
					path: SmallLoanRoutePaths.PENDING,
					element: <PageUrlCheckedRoute component={SmallLoanPendingPage} />,
				},
				{
					path: SmallLoanRoutePaths.SUCCESS,
					element: <PageUrlCheckedRoute component={SmallLoanSuccessPage} />,
				},
				{
					path: SmallLoanRoutePaths.REJECT,
					element: <PageUrlCheckedRoute component={SmallLoanRejectPage} />,
				},
				{
					path: ERROR_PAGE_PATH,
					element: <ErrorPage />,
				},
				{
					path: UNKNOWN_PAGE_ROUTE_PATH,
					element: <Redirect to={ERROR_PAGE_PATH} />,
				},
			],
		},
		{
			path: AppRoutePaths.CREDIT_LINE,
			element: <CreditLineRoot />,
			children: [
				{
					path: CreditLineRoutePaths.CHECKOUT,
					element: <PageUrlCheckedRoute component={CreditLineCheckoutPage} />,
				},
				{
					path: CreditLineRoutePaths.LOGIN,
					element: <PageUrlCheckedRoute component={CreditLineLoginPage} />,
				},
				{
					path: CreditLineRoutePaths.CONTACT,
					element: <PageUrlCheckedRoute component={CreditLineContactPage} />,
				},
				{
					path: CreditLineRoutePaths.PURPOSE_OF_LOAN,
					element: (
						<PageUrlCheckedRoute component={CreditLinePurposeOfLoanPage} />
					),
				},
				{
					path: CreditLineRoutePaths.CONTACT_EXTRA,
					element: (
						<PageUrlCheckedRoute component={CreditLineContactExtraPage} />
					),
				},
				{
					path: CreditLineRoutePaths.SPOUSE,
					element: <CreditLineSpouseConsentPage />,
				},
				{
					path: CreditLineRoutePaths.ACCOUNT_SCORING,
					element: (
						<PageUrlCheckedRoute component={CreditLineAccountScoringPage} />
					),
				},
				{
					path: CreditLineRoutePaths.EMTA_CONSENT,
					element: (
						<PageUrlCheckedRoute component={CreditLineEmtaConsentPage} />
					),
				},
				{
					path: CreditLineRoutePaths.SIGNING,
					element: <PageUrlCheckedRoute component={CreditLineSigningPage} />,
				},
				{
					path: CreditLineRoutePaths.PENDING,
					element: <PageUrlCheckedRoute component={CreditLinePendingPage} />,
				},
				{
					path: CreditLineRoutePaths.SUCCESS,
					element: <PageUrlCheckedRoute component={CreditLineSuccessPage} />,
				},
				{
					path: CreditLineRoutePaths.REJECT,
					element: <PageUrlCheckedRoute component={CreditLineRejectPage} />,
				},
				...sharedRoutes,
			],
		},
		{
			path: AppRoutePaths.CREDIT_LIMIT_RECALCULATION,
			children: [
				{
					path: ROOT_PAGE_PATH,
					element: (
						<Redirect to={CreditLimitRecalculationRoutePaths.CHECKOUT} />
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.CHECKOUT,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationCheckoutPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.LOGIN,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationLoginPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.CONTACT,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationContactPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.PURPOSE_OF_LOAN,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationPurposeOfLoanPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.CONTACT_EXTRA,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationContactExtraPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.SPOUSE,
					element: <CreditLimitRecalculationSpouseConsentPage />,
				},
				{
					path: CreditLimitRecalculationRoutePaths.ACCOUNT_SCORING,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationAccountScoringPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.EMTA_CONSENT,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationEmtaConsentPage}
						/>
					),
				},
				{
					path: CreditLimitRecalculationRoutePaths.PENDING,
					element: (
						<PageUrlCheckedRoute
							component={CreditLimitRecalculationPendingPage}
						/>
					),
				},
				...sharedRoutes,
			],
		},
		{
			path: AppRoutePaths.CREDIT_LINE_WITHDRAWAL,
			children: [
				{
					path: ROOT_PAGE_PATH,
					element: <Redirect to={CreditLineWithdrawalRoutePaths.CHECKOUT} />,
				},
				{
					path: CreditLineWithdrawalRoutePaths.CHECKOUT,
					element: (
						<PageUrlCheckedRoute component={CreditLineWithdrawalCheckoutPage} />
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.LOGIN,
					element: (
						<PageUrlCheckedRoute component={CreditLineWithdrawalLoginPage} />
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.CONTACT,
					element: (
						<PageUrlCheckedRoute component={CreditLineWithdrawalContactPage} />
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.PURPOSE_OF_LOAN,
					element: (
						<PageUrlCheckedRoute
							component={CreditLineWithdrawalPurposeOfLoanPage}
						/>
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.CONTACT_EXTRA,
					element: (
						<PageUrlCheckedRoute
							component={CreditLineWithdrawalContactExtraPage}
						/>
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.SPOUSE,
					element: <CreditLineWithdrawalSpouseConsentPage />,
				},
				{
					path: CreditLineWithdrawalRoutePaths.ACCOUNT_SCORING,
					element: (
						<PageUrlCheckedRoute
							component={CreditLineWithdrawalAccountScoringPage}
						/>
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.EMTA_CONSENT,
					element: (
						<PageUrlCheckedRoute
							component={CreditLineWithdrawalEmtaConsentPage}
						/>
					),
				},
				{
					path: CreditLineWithdrawalRoutePaths.PENDING,
					element: (
						<PageUrlCheckedRoute component={CreditLineWithdrawalPendingPage} />
					),
				},
				...sharedRoutes,
			],
		},
		...sharedRoutes,
	]);
});

export default AppRouter;
