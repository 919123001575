import c from 'classnames';
import { AppFooter } from 'components/app-footer';
import { useRootContext } from 'context/root';
import type { Children } from 'models';
import { SkeletonTheme } from 'react-loading-skeleton';
import scssVars from 'styles/export.module.scss';
import { Header } from '../app-header';
import styles from './AppLayout.module.scss';

const AppLayout = ({ children }: Children) => {
	const { isTablet, applicationPrivateInfo } = useRootContext();

	const { background_url } = applicationPrivateInfo ?? {};
	const isDesktopWithBackgroundImg = !!background_url && !isTablet;

	const backgroundStyles = {
		backgroundImage: isDesktopWithBackgroundImg
			? `url(${background_url})`
			: 'unset',
	};

	return (
		<SkeletonTheme
			baseColor={scssVars.colorSecondaryWhite}
			highlightColor={scssVars.colorWhite}
		>
			<div className={styles.background} style={backgroundStyles}>
				<div className={styles.layout}>
					<div className={styles.container}>
						<Header />
						{children}
					</div>
				</div>
			</div>
			<AppFooter
				className={c({ [styles.footer]: isDesktopWithBackgroundImg })}
			/>
		</SkeletonTheme>
	);
};

export default AppLayout;
