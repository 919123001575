import type { AnyObject } from 'models/core.models';
import { AppLanguages } from './app.constants';

export const LANGUAGES_BY_ABBREVIATIONS_MAP: AnyObject = {
	'en-us': AppLanguages.en,
	'et-ee': AppLanguages.et,
	'lv-lv': AppLanguages.lv,
	'lt-lt': AppLanguages.lt,
	'ru-ru': AppLanguages.ru,
};

export const ABBREVIATIONS_BY_LANGUAGES_MAP: AnyObject = {
	[AppLanguages.en]: 'en-us',
	[AppLanguages.et]: 'et-ee',
	[AppLanguages.lv]: 'lv-lv',
	[AppLanguages.lt]: 'lt-lt',
	[AppLanguages.ru]: 'ru-ru',
};
