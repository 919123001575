import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const EyeOpenedPasswordIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M14.7 12C14.7 13.4911 13.4912 14.7 12 14.7C10.5088 14.7 9.3 13.4911 9.3 12C9.3 10.5088 10.5088 9.29995 12 9.29995C13.4912 9.29995 14.7 10.5088 14.7 12Z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M3 12C4.44012 8.31233 7.80243 5.69995 12 5.69995C16.1976 5.69995 19.5599 8.31233 21 12C19.5599 15.6876 16.1976 18.3 12 18.3C7.80243 18.3 4.44012 15.6876 3 12Z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</svg>
		);
	},
);
