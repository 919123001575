import { useEffect, useState } from 'react';

export const useCheckMaxResolution = (maxResolution: number) => {
	const [isLessThanMaxResolution, setIsLessThanMaxResolution] = useState(
		window.innerWidth <= maxResolution,
	);

	const handleResize = () => {
		if (window.innerWidth <= maxResolution) {
			setIsLessThanMaxResolution(true);
		} else {
			setIsLessThanMaxResolution(false);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	});

	return isLessThanMaxResolution;
};
