import { ApplicationScheduleType } from 'api/core/generated';
import scssVars from 'styles/export.module.scss';

export enum AppEnvironments {
	development = 'development',
	production = 'production',
}

export enum AppApiVersions {
	core = 'core',
	purchaseFlow = 'purchase-flow',
}

export enum AppLanguages {
	et = 'et',
	en = 'en',
	lt = 'lt',
	lv = 'lv',
	ru = 'ru',
}

export enum AppRegions {
	et = 'EE',
	lv = 'LV',
	lt = 'LT',
}

export enum AppProductType {
	HIRE_PURCHASE = 'hire-purchase',
	SMALL_LOAN = 'small-loan',
	CREDIT_LINE = 'credit-line',
	CREDIT_LIMIT_RECALCULATION = 'credit-limit-recalculation',
	CREDIT_LINE_WITHDRAWAL = 'credit-line-withdrawal',
}

export const SMALL_LOAN_SCHEDULE_TYPES = [
	ApplicationScheduleType.SMALL_LOAN,
	ApplicationScheduleType.FAST_LOAN,
	ApplicationScheduleType.RENOVATION_LOAN,
	ApplicationScheduleType.VEHICLE_LOAN,
];

export const APPLICATION_DATA_REQUIRED_PRODUCT_TYPES = [
	AppProductType.HIRE_PURCHASE,
	AppProductType.SMALL_LOAN,
];

export const APPLICATION_PRODUCT_TYPES = [
	AppProductType.HIRE_PURCHASE,
	AppProductType.SMALL_LOAN,
];

export enum AppLoginMethods {
	smartId = 'smart_id',
	mobileId = 'mobile_id',
	idCard = 'id_card',
	password = 'password',
	banklink = 'banklink',
	eParakstsMobile = 'eparaksts_mobile',
	eParakstsSmartCard = 'eparaksts_smartcard',
}

export enum AppSigningMethods {
	smartId = 'smart_id',
	mobileId = 'mobile_id',
	idCard = 'id_card',
	password = 'password',
	banklink = 'banklink',
	eParakstsMobile = 'eparaksts_mobile',
	eParakstsSmartCard = 'eparaksts_smartcard',
}

export enum AppSearchParams {
	referenceKey = 'reference_key',
	magicLinkToken = 'token',
	eParakstsCode = 'code',
	payseraLoginPaymentStatus = 'paysera_login_payment_status',
	payseraSigningPaymentStatus = 'paysera_signing_payment_status',
	veroId = 'vero_id',
	veroConv = 'vero_conv',
	utmSource = 'utm_source',
	utmMedium = 'utm_medium',
	utmContent = 'utm_content',
	utmCampaign = 'utm_campaign',
	utmTerm = 'utm_term',
	creditAccountHash = 'hash',
	creditLimitRecalculationHash = 'hash',
	creditLineWithdrawalHash = 'hash',
	landedFrom = 'from',
	onboarding = 'onboarding',
	amount = 'amount',
	googleAnalytics = '_gl',
	emtaStatus = 'emta-status',
	language = 'lang',
	redirect = 'redirect',
	phone = 'phone',
	logout = 'logout',
	displayScheduleTypes = 'display-schedule-types',
	paymentMethodKey = 'paymentMethodKey',
	bankId = 'bankId',
	getkevinPaymentId = 'paymentId',
	systemPaymentHash = 'hash',
	sessionId = 'session_id',
	applicationPaid = 'applicationPaid',
}

export enum AppRouterParams {
	shortReferenceCode = 'short_reference_code',
}

export enum AppLoaderSizes {
	small = 'small',
	medium = 'medium',
}

export enum AppFileTypes {
	asiceFile = '.asice',
	bdocFile = '.bdoc',
	adocFile = '.adoc',
	pdfFile = '.pdf',
}

export enum AppPinTypes {
	pin1 = '1',
	pin2 = '2',
}

export enum AppTogglerTypes {
	switcher = 'switcher',
	arrow = 'arrow',
}

export const DEFAULT_DEBOUNCE_TIME = 300;

export const APP_POLLING_INTERVALS = {
	default: 5000,
	large: 10000,
};

export const STEP_COUNTER_COMPONENT_ID = 'step-counter';

export const COMPANY_NAME = 'ESTO';

// according to tablet breakpoint in variables.scss
export const TABLET_SCREEN_SIZE = 768;
// according to mobile breakpoint in variables.scss
export const MOBILE_SCREEN_SIZE = 375;

export const MODAL_CONTAINER_CLASSNAME = 'app-modal';

export const FONT_SIZE_BASE = +scssVars.fontSizeBase.split('px')[0];

export const SMALL_LOAN_START_ID = 'small_loan_start';
export const SMALL_LOAN_SUCCESS_ID = 'small_loan_success';

export const DEFAULT_DIGITS_AFTER_DECIMAL_POINT = 2;
