import { DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const CircledCheckMarkIcon = memo(
	({ color, opacity, size = DEFAULT_ICON_SIZE }: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M8 12L10.5347 14.2812C10.9662 14.6696 11.6366 14.6101 11.993 14.1519L16 9M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
						stroke={color}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</svg>
		);
	},
);
