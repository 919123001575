import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const TopRightArrowIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 14 14"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M12.6285 2.50286L13.007 12.3068L10.8283 12.2227L10.5514 5.04862L2.14314 13.4569L0.543144 11.8569L8.95139 3.44862L1.77735 3.1717L1.69325 0.993053L11.4971 1.37149C12.0988 1.39472 12.6053 1.90125 12.6285 2.50286Z"
						fill={color}
					/>
					<path
						d="M12.6285 2.50286C12.6053 1.90125 12.0988 1.39472 11.4971 1.37149L12.6568 1.34308L12.6285 2.50286Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
