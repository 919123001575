import { type MutableRefObject, useEffect } from 'react';

type useOutsideClickProps = {
	elementRef: MutableRefObject<HTMLInputElement | HTMLDivElement | null>;
	callback?: () => void;
};

export const useOutsideClick = ({
	elementRef,
	callback,
}: useOutsideClickProps) => {
	useEffect(() => {
		const handleOutsideClick = (event: Event) => {
			const { target } = event;

			if (
				elementRef?.current &&
				!elementRef?.current.contains(target as HTMLElement)
			)
				callback?.call(this);
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [elementRef, callback]);
};
