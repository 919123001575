import { ErrorBoundary } from '@sentry/react';
import { RootLogicProvider } from 'context/root/RootLogicProvider';
import type { Children } from 'models';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppApolloProvider } from './AppApolloProvider';

export const AppProviders = ({ children }: Children) => {
	return (
		<BrowserRouter>
			<ErrorBoundary>
				<AppApolloProvider>
					<RootLogicProvider>{children}</RootLogicProvider>
					<ToastContainer />
				</AppApolloProvider>
			</ErrorBoundary>
		</BrowserRouter>
	);
};
