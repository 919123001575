import { DEFAULT_ICON_OPACITY, DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const RussianFlagIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 14 14"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<mask
						height="14"
						id="mask0_469_620"
						maskUnits="userSpaceOnUse"
						style={{ maskType: 'alpha' }}
						width="14"
						x="0"
						y="0"
					>
						<circle cx="7" cy="7" fill="#D9D9D9" r="7" />
					</mask>
					<g mask="url(#mask0_469_620)">
						<rect fill="white" height="14" width="14" />
						<rect fill="#F1F0F0" height="5" width="14" />
						<rect fill="#2151AE" height="5" width="14" y="5" />
						<rect fill="#C62A30" height="5" width="14" y="10" />
					</g>
				</g>
			</svg>
		);
	},
);
