import { Navigate, useLocation } from 'react-router-dom';

type RedirectProps = {
	to: string;
};

export const Redirect = ({ to }: RedirectProps) => {
	const location = useLocation();
	const redirectUrlWithParams = `${to}${location.search}`;

	return <Navigate to={redirectUrlWithParams} />;
};
