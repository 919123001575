import c from 'classnames';
import { memo } from 'react';
import styles from './AppIcon.module.scss';
import { ICONS_MAP } from './app-icon.constants';
import type { AppIconProps } from './app-icon.types';

const AppIcon = memo(
	({
		name,
		size,
		color,
		opacity,
		className,
		onClick,
		...props
	}: AppIconProps) => {
		const iconConfig = ICONS_MAP[name as keyof typeof ICONS_MAP] || {};

		if (!iconConfig.icon) {
			return null;
		}

		const Icon = iconConfig.icon;

		return (
			// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
			<div className={c(styles.container, className)} onClick={onClick}>
				<Icon
					{...{
						name,
						size: size || iconConfig?.size,
						color: color || iconConfig?.color,
						opacity,
						...props,
					}}
				/>
			</div>
		);
	},
);

export default AppIcon;
