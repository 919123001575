import {
	AppLayout,
	AppLoader,
	AppRouter,
	AppStepCounter,
	AppTestMode,
} from 'components';
import { useRootContext } from 'context/root';
import { Suspense } from 'react';

export const App = () => {
	const { userWasFetched } = useRootContext();
	if (!userWasFetched) {
		return <AppLoader />;
	}
	return (
		<AppLayout>
			<AppStepCounter />
			<AppTestMode />
			<Suspense>
				<AppRouter />
			</Suspense>
		</AppLayout>
	);
};
