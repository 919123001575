import type { AnyObject } from 'models';

export const preventDefaults = (event: Event | DragEvent) => {
	event.preventDefault();
	event.stopPropagation();
};

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const filterObjectByKey = (object: AnyObject, key: any): AnyObject => {
	return (
		Object.keys(object)
			.filter((keyName) => keyName !== key)
			// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
			.reduce((obj, k) => ({ ...obj, [k]: object[k] }), {})
	);
};

export const filterObjectByExistingKeysInObject = (
	objectToFilter: Record<string, unknown>,
	object: Record<string, unknown>,
): Record<string, unknown> => {
	return Object.entries(objectToFilter).reduce(
		(acc, [key, value]) => {
			if (key in object) {
				acc[key] = value;
			}
			return acc;
		},
		{} as Record<string, unknown>,
	);
};
