import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const PersonIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 50 50"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<mask
						height={height}
						id="mask0_4206_6758"
						maskUnits="userSpaceOnUse"
						style={{ maskType: 'luminance' }}
						width={width}
						x="0"
						y="0"
					>
						<path d="M0 3.8147e-06H50.0001V50H0V3.8147e-06Z" fill="white" />
					</mask>
					<g mask="url(#mask0_4206_6758)">
						<path
							clipRule="evenodd"
							d="M31.3198 18.0317C31.3198 21.4624 28.5086 24.3067 25.0915 24.3027C21.2132 24.2982 18.6797 21.3283 18.6797 18.0834V12.4739C18.6797 10.5377 20.7101 9.27886 22.4806 10.1379C23.2501 10.5113 24.0978 10.7811 24.9997 10.7811C25.9017 10.7811 26.7494 10.5113 27.519 10.1378C29.2895 9.27889 31.3198 10.5377 31.3198 12.4738V18.0317ZM25.0898 25.7676C29.3301 25.7724 32.7846 22.2585 32.7846 18.0317V12.4738C32.7846 9.4248 29.5944 7.50278 26.8795 8.81994L26.8795 8.81998C26.2332 9.13359 25.6089 9.31621 24.9997 9.31621C24.3906 9.31621 23.7662 9.13359 23.12 8.82006L23.12 8.82005C20.4052 7.50277 17.2148 9.4248 17.2148 12.4739V18.0834C17.2148 22.0996 20.3665 25.7621 25.0898 25.7676Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M32.7851 17.7572V13.6601C32.8534 13.6532 32.9228 13.6497 32.9931 13.6497C34.1302 13.6497 35.052 14.5715 35.052 15.7086C35.052 16.8457 34.1301 17.7676 32.9931 17.7676C32.9228 17.7676 32.8534 17.7641 32.7851 17.7572ZM31.7963 19.0235C32.1704 19.1585 32.5736 19.2324 32.9931 19.2324C34.9392 19.2324 36.5168 17.6547 36.5168 15.7086C36.5168 13.7625 34.9392 12.1849 32.9931 12.1849C32.577 12.1849 32.1771 12.2577 31.8058 12.3906C31.5146 12.4948 31.3202 12.7708 31.3202 13.0802V18.0315C31.3202 18.1175 31.3173 18.2061 31.3132 18.3033C31.2995 18.6232 31.4952 18.9148 31.7963 19.0235Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M17.0081 17.7676C15.871 17.7676 14.9492 16.8457 14.9492 15.7086C14.9492 14.5714 15.8709 13.6497 17.0081 13.6497C17.0784 13.6497 17.1478 13.6532 17.2161 13.6601V17.7572C17.1478 17.7641 17.0784 17.7676 17.0081 17.7676ZM13.4844 15.7086C13.4844 17.6547 15.0619 19.2324 17.0081 19.2324C17.4268 19.2324 17.8295 19.1587 18.2032 19.0239C18.5027 18.9159 18.6981 18.6267 18.6866 18.3085L18.6866 18.3076C18.6835 18.2212 18.681 18.1515 18.681 18.0832V13.0802C18.681 12.7709 18.4867 12.495 18.1956 12.3907C17.824 12.2576 17.424 12.1849 17.0081 12.1849C15.0619 12.1849 13.4844 13.7624 13.4844 15.7086Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M15.1104 14.0359C15.3491 14.1632 15.6384 14.1486 15.8631 13.9979C16.1904 13.7784 16.5833 13.6503 17.0081 13.6503C17.2522 13.6503 17.4853 13.6929 17.7017 13.7703C17.9261 13.8506 18.1754 13.8168 18.3702 13.6796C18.5651 13.5424 18.681 13.319 18.681 13.0808V12.4743C18.681 10.5381 20.7113 9.27926 22.4818 10.1383C23.2513 10.5117 24.099 10.7814 25.001 10.7814C25.9029 10.7814 26.7506 10.5117 27.5202 10.1383C29.2908 9.27924 31.321 10.5381 31.321 12.4742V13.0808C31.321 13.319 31.4369 13.5424 31.6317 13.6796C31.8266 13.8168 32.0759 13.8506 32.3003 13.7703C32.5167 13.6929 32.7498 13.6503 32.9939 13.6503C33.4188 13.6503 33.8116 13.7784 34.1389 13.9979C34.3635 14.1486 34.6529 14.1632 34.8916 14.0359C35.1303 13.9086 35.2793 13.6601 35.2793 13.3896V10.2791C35.2793 4.6244 30.6558 0.000780106 25.001 0.000780106C19.3462 0.000780106 14.7227 4.6244 14.7227 10.2791V13.3896C14.7227 13.6601 14.8717 13.9086 15.1104 14.0359ZM16.1875 12.2817V10.2791C16.1875 5.4334 20.1552 1.46562 25.001 1.46562C29.8468 1.46562 33.8145 5.4334 33.8145 10.2791V12.2817C33.551 12.2188 33.2762 12.1854 32.9939 12.1854C32.921 12.1854 32.8485 12.1877 32.7767 12.1921C32.5872 9.30838 29.5109 7.54428 26.8808 8.82034L26.8807 8.82037C26.2345 9.13399 25.6102 9.3166 25.001 9.3166C24.3918 9.3166 23.7675 9.13398 23.1213 8.82045L23.1213 8.82045C20.4912 7.54429 17.4149 9.30834 17.2253 12.1921C17.1535 12.1877 17.081 12.1854 17.0081 12.1854C16.7258 12.1854 16.451 12.2188 16.1875 12.2817Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M37.1309 49.8047C37.5354 49.8047 37.8633 49.4768 37.8633 49.0723V35.8556C37.8633 35.4511 37.5354 35.1231 37.1309 35.1231C36.7264 35.1231 36.3984 35.4511 36.3984 35.8556V49.0723C36.3984 49.4768 36.7264 49.8047 37.1309 49.8047Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M12.8691 49.8047C13.2736 49.8047 13.6016 49.4768 13.6016 49.0723V35.8556C13.6016 35.4511 13.2736 35.1231 12.8691 35.1231C12.4646 35.1231 12.1367 35.4511 12.1367 35.8556V49.0723C12.1367 49.4768 12.4646 49.8047 12.8691 49.8047Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M21.7856 30.8893L18.7509 28.3727L20.854 27.5513L22.896 29.2447L21.7856 30.8893ZM22.9162 29.2149C22.9162 29.2149 22.9162 29.2149 22.9162 29.2149L22.9162 29.2149ZM22.9751 31.7452C22.5038 32.4434 21.5327 32.5827 20.8842 32.0447C20.8841 32.0447 20.8841 32.0447 20.884 32.0446L16.8587 28.7065C16.659 28.541 16.5618 28.2821 16.6031 28.0261C16.6444 27.77 16.8181 27.5549 17.0598 27.4605L20.4172 26.1494C20.6827 25.8833 21.1101 25.8607 21.4027 26.1034L23.8587 28.14C23.8587 28.1401 23.8587 28.1401 23.8588 28.1401C24.4229 28.6079 24.5402 29.4274 24.1302 30.0347C24.1302 30.0347 24.1301 30.0347 24.1301 30.0347L22.9751 31.7452Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M27.5894 30.9547L30.6241 28.4381L28.521 27.6168L26.479 29.3102L27.5894 30.9547ZM26.4588 29.2803C26.4588 29.2803 26.4588 29.2803 26.4588 29.2804L26.4588 29.2803ZM26.3999 31.8107C26.8712 32.5088 27.8423 32.6481 28.4908 32.1102C28.4909 32.1101 28.4909 32.1101 28.491 32.1101L32.5163 28.772C32.716 28.6064 32.8132 28.3476 32.7719 28.0915C32.7306 27.8354 32.5569 27.6203 32.3152 27.5259L28.9578 26.2148C28.6923 25.9488 28.2649 25.9261 27.9723 26.1688L25.5163 28.2055C25.5163 28.2055 25.5163 28.2055 25.5162 28.2055C24.9521 28.6733 24.8348 29.4929 25.2448 30.1001C25.2448 30.1001 25.2449 30.1001 25.2449 30.1001L26.3999 31.8107Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M22.7449 30.1641C22.872 30.4032 23.1207 30.5527 23.3916 30.5527H26.6102C26.881 30.5527 27.1298 30.4032 27.2569 30.1641C27.384 29.9249 27.3688 29.6351 27.2172 29.4105L27.1052 29.2446L29.5166 27.2449C29.7876 27.0322 29.8756 26.655 29.7189 26.3417L29.7188 26.3415C29.6944 26.2928 29.665 26.2474 29.6312 26.206L29.6292 23.9029C29.629 23.634 29.4814 23.3869 29.2449 23.2591C29.0083 23.1313 28.7207 23.1434 28.4957 23.2907C27.5184 23.9303 26.3535 24.303 25.1015 24.303C23.6972 24.303 22.481 23.9148 21.4966 23.2781C21.2713 23.1325 20.9844 23.1216 20.7487 23.2498C20.5131 23.3781 20.3664 23.6249 20.3664 23.8932V26.2062C20.127 26.5013 20.1527 26.9341 20.4315 27.1985C20.4474 27.2136 20.4638 27.2279 20.4806 27.2412L22.8966 29.2446L22.7845 29.4105C22.633 29.6351 22.6177 29.9249 22.7449 30.1641ZM21.8145 26.4444C21.8255 26.3714 21.8313 26.2953 21.8313 26.2162V25.1114C22.8069 25.532 23.9064 25.7679 25.1015 25.7679C26.19 25.7679 27.2264 25.5394 28.1654 25.129L28.1663 26.2211L28.1663 26.2226C28.1666 26.2988 28.1728 26.3735 28.1847 26.4465L26.1425 28.1399C25.846 28.3859 25.6727 28.7293 25.6349 29.0879H24.3669C24.329 28.7293 24.1559 28.386 23.8593 28.14L23.8592 28.1399L21.8145 26.4444Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M8.26055 48.5352C7.96353 48.5352 7.72266 48.2944 7.72266 47.9972V36.6036C7.72266 33.8306 9.6625 31.9546 11.0755 31.3701L21.065 27.4687C21.116 27.4488 21.1646 27.4232 21.2098 27.3925C21.5256 27.1782 21.8313 26.7996 21.8313 26.2158V25.1111C22.8069 25.5316 23.9064 25.7675 25.1015 25.7675C26.1901 25.7675 27.2264 25.539 28.1654 25.1287L28.1664 26.2207L28.1664 26.2221C28.1679 26.8155 28.5338 27.3455 29.0847 27.5587C29.274 27.632 30.8705 28.2503 32.7263 28.969C35.3957 30.0028 38.6016 31.2444 38.9273 31.3705C40.7353 32.1183 42.2793 34.2062 42.2793 36.6036V47.9972C42.2793 48.2942 42.0384 48.5352 41.7412 48.5352H33.4435C33.039 48.5352 32.7111 48.8631 32.7111 49.2676C32.7111 49.6721 33.039 50 33.4435 50H41.7412C42.8473 50 43.7441 49.1034 43.7441 47.9972V36.6036C43.7441 33.6097 41.8381 30.9844 39.478 30.0131C39.4733 30.0112 39.4686 30.0093 39.4638 30.0075C39.1849 29.8994 35.9869 28.6609 33.3058 27.6225C31.4764 26.9141 29.8877 26.2988 29.6312 26.1994L29.6292 23.9026C29.629 23.6337 29.4815 23.3866 29.2449 23.2588C29.0083 23.131 28.7208 23.1431 28.4958 23.2904C27.5184 23.9299 26.3536 24.3026 25.1015 24.3026C23.6972 24.3026 22.481 23.9145 21.4966 23.2778C21.2714 23.1321 20.9844 23.1212 20.7488 23.2494C20.5131 23.3777 20.3664 23.6245 20.3664 23.8928V26.1689L10.5361 30.0082C10.532 30.0098 10.5279 30.0114 10.5238 30.0131C8.67749 30.773 6.25781 33.1168 6.25781 36.6036V47.9972C6.25781 49.1033 7.15445 50 8.26055 50H30.0255C30.43 50 30.758 49.6721 30.758 49.2676C30.758 48.8631 30.43 48.5352 30.0255 48.5352H8.26055Z"
							fill={color}
							fillRule="evenodd"
						/>
						<path
							clipRule="evenodd"
							d="M22.9297 49.2676C22.9297 49.6721 23.2576 50 23.6621 50H26.3369C26.7414 50 27.0693 49.6721 27.0693 49.2676V40.8206C27.0693 40.4161 26.7414 40.0882 26.3369 40.0882C25.9324 40.0882 25.6045 40.4161 25.6045 40.8206V48.5352H24.3945V30.5524H25.6045V37.4026C25.6045 37.8071 25.9324 38.1351 26.3369 38.1351C26.7414 38.1351 27.0693 37.8071 27.0693 37.4026V29.82C27.0693 29.4155 26.7414 29.0876 26.3369 29.0876H23.6621C23.2576 29.0876 22.9297 29.4155 22.9297 29.82V49.2676Z"
							fill={color}
							fillRule="evenodd"
						/>
					</g>
				</g>
			</svg>
		);
	},
);
