import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const WalletIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		opacity = DEFAULT_ICON_OPACITY,
		color = DEFAULT_ICON_COLOR,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M22.5938 12.937H22.125V9.65561C22.125 8.36199 21.2167 7.27726 20.0045 7.00387L17.871 3.30837C17.5583 2.7668 17.0535 2.37936 16.4494 2.21749C16.0229 2.10325 15.5831 2.11164 15.1707 2.23615L13.6193 0.684662C12.7064 -0.228174 11.2212 -0.228268 10.3083 0.684662L4.05661 6.93674H2.71875C1.21964 6.93674 0 8.15644 0 9.65561V21.2811C0 22.7803 1.21964 24 2.71875 24H8.95312C9.21197 24 9.42188 23.7901 9.42188 23.5312C9.42188 23.2723 9.21197 23.0624 8.95312 23.0624H2.71875C1.73658 23.0624 0.9375 22.2633 0.9375 21.2811V9.65561C0.9375 8.6734 1.73658 7.87428 2.71875 7.87428H19.4062C20.3884 7.87428 21.1875 8.6734 21.1875 9.65561V12.937H17.7188C16.323 12.937 15.1875 14.0726 15.1875 15.4684C15.1875 16.8641 16.323 17.9997 17.7188 17.9997H21.1875V21.2811C21.1875 22.2633 20.3884 23.0624 19.4062 23.0624H13.1719C12.913 23.0624 12.7031 23.2723 12.7031 23.5312C12.7031 23.7901 12.913 24 13.1719 24H19.4062C20.9054 24 22.125 22.7803 22.125 21.2811V17.9997H22.5938C23.3692 17.9997 24 17.3688 24 16.5934V14.3433C24 13.5679 23.3692 12.937 22.5938 12.937ZM16.2067 3.12311C16.5689 3.22015 16.8716 3.45242 17.0591 3.77714L18.8832 6.93674H8.77936L15.1416 3.26332C15.4663 3.07591 15.8445 3.02603 16.2067 3.12311ZM10.9713 1.34764C11.2364 1.08251 11.5889 0.936485 11.9638 0.936485C12.3388 0.936485 12.6913 1.08251 12.9564 1.34764L14.2844 2.67572L6.90441 6.93674H5.38247L10.9713 1.34764ZM23.0625 16.5934C23.0625 16.8519 22.8522 17.0622 22.5938 17.0622H17.7188C16.8399 17.0622 16.125 16.3472 16.125 15.4684C16.125 14.5895 16.8399 13.8745 17.7188 13.8745H22.5938C22.8522 13.8745 23.0625 14.0848 23.0625 14.3433V16.5934Z"
						fill={color}
					/>
					<path
						d="M17.6406 14.9995C17.5173 14.9995 17.3964 15.0497 17.3092 15.1369C17.222 15.2241 17.1719 15.3446 17.1719 15.4683C17.1719 15.5916 17.222 15.7125 17.3092 15.7997C17.3964 15.8869 17.5173 15.9371 17.6406 15.9371C17.7644 15.9371 17.8848 15.8869 17.972 15.7997C18.0596 15.7125 18.1094 15.5916 18.1094 15.4683C18.1094 15.345 18.0596 15.2241 17.972 15.1369C17.8848 15.0497 17.7644 14.9995 17.6406 14.9995Z"
						fill={color}
					/>
					<path
						d="M11.0625 23.0625C10.9392 23.0625 10.8183 23.1126 10.7311 23.1998C10.6439 23.287 10.5938 23.4079 10.5938 23.5312C10.5938 23.6545 10.6439 23.7755 10.7311 23.8626C10.8183 23.9498 10.9392 24 11.0625 24C11.1858 24 11.3067 23.9498 11.3939 23.8626C11.481 23.7755 11.5312 23.6545 11.5312 23.5312C11.5312 23.4079 11.4811 23.287 11.3939 23.1998C11.3067 23.1126 11.1858 23.0625 11.0625 23.0625Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
