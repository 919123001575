import {
	type PageUrlQueryVariables,
	usePageUrlLazyQuery,
} from 'api/purchase-flow/generated';
import {
	AppApiVersions,
	LOGIN_PAGE_PATH,
	LocalStorageKeys,
} from 'app-constants';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFromStorage, removeFromStorage, setToStorage } from 'services';

type PageUrlNavigateOptions = {
	customCurrentPageUrl?: string;
};

export const useGetPageUrl = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [pageUrlAndNavigationProcessing, setPageUrlAndNavigationProcessing] =
		useState(false);

	const url = `${location.pathname}${location.search}`;
	const currentPageIsLogin = url.includes(`/${LOGIN_PAGE_PATH}`);

	const [requestPageUrl, { data, loading, error }] = usePageUrlLazyQuery({
		context: { apiVersion: AppApiVersions.purchaseFlow },
		fetchPolicy: 'network-only',
	});

	const getPageUrl = (variables: PageUrlQueryVariables) =>
		requestPageUrl({ variables });

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const getPageUrlAndNavigateByDefault = useCallback(
		(next: boolean | null = null, options?: PageUrlNavigateOptions) => {
			setPageUrlAndNavigationProcessing(true);

			const currentPageUrl = options?.customCurrentPageUrl || url;

			return getPageUrl({ url: currentPageUrl, next })
				.then(({ data: responseData }) => {
					// HANDLE EXTERNAL REDIRECT
					const externalUrl = responseData?.page_url?.external_url;
					if (externalUrl) {
						window.location.href = externalUrl;
						return;
					}
					// HANDLE INTERNAL REDIRECT
					const nextPageUrl = responseData?.page_url?.url;
					const nextPageIsLogin =
						responseData?.page_url?.url.includes(LOGIN_PAGE_PATH);
					const shouldSaveCurrentQueryVariables =
						nextPageIsLogin && !(currentPageIsLogin && next === null);

					if (shouldSaveCurrentQueryVariables)
						setToStorage(LocalStorageKeys.pageUrlQueryVariables, {
							url: currentPageUrl,
							next,
						});
					if (nextPageUrl && nextPageUrl !== url) {
						navigate(nextPageUrl);
					}
					setPageUrlAndNavigationProcessing(false);
				})
				.catch(() => {
					setPageUrlAndNavigationProcessing(false);
				});
		},
		[location],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const getPageUrlAndNavigate = useCallback(
		(next: boolean | null = null, options?: PageUrlNavigateOptions) => {
			const storedPageUrlQueryVariables = getFromStorage(
				LocalStorageKeys.pageUrlQueryVariables,
			);

			if (currentPageIsLogin && storedPageUrlQueryVariables && next === false) {
				removeFromStorage(LocalStorageKeys.pageUrlQueryVariables);
				return getPageUrlAndNavigateByDefault(next, options);
			}

			if (currentPageIsLogin && storedPageUrlQueryVariables && next !== null) {
				setPageUrlAndNavigationProcessing(true);

				if (options?.customCurrentPageUrl) {
					storedPageUrlQueryVariables.url = options.customCurrentPageUrl;
				}

				return getPageUrl(storedPageUrlQueryVariables)
					.then(({ data: responseData }) => {
						// HANDLE EXTERNAL REDIRECT
						const externalUrl = responseData?.page_url?.external_url;
						if (externalUrl) {
							window.location.href = externalUrl;
							return;
						}
						// HANDLE INTERNAL REDIRECT
						const nextPageUrl = responseData?.page_url?.url;
						removeFromStorage(LocalStorageKeys.pageUrlQueryVariables);
						if (nextPageUrl) navigate(nextPageUrl);
						setPageUrlAndNavigationProcessing(false);
					})
					.catch(() => {
						setPageUrlAndNavigationProcessing(false);
					});
			}
			return getPageUrlAndNavigateByDefault(next, options);
		},

		[location],
	);

	return {
		getPageUrl,
		getPageUrlAndNavigate,
		pageUrl: data?.page_url?.url,
		pageUrlLoading: loading,
		pageUrlAndNavigationProcessing,
		pageUrlError: error,
	};
};
