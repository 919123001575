import { ApplicationScheduleType } from 'api/core/generated';

export enum ConsumerLoanProduct {
	FAST_LOAN = ApplicationScheduleType.FAST_LOAN,
	RENOVATION_LOAN = ApplicationScheduleType.RENOVATION_LOAN,
	SMALL_LOAN = ApplicationScheduleType.SMALL_LOAN,
	VEHICLE_LOAN = ApplicationScheduleType.VEHICLE_LOAN,
}

export enum HirePurchaseProduct {
	ESTO_PAY = ApplicationScheduleType.ESTO_PAY,
	ESTO_X = ApplicationScheduleType.ESTO_X,
	PAY_LATER = ApplicationScheduleType.PAY_LATER,
	REGULAR = ApplicationScheduleType.REGULAR,
}

export enum NonLoanProduct {
	CREDIT_LINE = 'CREDIT_LINE',
	PREMIUM_SUBSCRIPTION = 'PREMIUM_SUBSCRIPTION',
}

export type LoanProduct = ConsumerLoanProduct | HirePurchaseProduct;

export type EstoProductType = NonLoanProduct.CREDIT_LINE | ConsumerLoanProduct;

export type ProductType =
	| NonLoanProduct
	| ConsumerLoanProduct
	| HirePurchaseProduct;
