import { captureException } from '@sentry/react';
import type { MeUserFragment } from 'api/core/generated';
import { LocalStorageBooleanValue, LocalStorageKeys } from 'app-constants';
import {
	coreApiEndpoint,
	isDevelopment,
	purchaseFlowApiEndpoint,
	smartlookProjectId,
} from 'environment';
import { useCallback, useState } from 'react';
import smartlookClient from 'smartlook-client';
import { useLocalStorage } from './utils';

type SmartlookHookMethods = {
	initializeSmartlook: (hashIdentifier: Nullable<string>) => void;
	identifyInSmartlook: (
		hashIdentifier: Nullable<string>,
		user: Nullable<MeUserFragment>,
	) => void;
};

export const useSmartlook = (): SmartlookHookMethods => {
	const [isAnonymousUserIdentified, setIsAnonymousUserIdentified] =
		useState(false);
	const [isUserIdentified, setIsUserIdentified] = useState(false);
	const [isRedirectedFromThirdPartyApi, setIsRedirectedFromThirdPartyApi] =
		useLocalStorage<LocalStorageBooleanValue>(
			LocalStorageKeys.isRedirectedFromThirdPartyApi,
			LocalStorageBooleanValue.false,
		);
	const [smartlookIdentifier, setSmartlookIdentifier] = useLocalStorage<
		Nullable<string>
	>(LocalStorageKeys.smartlookIdentifier, null);

	const initializeSmartlook = (hashIdentifier: Nullable<string>): void => {
		if (isDevelopment) return;

		if (smartlookProjectId && !smartlookClient.initialized()) {
			smartlookClient.init(smartlookProjectId, {
				advancedNetwork: {
					allowedUrls: [coreApiEndpoint, purchaseFlowApiEndpoint],
				},
			});

			// This is needed when user restarts app with the same application/flow
			if (smartlookIdentifier && smartlookIdentifier !== hashIdentifier) {
				setSmartlookIdentifier(hashIdentifier);
				smartlookClient.anonymize();
			}

			// this is needed for Credit Line unauthorized flow as we do not have hash identifier to identify user
			if (
				!smartlookIdentifier &&
				isRedirectedFromThirdPartyApi === LocalStorageBooleanValue.false
			) {
				smartlookClient.anonymize();
			}
			//Ensure reset of the flag after redirect case above
			setIsRedirectedFromThirdPartyApi(LocalStorageBooleanValue.false);

			smartlookClient.record({
				forms: true,
				emails: true,
				numbers: true,
				api: true,
				ips: true,
			});
		} else if (!smartlookProjectId) {
			captureException(
				'Smartlook is not initialized because project id is not provided',
				{
					tags: { source: 'handleUnknownError' },
				},
			);
		}
	};

	const identifyInSmartlook = useCallback(
		(hashIdentifier: Nullable<string>, user: Nullable<MeUserFragment>) => {
			if (!smartlookClient.initialized() || !hashIdentifier) {
				return;
			}

			// Ensure that we store hash once it is available
			setSmartlookIdentifier(hashIdentifier);

			if (user && !isUserIdentified) {
				smartlookClient.identify(hashIdentifier, {
					hash: hashIdentifier,
					userId: user.id || 'No User ID',
					email: user.email || 'No User Email',
					name: !(!user.profile?.first_name && !user.profile?.last_name)
						? `${user.profile?.first_name ?? ''} ${
								user.profile?.last_name ?? ''
							}`.trimStart()
						: 'No User Name',
				});
				setIsUserIdentified(true);
				return;
			}

			if (!user && !isAnonymousUserIdentified) {
				smartlookClient.identify(hashIdentifier, {
					hash: hashIdentifier,
				});
				setIsAnonymousUserIdentified(true);
			}
		},
		[isUserIdentified, isAnonymousUserIdentified, setSmartlookIdentifier],
	);

	return {
		initializeSmartlook,
		identifyInSmartlook,
	};
};
