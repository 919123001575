import { AppProductType, AppSearchParams } from './app.constants';

export enum LoginPageViewTypes {
	login = 'login',
	magic = 'magic',
	pending = 'pending',
	pinConfirmation = 'pin-confirmation',
}

export const EPARAKSTS_BANKLINK_LOGIN_PARAMS_BY_PRODUCT_TYPE_MAP = {
	[AppProductType.HIRE_PURCHASE]: {
		[AppSearchParams.referenceKey]: true,
	},
	[AppProductType.SMALL_LOAN]: {
		[AppSearchParams.referenceKey]: true,
	},
	[AppProductType.CREDIT_LINE]: {
		[AppSearchParams.creditAccountHash]: true,
	},
	[AppProductType.CREDIT_LIMIT_RECALCULATION]: {
		[AppSearchParams.creditLimitRecalculationHash]: true,
	},
	[AppProductType.CREDIT_LINE_WITHDRAWAL]: {
		[AppSearchParams.creditLineWithdrawalHash]: true,
	},
};
