import { region } from 'environment';
import { AppRegions } from 'models/app.models';
import { AppFileTypes } from './app.constants';
import { LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS } from './locize.constants';

export enum IncomeSourcesCheckServices {
	emta = 'emta',
	accountScoring = 'account-scoring',
}

export enum AccountScoringSteps {
	talkingToBank = 1,
	analyzingBankStatement = 2,
	makingDecision = 3,
	finalizing = 4,
}

export const ACCOUNT_SCORING_TRANSLATION_KEYS_BY_STEPS_MAP = {
	[AccountScoringSteps.talkingToBank]:
		LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS.talkingToBank,
	[AccountScoringSteps.analyzingBankStatement]:
		LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS.analysingBankStatement,
	[AccountScoringSteps.makingDecision]:
		LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS.makingDecision,
	[AccountScoringSteps.finalizing]:
		LOCIZE_ACCOUNT_SCORING_TRANSLATION_KEYS.finalising,
};

export enum AccountScoringStepState {
	initial = 'initial',
	processing = 'processing',
	finished = 'finished',
}

export const DEFAULT_ACCOUNT_SCORING_STEPS = {
	[AccountScoringSteps.talkingToBank]: AccountScoringStepState.processing,
	[AccountScoringSteps.analyzingBankStatement]: AccountScoringStepState.initial,
	[AccountScoringSteps.makingDecision]: AccountScoringStepState.initial,
	[AccountScoringSteps.finalizing]: AccountScoringStepState.initial,
};

export const DEFAULT_ACCOUNT_SCORING_TIMEOUT = 30000;

export const DEFAULT_ACCOUNT_SCORING_STEPS_TIMEOUTS = {
	[AccountScoringSteps.talkingToBank]: 0,
	[AccountScoringSteps.analyzingBankStatement]: DEFAULT_ACCOUNT_SCORING_TIMEOUT,
	[AccountScoringSteps.makingDecision]: DEFAULT_ACCOUNT_SCORING_TIMEOUT * 5,
	[AccountScoringSteps.finalizing]: DEFAULT_ACCOUNT_SCORING_TIMEOUT * 10,
};

export const ACCOUNT_SCORING_BANK_STATEMENT_ACCEPT_FILE_TYPES_BY_REGION = {
	[AppRegions.EE]: [AppFileTypes.asiceFile, AppFileTypes.bdocFile],
	[AppRegions.LV]: [
		AppFileTypes.asiceFile,
		AppFileTypes.bdocFile,
		AppFileTypes.adocFile,
		AppFileTypes.pdfFile,
	],
	[AppRegions.LT]: [
		AppFileTypes.asiceFile,
		AppFileTypes.bdocFile,
		AppFileTypes.pdfFile,
	],
};

export const ACCOUNT_SCORING_BANK_STATEMENT_ACCEPT_FILE_TYPES =
	ACCOUNT_SCORING_BANK_STATEMENT_ACCEPT_FILE_TYPES_BY_REGION[
		region as AppRegions
	];

export enum AccountScoringViewTypes {
	initial = 'initial',
	stepper = 'stepper',
}

export enum AccountScoringStatuses {
	generated = 'generated',
	viewed = 'viewed',
	inProgress = 'in_progress',
	completed = 'completed',
	outdated = 'outdated',
}
