import type { Application, CreditSettingCalculator } from 'api/core/generated';
import { AppProductType } from 'app-constants';

export const getApplicationDefaultDownPayment = (
	application: Application | null,
) => {
	const downPayment = application?.credit_info?.down_payment ?? 0;
	const minDownPayment = application?.credit_info?.min_down_payment ?? 0;
	return downPayment > minDownPayment ? downPayment : minDownPayment;
};

export const getDownPayment = (
	downPaymentAmount: number | string | null = 0,
): number => {
	const amountOfDownPayment = downPaymentAmount ?? 0;
	return +amountOfDownPayment;
};

export const getCurrentCreditSetting = (
	creditSettings: Array<CreditSettingCalculator> | null,
	periodMonths: number | string = 1,
): CreditSettingCalculator | undefined =>
	creditSettings?.find(({ month }) => month === +periodMonths);

export const getProductTypeInfo = (productType: AppProductType) => ({
	isHirePurchase: productType === AppProductType.HIRE_PURCHASE,
	isSmallLoan: productType === AppProductType.SMALL_LOAN,
	isCreditLine: productType === AppProductType.CREDIT_LINE,
	isCreditLineWithdrawal: productType === AppProductType.CREDIT_LINE_WITHDRAWAL,
	isCreditLimitRecalculation:
		productType === AppProductType.CREDIT_LIMIT_RECALCULATION,
});
