import { ApplicationScheduleType } from 'api/core/generated';
import { AppEnvironments, AppLanguages } from 'app-constants/app.constants';
import type { AvailableLanguage } from 'models/app.models';

const TEST_ENVIRONMENT_NAME = 'dekker';

const {
	REACT_APP_CORE_API_URL,
	REACT_APP_CORE_API_ENDPOINT,
	REACT_APP_PURCHASE_FLOW_API_ENDPOINT,
	REACT_APP_ENVIRONMENT,
	REACT_APP_SENTRY_DSN,
	REACT_APP_LANGUAGES,
	REACT_APP_LOCIZE_PROJECT_ID,
	REACT_APP_CUSTOMER_PROFILE_URL,
	REACT_APP_NEW_CUSTOMER_PROFILE_URL,
	REACT_APP_FAQ_URL_EN,
	REACT_APP_FAQ_URL_ET,
	REACT_APP_FAQ_URL_RU,
	REACT_APP_FAQ_URL_LV,
	REACT_APP_FAQ_URL_LT,
	REACT_APP_CL_FAQ_URL_EN,
	REACT_APP_CL_FAQ_URL_ET,
	REACT_APP_CL_FAQ_URL_RU,
	REACT_APP_CL_FAQ_URL_LV,
	REACT_APP_CL_FAQ_URL_LT,
	REACT_APP_HOMEPAGE_CREDIT_LINE_URL_EN,
	REACT_APP_HOMEPAGE_CREDIT_LINE_URL_ET,
	REACT_APP_HOMEPAGE_CREDIT_LINE_URL_RU,
	REACT_APP_HOMEPAGE_CREDIT_LINE_URL_LV,
	REACT_APP_HOMEPAGE_CREDIT_LINE_URL_LT,
	NODE_ENV,
	REACT_APP_CONTACT_NAME,
	REACT_APP_CONTACT_PHONE,
	REACT_APP_CONTACT_EMAIL,
	REACT_APP_CONTACT_ADDRESS,
	REACT_APP_WEB_URL,
	REACT_APP_SMARTLOOK_PROJECT_ID,
	REACT_APP_ZENDESK_CHAT_KEY_CODE,
	REACT_APP_REGION,
	REACT_APP_SIFT_BEACON_KEY,
	REACT_APP_GOOGLE_ANALYTICS_ID,
	REACT_APP_PHONE_PREFIX,
	REACT_APP_REGION_LANGUAGE,
	REACT_APP_TERMS_URL_EN,
	REACT_APP_TERMS_URL_ET,
	REACT_APP_TERMS_URL_RU,
	REACT_APP_TERMS_URL_LV,
	REACT_APP_TERMS_URL_LT,
	REACT_APP_RUDDERSTACK_PLANE_URL,
	REACT_APP_RUDDERSTACK_API_KEY,
	REACT_APP_ID_CARD_LOGIN_ENDPOINT,
	REACT_APP_CREDY_TERMS_URL_LT,
	REACT_APP_SMALL_LOAN_INTEREST_RATE,
	REACT_APP_FAST_LOAN_INTEREST_RATE,
	REACT_APP_RENOVATION_LOAN_INTEREST_RATE,
	REACT_APP_VEHICLE_LOAN_INTEREST_RATE,
	REACT_APP_ONLY_PASSWORD_SIGNING_ENABLED,
	REACT_APP_CP_CA_ONBOARDING,
	REACT_APP_PRIVACY_POLICY_URL,
} = process.env;

export const coreApiUrl = REACT_APP_CORE_API_URL ?? '';
export const coreApiEndpoint = REACT_APP_CORE_API_ENDPOINT ?? '';
export const purchaseFlowApiEndpoint =
	REACT_APP_PURCHASE_FLOW_API_ENDPOINT ?? '';
export const environment = REACT_APP_ENVIRONMENT ?? '';
export const isDekker = environment.includes(TEST_ENVIRONMENT_NAME);
export const isDevelopment = NODE_ENV === AppEnvironments.development;
export const sentryDsn = REACT_APP_SENTRY_DSN ?? '';
export const availableLanguages =
	(REACT_APP_LANGUAGES?.split(',') as Array<AvailableLanguage>) ?? [];
export const locizeProjectId = REACT_APP_LOCIZE_PROJECT_ID ?? '';
export const customerProfileUrl = REACT_APP_CUSTOMER_PROFILE_URL ?? '';
export const newCustomerProfileUrl = REACT_APP_NEW_CUSTOMER_PROFILE_URL ?? '';
export const FAQ_URLS = {
	[AppLanguages.en]: REACT_APP_FAQ_URL_EN ?? '',
	[AppLanguages.et]: REACT_APP_FAQ_URL_ET ?? '',
	[AppLanguages.ru]: REACT_APP_FAQ_URL_RU ?? '',
	[AppLanguages.lv]: REACT_APP_FAQ_URL_LV ?? '',
	[AppLanguages.lt]: REACT_APP_FAQ_URL_LT ?? '',
} as const;
export const CREDIT_LINE_FAQ_URLS = {
	[AppLanguages.en]: REACT_APP_CL_FAQ_URL_EN ?? '',
	[AppLanguages.et]: REACT_APP_CL_FAQ_URL_ET ?? '',
	[AppLanguages.ru]: REACT_APP_CL_FAQ_URL_RU ?? '',
	[AppLanguages.lv]: REACT_APP_CL_FAQ_URL_LV ?? '',
	[AppLanguages.lt]: REACT_APP_CL_FAQ_URL_LT ?? '',
} as const;
export const TERMS_PAGE_URLS = {
	[AppLanguages.en]: REACT_APP_TERMS_URL_EN,
	[AppLanguages.et]: REACT_APP_TERMS_URL_ET,
	[AppLanguages.ru]: REACT_APP_TERMS_URL_RU,
	[AppLanguages.lv]: REACT_APP_TERMS_URL_LV,
	[AppLanguages.lt]: REACT_APP_TERMS_URL_LT,
};
export const HOMEPAGE_CREDIT_LINE_PAGE_URLS = {
	[AppLanguages.en]: REACT_APP_HOMEPAGE_CREDIT_LINE_URL_EN ?? '',
	[AppLanguages.et]: REACT_APP_HOMEPAGE_CREDIT_LINE_URL_ET ?? '',
	[AppLanguages.ru]: REACT_APP_HOMEPAGE_CREDIT_LINE_URL_RU ?? '',
	[AppLanguages.lv]: REACT_APP_HOMEPAGE_CREDIT_LINE_URL_LV ?? '',
	[AppLanguages.lt]: REACT_APP_HOMEPAGE_CREDIT_LINE_URL_LT ?? '',
};
export const INTEREST_RATES_BY_SCHEDULE_TYPES_MAP = {
	[ApplicationScheduleType.SMALL_LOAN]: Number(
		REACT_APP_SMALL_LOAN_INTEREST_RATE,
	),
	[ApplicationScheduleType.FAST_LOAN]: Number(
		REACT_APP_FAST_LOAN_INTEREST_RATE,
	),
	[ApplicationScheduleType.RENOVATION_LOAN]: Number(
		REACT_APP_RENOVATION_LOAN_INTEREST_RATE,
	),
	[ApplicationScheduleType.VEHICLE_LOAN]: Number(
		REACT_APP_VEHICLE_LOAN_INTEREST_RATE,
	),
};
export const CREDY_TERMS_PAGE_URLS = {
	[AppLanguages.lt]: REACT_APP_CREDY_TERMS_URL_LT,
};
export const CONTACT_INFO = {
	contactName: REACT_APP_CONTACT_NAME ?? '',
	contactPhone: REACT_APP_CONTACT_PHONE ?? '',
	contactEmail: REACT_APP_CONTACT_EMAIL ?? '',
	contactAddress: REACT_APP_CONTACT_ADDRESS ?? '',
};
export const webUrl = REACT_APP_WEB_URL ?? '';
export const smartlookProjectId = REACT_APP_SMARTLOOK_PROJECT_ID ?? '';
export const zendeskChatKeyCode = REACT_APP_ZENDESK_CHAT_KEY_CODE ?? '';
export const region = REACT_APP_REGION ?? '';
export const siftBeaconKey = REACT_APP_SIFT_BEACON_KEY ?? '';
export const googleAnalyticsId = REACT_APP_GOOGLE_ANALYTICS_ID ?? '';
export const regionPhonePrefix = REACT_APP_PHONE_PREFIX ?? '';
export const regionLanguage = REACT_APP_REGION_LANGUAGE ?? '';

export const rudderStackPlaneUrl = REACT_APP_RUDDERSTACK_PLANE_URL ?? '';
export const rudderStackApiKey = REACT_APP_RUDDERSTACK_API_KEY ?? '';
export const idCardLoginEndpoint = REACT_APP_ID_CARD_LOGIN_ENDPOINT ?? '';
export const onlyPasswordSigningEnabled = Boolean(
	Number(REACT_APP_ONLY_PASSWORD_SIGNING_ENABLED ?? 0),
);
export const isCreditAccountOnboardingOnCustomerProfile = Boolean(
	Number(REACT_APP_CP_CA_ONBOARDING ?? 0),
);
export const PRIVACY_POLICY_URL = REACT_APP_PRIVACY_POLICY_URL ?? '';
