import * as Sentry from '@sentry/react';
import { environment, sentryDsn } from 'environment';
import { useEffect } from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

export const initializeSentry = () => {
	Sentry.init({
		dsn: sentryDsn,
		environment,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		sampleRate: 1,
		tracesSampleRate: 0.5,
	});
};
