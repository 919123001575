import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const ClockIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 64 64"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						clipRule="evenodd"
						d="M32 7C25.2947 7 19.206 9.63977 14.7164 13.9368C9.96129 18.4879 7 24.8981 7 32C7 45.8071 18.1929 57 32 57C45.8071 57 57 45.8071 57 32V31H44.9617C44.7327 28.0137 43.4763 25.0915 41.1924 22.8076C38.9088 20.524 35.9853 19.2675 33 19.0383V14H31V30C31 31.6569 32.3431 33 34 33H42.9547C42.7303 35.4732 41.6715 37.8849 39.7782 39.7782C35.4824 44.074 28.5176 44.074 24.2218 39.7782C19.9261 35.4824 19.9261 28.5176 24.2218 24.2218L22.8076 22.8076C17.7308 27.8844 17.7308 36.1156 22.8076 41.1924C27.8844 46.2692 36.1156 46.2692 41.1924 41.1924C43.4763 38.9085 44.7327 35.9863 44.9617 33H54.9787C54.4551 45.2386 44.3675 55 32 55C19.2975 55 9 44.7025 9 32C9 25.6488 11.5743 19.8988 15.7365 15.7366C15.8631 15.61 15.9909 15.4852 16.1197 15.3622C18.126 13.4467 20.4782 11.8904 23.0731 10.7966C31.4348 7.27975 41.4532 8.92644 48.2635 15.7367L49.6777 14.3224C44.7961 9.44089 38.3981 7 32 7ZM42.9547 31C42.7303 28.5268 41.6714 26.1151 39.7782 24.2218C37.8846 22.3283 35.4741 21.2696 33 21.0453V30C33 30.5523 33.4477 31 34 31H42.9547Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
