import { region, siftBeaconKey } from 'environment';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

type SiftHookMethods = {
	initializeSift: () => void;
	identifyUserInSift: (userEmail: number | null) => void;
};

export const useSift = (): SiftHookMethods => {
	const [sessionId] = useState(uuid());

	const initializeSift = (): void => {
		const siftScript = document.createElement('script');
		siftScript.type = 'text/javascript';
		siftScript.src = 'https://cdn.sift.com/s.js';
		document.body.insertAdjacentElement('afterbegin', siftScript);
	};

	const identifyUserInSift = (userId: number | null) => {
		// biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		const sift = ((window as any)._sift = (window as any)._sift || []);
		const userIdentifier = userId ? `${region}-${userId}` : null;

		sift.push(['_setAccount', siftBeaconKey]);
		sift.push(['_setUserId', userIdentifier]);
		sift.push(['_setSessionId', sessionId]);
		sift.push(['_trackPageview']);
	};

	return {
		initializeSift,
		identifyUserInSift,
	};
};
