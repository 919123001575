import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { type ErrorResponse, onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { AppApiVersions } from 'app-constants';
import { coreApiEndpoint, purchaseFlowApiEndpoint } from 'environment';
import { useHandleErrors } from './use-handle-errors';

export const useClient = () => {
	const { handleGraphQLErrors } = useHandleErrors();
	const errorLink = onError(({ graphQLErrors }: ErrorResponse) => {
		if (graphQLErrors) {
			handleGraphQLErrors(graphQLErrors);
		}
	});

	const coreApiLink = createUploadLink({
		uri: coreApiEndpoint,
		credentials: 'include',
	});

	const purchaseFlowApiLink = createUploadLink({
		uri: purchaseFlowApiEndpoint,
		credentials: 'include',
	});

	const link = from([
		errorLink,
		ApolloLink.split(
			({ getContext }) => getContext().apiVersion === AppApiVersions.core,
			coreApiLink,
			purchaseFlowApiLink,
		),
	]);

	const cache = new InMemoryCache({
		typePolicies: {
			Application: {
				fields: {
					merchant: {
						merge(existing, incoming) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
			User: {
				fields: {
					profile: {
						merge(existing, incoming) {
							return { ...existing, ...incoming };
						},
					},
					user_info: {
						merge(existing, incoming) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
			CreditAccount: {
				fields: {
					user_info: {
						merge(existing, incoming) {
							return { ...existing, ...incoming };
						},
					},
				},
			},
		},
	});

	return new ApolloClient({
		link,
		cache,
	});
};
