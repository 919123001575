import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const GetBackIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		opacity = DEFAULT_ICON_OPACITY,
		color = DEFAULT_ICON_COLOR,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				height={height}
				viewBox="0 0 24 22"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M0 15.8668C7.33618e-09 15.6989 0.0671331 15.5311 0.201399 15.3968L4.73287 10.8654C5.0014 10.5968 5.40419 10.5968 5.67273 10.8654C5.94126 11.1339 5.94126 11.5367 5.67273 11.8052L1.61119 15.8668L5.67273 19.9283C5.94126 20.1968 5.94126 20.5996 5.67273 20.8682C5.40419 21.1367 5.0014 21.1367 4.73286 20.8682L0.201399 16.3367C0.0671331 16.2024 -7.33618e-09 16.0346 0 15.8668Z"
						fill={color}
					/>
					<path
						d="M0.839161 15.6989C0.839161 15.3297 1.14126 15.0276 1.51049 15.0276L22.993 15.0276C23.3622 15.0276 23.6643 15.3297 23.6643 15.6989C23.6643 16.0681 23.3622 16.3702 22.993 16.3702L1.51049 16.3702C1.14126 16.3702 0.839161 16.0681 0.839161 15.6989Z"
						fill={color}
					/>
					<path
						d="M17.958 10.664C17.958 10.4961 18.0252 10.3283 18.1594 10.194L22.221 6.13248L18.1594 2.07095C17.8909 1.80241 17.8909 1.39962 18.1594 1.13109C18.428 0.862555 18.8308 0.862555 19.0993 1.13109L23.6308 5.66255C23.8993 5.93109 23.8993 6.33388 23.6308 6.60242L19.0993 11.1339C18.8308 11.4024 18.428 11.4024 18.1594 11.1339C18.0252 10.9996 17.958 10.8318 17.958 10.664Z"
						fill={color}
					/>
					<path
						d="M0 5.96465C1.61396e-08 5.59542 0.302099 5.29332 0.671329 5.29332L6.32867 5.29333C6.69791 5.29333 7 5.59542 7 5.96465C7 6.33388 6.69791 6.63598 6.32867 6.63598L0.671329 6.63598C0.302099 6.63598 -1.61396e-08 6.33388 0 5.96465Z"
						fill={color}
					/>
					<path
						d="M11 5.96465C11 5.59542 11.3021 5.29332 11.6713 5.29332L21.714 5.29332C22.0832 5.29332 22.3853 5.59542 22.3853 5.96465C22.3853 6.33388 22.0832 6.63598 21.714 6.63598L11.6713 6.63598C11.3021 6.63598 11 6.33388 11 5.96465Z"
						fill={color}
					/>
					<path
						d="M8.32867 5.96465C8.32867 5.59542 8.63077 5.29333 9 5.29333C9.36923 5.29333 9.67133 5.59543 9.67133 5.96466C9.67133 6.33389 9.36923 6.63599 9 6.63599C8.63077 6.63599 8.32867 6.33389 8.32867 5.96465Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
