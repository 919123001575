import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const PasswordIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 33 32"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						clipRule="evenodd"
						d="M2.5 16C2.5 19.3137 5.18629 22 8.5 22C10.7208 22 12.6599 20.7934 13.6973 19H23.5L25 17L26.5 19H28.5L30.5 16L28.5 13H13.6973C12.6599 11.2066 10.7208 10 8.5 10C5.18629 10 2.5 12.6863 2.5 16ZM8.5 19C10.1569 19 11.5 17.6569 11.5 16C11.5 14.3431 10.1569 13 8.5 13C6.84315 13 5.5 14.3431 5.5 16C5.5 17.6569 6.84315 19 8.5 19Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
