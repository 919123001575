import { LOCIZE_ERROR_TRANSLATION_KEYS, LocizeNamespaces } from 'app-constants';
import { CONTACT_INFO } from 'environment';
import type { GraphQLErrorExtended } from 'models';
import { toast } from 'react-toastify';
import { i18Next } from 'services';

export const useHandleErrors = () => {
	const { contactEmail } = CONTACT_INFO;
	const locizeErrorsEntries = Object.entries(LOCIZE_ERROR_TRANSLATION_KEYS);
	// using i18Next instance here cause useTranslation hook triggers rerender of the whole client
	const handleGraphQLErrors = (errors: ReadonlyArray<GraphQLErrorExtended>) => {
		for (const error of errors) {
			const { code, message } = error;
			const locizeErrorEntry =
				code &&
				locizeErrorsEntries.find(([, errorCode]) => +errorCode === +code);

			// DESCRIBED ERROR CODE CASE
			if (locizeErrorEntry) {
				i18Next.then((t) =>
					toast.error(
						t(
							LOCIZE_ERROR_TRANSLATION_KEYS[
								locizeErrorEntry[0] as keyof typeof LOCIZE_ERROR_TRANSLATION_KEYS
							],
							{
								ns: LocizeNamespaces.errors,
								...(code === +LOCIZE_ERROR_TRANSLATION_KEYS.errorCode_2805
									? { contactEmail }
									: {}),
							},
						) as string,
					),
				);
				// VALIDATION MESSAGE ERROR CASE
			} else if (message === LOCIZE_ERROR_TRANSLATION_KEYS.validationError) {
				i18Next.then((t) =>
					toast.error(
						t(LOCIZE_ERROR_TRANSLATION_KEYS.validationError, {
							ns: LocizeNamespaces.errors,
						}) as string,
					),
				);
				// DEFAULT ERROR CASE
			} else {
				i18Next.then((t) =>
					toast.error(
						t(LOCIZE_ERROR_TRANSLATION_KEYS.generalError, {
							ns: LocizeNamespaces.errors,
						}) as string,
					),
				);
			}
		}
	};

	return {
		handleGraphQLErrors,
	};
};
