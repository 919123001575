export const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24; // sec * secs in a min * mins in an hour * hours in a day
export const ONE_YEAR_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 365; // day in ms * days in a year

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';
export const DEFAULT_MAX_DATE = new Date().toISOString().split('T')[0]; // today's date
export const DEFAULT_MIN_DATE = new Date(
	Date.now() - ONE_YEAR_IN_MILLISECONDS * 50,
)
	.toISOString()
	.split('T')[0]; // 50 years before now date

export const MONTHS_IN_YEAR = 12;
