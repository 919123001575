import type { LocizeNamespaces } from 'app-constants';
import type { TFunction } from 'i18next';
import type { AnyObject, Children } from 'models';
import type { ReactElement } from 'react';
import { Trans, type UseTranslationOptions } from 'react-i18next';

type AppLocalizationComponentProps = {
	locizeKey: string;
	values?: AnyObject;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	components?: Record<any, ReactElement>;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	t: TFunction<LocizeNamespaces, UseTranslationOptions<any>>;
	className?: string;
	children?: Children;
};

const AppLocalizationComponent = ({
	locizeKey,
	values,
	components,
	t,
	className,
	children,
}: AppLocalizationComponentProps) => (
	<div className={className}>
		<Trans components={components} i18nKey={locizeKey} t={t} values={values}>
			{children}
		</Trans>
	</div>
);

export default AppLocalizationComponent;
