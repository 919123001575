import { IconNames } from 'app-constants';
import scssVars from 'styles/export.module.scss';
import {
	BagIcon,
	BanklinkIcon,
	BottomArrowIcon,
	CalendarIcon,
	CardIcon,
	CheckMarkIcon,
	CheckboxIcon,
	ChevronIcon,
	CircledCheckMarkIcon,
	ClockIcon,
	CloseIcon,
	ContractIcon,
	DiscountTagIcon,
	EnglishFlagIcon,
	EparakstsMobileIcon,
	EparakstsSmartCardIcon,
	EstoLogoIcon,
	EstonianFlagIcon,
	EyeClosedPasswordIcon,
	EyeOpenedPasswordIcon,
	FailureIcon,
	FamilyIcon,
	GetBackIcon,
	IdCardIcon,
	LatvianFlagIcon,
	LeftArrowIcon,
	LithuanianFlagIcon,
	MailIcon,
	MobileIdIcon,
	PasswordIcon,
	PersonIcon,
	QuestionMarkIcon,
	RejectIcon,
	RussianFlagIcon,
	ScrapIcon,
	SheetIcon,
	SmartIdIcon,
	SuccessIcon,
	TopRightArrowIcon,
	WalletIcon,
} from './icons';

const {
	colorPrimary,
	colorSecondary,
	colorTertiaryDarkened,
	colorSecondaryWhite,
	colorWhite,
	colorRed,
	colorYellow,
	colorDarkBlue,
	colorGreen,
	colorBlack,
} = scssVars;

const {
	checkbox,
	estoLogo,
	leftArrow,
	questionMark,
	bottomArrow,
	englishFlag,
	latvianFlag,
	lithuanianFlag,
	estonianFlag,
	russianFlag,
	topRightArrow,
	smartId,
	mobileId,
	banklink,
	password,
	bag,
	scrap,
	eParakstsSmartCard,
	eParakstsMobile,
	chevron,
	circledCheckMark,
	reject,
	calendar,
	clock,
	person,
	family,
	eyeClosedPassword,
	eyeOpenedPassword,
	contract,
	checkMark,
	card,
	idCard,
	discountTag,
	failureIcon,
	successIcon,
	walletIcon,
	getBackIcon,
	mailIcon,
	closeIcon,
	sheet,
} = IconNames;

export const ICONS_MAP = {
	[sheet]: {
		icon: SheetIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 24,
		},
	},
	[closeIcon]: {
		icon: CloseIcon,
		color: colorBlack,
		size: {
			width: 20,
			height: 20,
		},
	},
	[walletIcon]: {
		icon: WalletIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 24,
		},
	},
	[getBackIcon]: {
		icon: GetBackIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 22,
		},
	},
	[mailIcon]: {
		icon: MailIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 20,
		},
	},
	[successIcon]: {
		icon: SuccessIcon,
		color: colorGreen,
		size: {
			width: 48,
			height: 48,
		},
	},
	[failureIcon]: {
		icon: FailureIcon,
		color: colorRed,
		size: {
			width: 48,
			height: 48,
		},
	},
	[discountTag]: {
		icon: DiscountTagIcon,
		color: colorPrimary,
		size: {
			width: 26,
			height: 33,
		},
	},
	[calendar]: {
		icon: CalendarIcon,
		color: colorPrimary,
		size: {
			width: 18,
			height: 18,
		},
	},
	[eParakstsSmartCard]: {
		icon: EparakstsSmartCardIcon,
		color: colorSecondary,
		size: {
			width: 24,
			height: 24,
		},
	},
	[eParakstsMobile]: {
		icon: EparakstsMobileIcon,
		color: colorSecondary,
		size: {
			width: 33,
			height: 32,
		},
	},
	[chevron]: {
		icon: ChevronIcon,
		color: colorWhite,
		size: {
			width: 12,
			height: 8,
		},
	},
	[scrap]: {
		icon: ScrapIcon,
		color: colorSecondary,
		size: {
			width: 35,
			height: 35,
		},
	},
	[bag]: {
		icon: BagIcon,
		color: colorSecondary,
		size: {
			width: 64,
			height: 64,
		},
	},
	[smartId]: {
		icon: SmartIdIcon,
		color: colorSecondary,
		size: {
			width: 32,
			height: 32,
		},
	},
	[mobileId]: {
		icon: MobileIdIcon,
		color: colorSecondary,
		size: {
			width: 32,
			height: 32,
		},
	},
	[idCard]: {
		icon: IdCardIcon,
		color: colorSecondary,
		size: {
			width: 32,
			height: 32,
		},
	},
	[banklink]: {
		icon: BanklinkIcon,
		color: colorSecondary,
		size: {
			width: 33,
			height: 33,
		},
	},
	[password]: {
		icon: PasswordIcon,
		color: colorSecondary,
		size: {
			width: 33,
			height: 32,
		},
	},
	[checkbox]: {
		icon: CheckboxIcon,
		color: colorPrimary,
		size: {
			width: 10,
			height: 10,
		},
	},
	[estoLogo]: {
		icon: EstoLogoIcon,
		color: colorTertiaryDarkened,
		size: {
			width: 92,
			height: 32,
		},
	},
	[leftArrow]: {
		icon: LeftArrowIcon,
		color: colorTertiaryDarkened,
		size: {
			width: 10,
			height: 16,
		},
	},
	[topRightArrow]: {
		icon: TopRightArrowIcon,
		color: colorTertiaryDarkened,
		size: {
			width: 14,
			height: 14,
		},
	},
	[bottomArrow]: {
		icon: BottomArrowIcon,
		color: colorTertiaryDarkened,
		size: {
			width: 10,
			height: 6,
		},
	},
	[questionMark]: {
		icon: QuestionMarkIcon,
		color: colorSecondaryWhite,
		size: {
			width: 24,
			height: 24,
		},
	},
	[englishFlag]: {
		icon: EnglishFlagIcon,
		color: undefined,
		size: {
			width: 14,
			height: 14,
		},
	},
	[latvianFlag]: {
		icon: LatvianFlagIcon,
		color: undefined,
		size: {
			width: 14,
			height: 14,
		},
	},
	[lithuanianFlag]: {
		icon: LithuanianFlagIcon,
		color: undefined,
		size: {
			width: 14,
			height: 14,
		},
	},
	[estonianFlag]: {
		icon: EstonianFlagIcon,
		color: undefined,
		size: {
			width: 14,
			height: 14,
		},
	},
	[russianFlag]: {
		icon: RussianFlagIcon,
		color: undefined,
		size: {
			width: 14,
			height: 14,
		},
	},
	[circledCheckMark]: {
		icon: CircledCheckMarkIcon,
		color: colorPrimary,
		size: {
			width: 64,
			height: 64,
		},
	},
	[reject]: {
		icon: RejectIcon,
		color: colorRed,
		size: {
			width: 64,
			height: 64,
		},
	},
	[clock]: {
		icon: ClockIcon,
		color: colorYellow,
		size: {
			width: 64,
			height: 64,
		},
	},
	[person]: {
		icon: PersonIcon,
		color: colorPrimary,
		size: {
			width: 50,
			height: 50,
		},
	},
	[family]: {
		icon: FamilyIcon,
		color: colorPrimary,
		size: {
			width: 50,
			height: 50,
		},
	},
	[eyeClosedPassword]: {
		icon: EyeClosedPasswordIcon,
		color: colorDarkBlue,
		size: {
			width: 24,
			height: 24,
		},
	},
	[eyeOpenedPassword]: {
		icon: EyeOpenedPasswordIcon,
		color: colorDarkBlue,
		size: {
			width: 24,
			height: 24,
		},
	},
	[contract]: {
		icon: ContractIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 24,
		},
	},
	[checkMark]: {
		icon: CheckMarkIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 24,
		},
	},
	[card]: {
		icon: CardIcon,
		color: colorPrimary,
		size: {
			width: 24,
			height: 24,
		},
	},
};
