import { AppProductType, AppRoutePaths } from 'app-constants';
import { useLocation } from 'react-router-dom';

export const useGetProductTypeByUrl = () => {
	const { pathname } = useLocation();

	if (pathname.includes(AppRoutePaths.CREDIT_LINE_WITHDRAWAL))
		return AppProductType.CREDIT_LINE_WITHDRAWAL;

	if (pathname.includes(AppRoutePaths.HIRE_PURCHASE))
		return AppProductType.HIRE_PURCHASE;

	if (pathname.includes(AppRoutePaths.SMALL_LOAN))
		return AppProductType.SMALL_LOAN;

	if (pathname.includes(AppRoutePaths.CREDIT_LINE))
		return AppProductType.CREDIT_LINE;

	if (pathname.includes(AppRoutePaths.CREDIT_LIMIT_RECALCULATION))
		return AppProductType.CREDIT_LIMIT_RECALCULATION;

	return null;
};
