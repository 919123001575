import { DEFAULT_ICON_SIZE } from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const ContractIcon = memo(
	({ color, opacity, size = DEFAULT_ICON_SIZE }: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						clipRule="evenodd"
						d="M7.125 6C7.125 4.96447 7.96447 4.125 9 4.125C10.0355 4.125 10.875 4.96447 10.875 6C10.875 7.03553 10.0355 7.875 9 7.875C7.96447 7.875 7.125 7.03553 7.125 6ZM9 4.875C8.37868 4.875 7.875 5.37868 7.875 6C7.875 6.62132 8.37868 7.125 9 7.125C9.62132 7.125 10.125 6.62132 10.125 6C10.125 5.37868 9.62132 4.875 9 4.875Z"
						fill={color}
						fillRule="evenodd"
					/>
					<path
						d="M18.8598 14.7349L16.7378 16.8568C16.4543 17.1403 16.6551 17.625 17.056 17.625H18.2196L16.6098 19.2349L17.1401 19.7652L19.2621 17.6432C19.5456 17.3597 19.3448 16.875 18.9439 16.875H17.7803L19.3901 15.2652L18.8598 14.7349Z"
						fill={color}
					/>
					<path
						clipRule="evenodd"
						d="M14.7225 1.875H9.27769C8.45304 1.875 7.80351 1.87499 7.28752 1.9309C6.75984 1.98807 6.32751 2.10729 5.95721 2.37633C5.73436 2.53824 5.53839 2.73422 5.37648 2.95706C5.10744 3.32737 4.98821 3.75969 4.93104 4.28737C4.87514 4.80336 4.87514 5.45288 4.87515 6.27754L4.875 16.875C4.875 17.7034 4.20343 18.375 3.375 18.375C2.54657 18.375 1.875 17.7034 1.875 16.875V14.25C1.875 13.6287 2.37868 13.125 3 13.125H3.375V12.375H3C1.96447 12.375 1.125 13.2145 1.125 14.25V16.875C1.125 18.1176 2.13236 19.125 3.375 19.125H13.4986C14.2333 20.8867 15.972 22.125 18 22.125C20.6924 22.125 22.875 19.9424 22.875 17.25C22.875 14.9448 21.275 13.0133 19.125 12.5054V6.27753C19.125 5.4529 19.125 4.80335 19.0691 4.28737C19.0119 3.75969 18.8927 3.32737 18.6237 2.95706C18.4618 2.73422 18.2658 2.53824 18.0429 2.37633C17.6726 2.10729 17.2403 1.98807 16.7126 1.9309C16.1966 1.87499 15.5471 1.875 14.7225 1.875ZM18.375 12.3892V6.3C18.375 5.44796 18.3745 4.83905 18.3235 4.36816C18.2732 3.90429 18.1776 3.61905 18.0169 3.3979C17.9013 3.23873 17.7613 3.09874 17.6021 2.98309C17.381 2.82242 17.0957 2.72679 16.6318 2.67653C16.161 2.62552 15.552 2.625 14.7 2.625H9.30015C8.4481 2.625 7.83919 2.62552 7.36831 2.67653C6.90444 2.72679 6.61919 2.82242 6.39805 2.98309C6.23887 3.09874 6.09889 3.23873 5.98324 3.3979C5.82257 3.61905 5.72694 3.90429 5.67668 4.36816C5.62566 4.83905 5.62515 5.44796 5.62515 6.3L5.625 16.875C5.625 17.4513 5.40836 17.9769 5.05208 18.375H13.2554C13.1701 18.0139 13.125 17.6372 13.125 17.25C13.125 14.5576 15.3076 12.375 18 12.375C18.1262 12.375 18.2512 12.3798 18.375 12.3892ZM13.875 17.25C13.875 14.9718 15.7218 13.125 18 13.125C20.2782 13.125 22.125 14.9718 22.125 17.25C22.125 19.5282 20.2782 21.375 18 21.375C15.7218 21.375 13.875 19.5282 13.875 17.25Z"
						fill={color}
						fillRule="evenodd"
					/>
				</g>
			</svg>
		);
	},
);
