import {
	AppRoutePaths,
	AppSearchParams,
	CreditLineRoutePaths,
} from 'app-constants';
import { AppLoader } from 'components/app-loader';
import { useRootContext } from 'context/root';
import { useBoolean, useEffectOnce } from 'hooks';
import { useStoreCreditAccount } from 'hooks/use-store-credit-account';
import { useEffect, useState } from 'react';
import {
	NavigationType,
	Outlet,
	useLocation,
	useNavigate,
	useNavigationType,
} from 'react-router-dom';
import { replaceOrAddQueryParamsInUrl } from 'services';

const NO_REQUIRED_PAGE_URL_CHECK_ROUTES: Array<string> = [
	CreditLineRoutePaths.SPOUSE,
];

export const CreditLineRoot = () => {
	const { value: initialPageLoad, setFalse } = useBoolean(true); // Initial Credit Line Page Load
	const [canRenderRoutes, setCanRenderRoutes] = useState(false);
	const { pathname, search } = useLocation();
	const navigate = useNavigate();
	const navigationType = useNavigationType();

	const { storeCreditAccount } = useStoreCreditAccount();
	const { getUser, getPageUrlAndNavigate } = useRootContext();

	const isRootPath = pathname.split('/').length === 2;

	const manageCreditAccountCreation = async () => {
		const user = await getUser();
		const userIsAuthorized = Boolean(user.data.me);

		if (userIsAuthorized) {
			// biome-ignore lint/style/noNonNullAssertion: <explanation>
			const creditAccount = await storeCreditAccount(user?.data?.me?.id!);

			const path = isRootPath
				? `/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.CHECKOUT}`
				: pathname;

			const customCurrentPageUrl = replaceOrAddQueryParamsInUrl({
				url: `${path}${search}`,
				params: {
					[AppSearchParams.creditAccountHash]:
						creditAccount?.data?.credit_account?.hash || '',
				},
			});

			if (!NO_REQUIRED_PAGE_URL_CHECK_ROUTES.includes(pathname)) {
				navigate(customCurrentPageUrl);
			} else {
				await getPageUrlAndNavigate(null, {
					customCurrentPageUrl,
				});
			}
		} else if (!userIsAuthorized && isRootPath) {
			await getPageUrlAndNavigate(null, {
				customCurrentPageUrl: `/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.CHECKOUT}${search}`,
			});
		}

		setCanRenderRoutes(true);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (
			!initialPageLoad &&
			isRootPath &&
			navigationType === NavigationType.Pop
		) {
			navigate(-1);
		}
	}, [isRootPath, navigate, navigationType]);

	useEffectOnce(() => {
		setFalse();
		manageCreditAccountCreation();
	});

	return canRenderRoutes ? <Outlet /> : <AppLoader isRelative />;
};
