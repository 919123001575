import { useLogoutMutation } from 'api/core/generated';
import { AppApiVersions } from 'app-constants';

export const useLogout = () => {
	const [logoutRequest] = useLogoutMutation({
		context: { apiVersion: AppApiVersions.core },
	});
	const logOut = () => logoutRequest();
	return {
		logOut,
	};
};
