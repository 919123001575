export enum AppRoutePaths {
	HIRE_PURCHASE = 'hire-purchase',
	SMALL_LOAN = 'small-loan',
	CREDIT_LINE = 'credit-line',
	SHORT_LINK = 'p',
	CREDIT_LIMIT_RECALCULATION = 'credit-limit-recalculation',
	CREDIT_LINE_WITHDRAWAL = 'credit-line-withdrawal',
}

export enum HirePurchaseRoutePaths {
	SCHEDULE = 'schedule',
	CHECKOUT = 'checkout',
	LOGIN = 'login',
	CONTACT = 'contact',
	PURPOSE_OF_LOAN = 'purpose-of-loan',
	CONTACT_EXTRA = 'contact-extra',
	SPOUSE = 'spouse',
	ACCOUNT_SCORING = 'account-scoring',
	EMTA_CONSENT = 'emta-consent',
	SIGNING = 'signing',
	PENDING = 'pending',
	SUCCESS = 'success',
	REJECT = 'reject',
	DIRECT_PAYMENT = 'direct-payment',
	AFTER_DIRECT_PAYMENT = 'after-direct-payment',
	AFTER_DIRECT_CARD_PAYMENT = 'after-direct-payment-card',
}

export enum SmallLoanRoutePaths {
	CHECKOUT = 'checkout',
	LOGIN = 'login',
	CONTACT = 'contact',
	PURPOSE_OF_LOAN = 'purpose-of-loan',
	CONTACT_EXTRA = 'contact-extra',
	SPOUSE = 'spouse',
	ACCOUNT_SCORING = 'account-scoring',
	EMTA_CONSENT = 'emta-consent',
	SIGNING = 'signing',
	PENDING = 'pending',
	SUCCESS = 'success',
	REJECT = 'reject',
}

export enum CreditLineRoutePaths {
	CHECKOUT = 'checkout',
	LOGIN = 'login',
	CONTACT = 'contact',
	PURPOSE_OF_LOAN = 'purpose-of-loan',
	CONTACT_EXTRA = 'contact-extra',
	SPOUSE = 'spouse',
	ACCOUNT_SCORING = 'account-scoring',
	EMTA_CONSENT = 'emta-consent',
	SIGNING = 'signing',
	PENDING = 'pending',
	SUCCESS = 'success',
	REJECT = 'reject',
}

export enum CreditLimitRecalculationRoutePaths {
	CHECKOUT = 'checkout',
	LOGIN = 'login',
	CONTACT = 'contact',
	PURPOSE_OF_LOAN = 'purpose-of-loan',
	CONTACT_EXTRA = 'contact-extra',
	SPOUSE = 'spouse',
	ACCOUNT_SCORING = 'account-scoring',
	EMTA_CONSENT = 'emta-consent',
	PENDING = 'pending',
}

export enum CreditLineWithdrawalRoutePaths {
	CHECKOUT = 'checkout',
	LOGIN = 'login',
	CONTACT = 'contact',
	PURPOSE_OF_LOAN = 'purpose-of-loan',
	CONTACT_EXTRA = 'contact-extra',
	SPOUSE = 'spouse',
	ACCOUNT_SCORING = 'account-scoring',
	EMTA_CONSENT = 'emta-consent',
	PENDING = 'pending',
}

export const ROOT_PAGE_PATH = '';
export const LOGIN_PAGE_PATH = 'login';
export const ERROR_PAGE_PATH = 'error';
export const UNKNOWN_PAGE_ROUTE_PATH = '*';

const HIRE_PURCHASE_NO_BACK_BUTTON_PATHS = [
	`/${AppRoutePaths.HIRE_PURCHASE}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.SCHEDULE}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.CHECKOUT}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.SUCCESS}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.REJECT}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.PENDING}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.SPOUSE}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.AFTER_DIRECT_PAYMENT}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${HirePurchaseRoutePaths.AFTER_DIRECT_CARD_PAYMENT}`,
	`/${AppRoutePaths.HIRE_PURCHASE}/${ERROR_PAGE_PATH}`,
];

const SMALL_LOAN_NO_BACK_BUTTON_PATHS = [
	`/${AppRoutePaths.SMALL_LOAN}`,
	`/${AppRoutePaths.SMALL_LOAN}/${SmallLoanRoutePaths.CHECKOUT}`,
	`/${AppRoutePaths.SMALL_LOAN}/${SmallLoanRoutePaths.SUCCESS}`,
	`/${AppRoutePaths.SMALL_LOAN}/${SmallLoanRoutePaths.REJECT}`,
	`/${AppRoutePaths.SMALL_LOAN}/${SmallLoanRoutePaths.PENDING}`,
	`/${AppRoutePaths.SMALL_LOAN}/${SmallLoanRoutePaths.SPOUSE}`,
	`/${AppRoutePaths.SMALL_LOAN}/${ERROR_PAGE_PATH}`,
];

const CREDIT_LINE_NO_BACK_BUTTON_PATHS = [
	`/${AppRoutePaths.CREDIT_LINE}`,
	`/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.CHECKOUT}`,
	`/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.SUCCESS}`,
	`/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.REJECT}`,
	`/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.PENDING}`,
	`/${AppRoutePaths.CREDIT_LINE}/${CreditLineRoutePaths.SPOUSE}`,
	`/${AppRoutePaths.CREDIT_LINE}/${ERROR_PAGE_PATH}`,
];

const CREDIT_LINE_WITHDRAWAL_NO_BACK_BUTTON_PATHS = [
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLineWithdrawalRoutePaths.CHECKOUT}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLineWithdrawalRoutePaths.LOGIN}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLineWithdrawalRoutePaths.CONTACT}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLineWithdrawalRoutePaths.SPOUSE}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLineWithdrawalRoutePaths.PENDING}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${ERROR_PAGE_PATH}`,
];

const CREDIT_LIMIT_RECALCULATION_NO_BACK_BUTTON_PATHS = [
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLimitRecalculationRoutePaths.CHECKOUT}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLimitRecalculationRoutePaths.LOGIN}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLimitRecalculationRoutePaths.CONTACT}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLimitRecalculationRoutePaths.SPOUSE}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${CreditLimitRecalculationRoutePaths.PENDING}`,
	`/${AppRoutePaths.CREDIT_LIMIT_RECALCULATION}/${ERROR_PAGE_PATH}`,
];

export const NO_BACK_BUTTON_ROUTE_PATHS = [
	...HIRE_PURCHASE_NO_BACK_BUTTON_PATHS,
	...SMALL_LOAN_NO_BACK_BUTTON_PATHS,
	...CREDIT_LINE_NO_BACK_BUTTON_PATHS,
	...CREDIT_LINE_WITHDRAWAL_NO_BACK_BUTTON_PATHS,
	...CREDIT_LIMIT_RECALCULATION_NO_BACK_BUTTON_PATHS,
	`/${ERROR_PAGE_PATH}`,
];
