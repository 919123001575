import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const LeftArrowIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 10 16"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M9.83785 14.0548C9.72648 14.1607 9.61669 14.265 9.50842 14.368C8.95051 14.8983 8.43298 15.3902 7.94797 15.8599C6.66989 14.5916 5.37491 13.2979 4.07339 11.9978C2.77176 10.6975 1.46358 9.39067 0.15947 8.09629C2.71528 5.49105 5.30554 2.85139 7.96378 0.143829L9.81093 2.08208L3.77097 8.04375L3.69835 8.11542L3.77148 8.18658C5.79936 10.16 7.82721 12.1161 9.83785 14.0548Z"
						fill={color}
						stroke="#FAFBFF"
						strokeWidth="0.2"
					/>
				</g>
			</svg>
		);
	},
);
