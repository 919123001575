import { AppLanguageSelector } from 'components/app-language-selector';
import { AppLogOutButton } from 'components/app-logout-button';

type AppHeaderMobileContentProps = {
	shouldShowLogoutButton: boolean;
};

const AppHeaderDesktopContent = ({
	shouldShowLogoutButton,
}: AppHeaderMobileContentProps) => {
	return (
		<>
			<AppLanguageSelector isSelect />
			{shouldShowLogoutButton ? <AppLogOutButton /> : null}
		</>
	);
};

export default AppHeaderDesktopContent;
