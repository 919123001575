import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const EparakstsSmartCardIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M19.0401 19.2674H14.3084V23.9997H9.57669V19.2674H4.84498V23.9989H0.111328V19.2674H4.84498V9.80411H9.57669V5.07181H14.3084V0.341797H19.0401V5.07181H23.7738V9.80411H19.0401V19.2674ZM9.57669 5.07181H4.84498V0.341797H9.57669V5.07181Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
