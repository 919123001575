import c from 'classnames';
import { AppPortal } from 'components/app-portal';
import { useMemo } from 'react';
import styles from './AppModal.module.scss';
import { AppModalBody } from './AppModalBody';
import { AppModalContainer } from './AppModalContainer';
import { AppModalHeader } from './AppModalHeader';
import { AppModalContext } from './app-modal.context';
import type { AppModalProps } from './app-modal.type';

const AppModal = ({ noBackdrop = false, onClose, children }: AppModalProps) => {
	const contextValue = useMemo(() => ({ onClose }), [onClose]);
	return (
		<AppPortal>
			<AppModalContext.Provider value={contextValue}>
				<div
					className={c(styles.backdrop, {
						[styles['backdrop--transparent']]: noBackdrop,
					})}
				/>
				{children}
			</AppModalContext.Provider>
		</AppPortal>
	);
};

AppModal.ModalContainer = AppModalContainer;
AppModal.ModalHeader = AppModalHeader;
AppModal.ModalBody = AppModalBody;

export default AppModal;
