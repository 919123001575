import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const MailIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		opacity = DEFAULT_ICON_OPACITY,
		color = DEFAULT_ICON_COLOR,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				height={height}
				viewBox="0 0 24 20"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M18.2257 5.9489C18.0434 5.76586 17.7466 5.76465 17.5628 5.94624C17.3791 6.12788 17.3778 6.42354 17.5602 6.60663L17.563 6.60952C17.6543 6.70118 17.7739 6.74685 17.8937 6.74685C18.0131 6.74685 18.1327 6.70137 18.2245 6.61069C18.4083 6.42905 18.4081 6.13199 18.2257 5.9489Z"
						fill={color}
					/>
					<path
						d="M23.8627 11.5641L19.509 7.22728C19.326 7.04499 19.0292 7.04499 18.8461 7.22728C18.663 7.40962 18.663 7.70528 18.8461 7.88767L22.3022 11.3304H14.0605C13.3767 11.3304 12.8206 10.7764 12.8206 10.0953V1.88549L16.1573 5.20923C16.3403 5.39152 16.6371 5.39152 16.8202 5.20923C17.0033 5.02689 17.0033 4.73123 16.8202 4.54884L12.586 0.331055C12.403 0.148763 12.1061 0.148763 11.9231 0.331055L4.4503 7.77509C4.26726 7.95743 4.26726 8.25309 4.4503 8.43548L15.727 19.6685C15.8148 19.756 15.9341 19.8052 16.0584 19.8052C16.1827 19.8052 16.302 19.756 16.3899 19.6685L23.8627 12.2245C23.9506 12.1369 24 12.0181 24 11.8943C24 11.7705 23.9505 11.6517 23.8627 11.5641ZM11.8832 1.69166V7.63837H5.91336L11.8832 1.69166ZM15.5897 18.211L5.91336 8.5722H11.8832V10.0953C11.8832 11.2913 12.8599 12.2643 14.0606 12.2643H15.5897V18.211ZM16.5271 18.211V12.2643H22.4969L16.5271 18.211Z"
						fill={color}
					/>
					<path
						d="M3.21707 9.90637H0.468749C0.209859 9.90637 0 10.1155 0 10.3733C0 10.6312 0.209859 10.8402 0.468749 10.8402H3.21707C3.47596 10.8402 3.68582 10.6312 3.68582 10.3733C3.68582 10.1155 3.47596 9.90637 3.21707 9.90637Z"
						fill={color}
					/>
					<path
						d="M4.61465 9.90637H4.60762C4.34873 9.90637 4.13887 10.1155 4.13887 10.3733C4.13887 10.6312 4.34873 10.8402 4.60762 10.8402H4.61465C4.87354 10.8402 5.0834 10.6312 5.0834 10.3733C5.0834 10.1155 4.87354 9.90637 4.61465 9.90637Z"
						fill={color}
					/>
					<path
						d="M1.9695 5.00368H0.469358C0.210468 5.00368 0.000609374 5.21278 0.000609374 5.47062C0.000609374 5.72846 0.210468 5.93756 0.469358 5.93756H1.9695C2.22839 5.93756 2.43825 5.72846 2.43825 5.47062C2.43825 5.21278 2.22839 5.00368 1.9695 5.00368Z"
						fill={color}
					/>
					<path
						d="M5.2739 5.00368H3.50071C3.24182 5.00368 3.03196 5.21278 3.03196 5.47062C3.03196 5.72846 3.24182 5.93756 3.50071 5.93756H5.2739C5.53279 5.93756 5.74265 5.72846 5.74265 5.47062C5.74265 5.21278 5.53279 5.00368 5.2739 5.00368Z"
						fill={color}
					/>
					<path
						d="M6.29446 12.4745H3.66951C3.41062 12.4745 3.20076 12.6836 3.20076 12.9414C3.20076 13.1993 3.41062 13.4083 3.66951 13.4083H6.29446C6.55335 13.4083 6.76321 13.1993 6.76321 12.9414C6.76321 12.6836 6.5533 12.4745 6.29446 12.4745Z"
						fill={color}
					/>
					<path
						d="M8.34411 15.4628H1.73479C1.4759 15.4628 1.26604 15.6719 1.26604 15.9297C1.26604 16.1876 1.4759 16.3966 1.73479 16.3966H8.34411C8.603 16.3966 8.81286 16.1876 8.81286 15.9297C8.81286 15.6719 8.603 15.4628 8.34411 15.4628Z"
						fill={color}
					/>
				</g>
			</svg>
		);
	},
);
