if (typeof Node === 'function' && Node.prototype) {
	const originalRemoveChild = Node.prototype.removeChild;
	Node.prototype.removeChild = function (child) {
		if (child.parentNode !== this) {
			if (console) {
				console.warn('Cannot remove a child from a different parent');
			}
			return child;
		}
		// biome-ignore lint/style/noArguments: <explanation>
		return originalRemoveChild.apply(this, arguments);
	};

	const originalInsertBefore = Node.prototype.insertBefore;
	Node.prototype.insertBefore = function (newNode, referenceNode) {
		if (referenceNode && referenceNode.parentNode !== this) {
			if (console) {
				console.warn(
					'Cannot insert before a reference node from a different parent',
				);
			}
			return newNode;
		}
		// biome-ignore lint/style/noArguments: <explanation>
		return originalInsertBefore.apply(this, arguments);
	};
}
