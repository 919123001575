import {
	DEFAULT_ICON_COLOR,
	DEFAULT_ICON_OPACITY,
	DEFAULT_ICON_SIZE,
} from 'app-constants';
import { memo } from 'react';
import type { AppIconProps } from '../app-icon.types';

export const QuestionMarkIcon = memo(
	({
		size = DEFAULT_ICON_SIZE,
		color = DEFAULT_ICON_COLOR,
		opacity = DEFAULT_ICON_OPACITY,
	}: AppIconProps) => {
		const { width, height } = size;

		return (
			// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
			<svg
				fill="none"
				height={height}
				viewBox="0 0 24 24"
				width={width}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g opacity={opacity}>
					<path
						d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
						fill={color}
					/>
					<path
						d="M11.0809 18V16.56H12.5209V18H11.0809ZM11.1609 15.44V14.48C11.1609 13.8507 11.2889 13.3493 11.5449 12.976C11.8116 12.592 12.2703 12.24 12.9209 11.92C13.7209 11.5147 14.1209 10.848 14.1209 9.92C14.1209 9.32267 13.9076 8.82667 13.4809 8.432C13.0543 8.03733 12.5209 7.84 11.8809 7.84C11.2196 7.84 10.6809 8.02667 10.2649 8.4C9.84894 8.77333 9.64094 9.22667 9.64094 9.76H8.36094C8.36094 8.87467 8.68627 8.13333 9.33694 7.536C9.99827 6.93867 10.8463 6.64 11.8809 6.64C12.9156 6.64 13.7583 6.95467 14.4089 7.584C15.0703 8.20267 15.4009 8.98133 15.4009 9.92C15.4009 11.2853 14.7876 12.272 13.5609 12.88C13.1129 13.0933 12.8143 13.3227 12.6649 13.568C12.5156 13.8133 12.4409 14.1173 12.4409 14.48V15.44H11.1609Z"
						fill="#122A72"
					/>
				</g>
			</svg>
		);
	},
);
