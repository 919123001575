import { useGetLanguagesQuery } from 'api/core/generated';
import { AppApiVersions } from 'app-constants';
import type { AnyObject } from 'models';

export const useGetLanguages = () => {
	const { data, loading, error } = useGetLanguagesQuery({
		context: { apiVersion: AppApiVersions.core },
	});

	return {
		languages: (data?.languages ?? []) as Array<AnyObject>,
		languagesLoading: loading,
		languagesError: error,
	};
};
