import { AppLanguages } from './app.constants';

export enum ZendeskRegions {
	en = 'EE',
	et = 'EE',
	lv = 'LV',
	lt = 'LT',
	ru = 'RU',
}

export const ZENDESK_DEPARTMENT_REGION_BY_LANGUAGES_MAP = {
	[AppLanguages.en]: ZendeskRegions.en,
	[AppLanguages.et]: ZendeskRegions.et,
	[AppLanguages.lv]: ZendeskRegions.lv,
	[AppLanguages.lt]: ZendeskRegions.lt,
	[AppLanguages.ru]: ZendeskRegions.ru,
};
