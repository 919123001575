import { AppLoader } from 'components';
import { useEffectOnce, useGetPageUrl } from 'hooks';
import type { FC } from 'react';

const withPageUrlCheck = (WrappedComponent: FC) => {
	return () => {
		const { pageUrl, getPageUrlAndNavigate, pageUrlAndNavigationProcessing } =
			useGetPageUrl();

		useEffectOnce(() => {
			getPageUrlAndNavigate();
		});

		if (!pageUrl || pageUrlAndNavigationProcessing) {
			return <AppLoader isRelative />;
		}
		return <WrappedComponent />;
	};
};

export default withPageUrlCheck;
