import { App } from 'App';
import { AppProviders } from 'components';
import { isDevelopment } from 'environment';
import { createRoot } from 'react-dom/client';
import { initializeSentry } from 'services';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import 'scripts/remove-child-on-node';

import './styles/global.scss';

if (!isDevelopment) initializeSentry();

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

createRoot(rootElement).render(
	<AppProviders>
		<App />
	</AppProviders>,
);
