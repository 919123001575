import {
	AppProductType,
	HirePurchaseRoutePaths,
	IconNames,
	NO_BACK_BUTTON_ROUTE_PATHS,
} from 'app-constants';
import { AppIcon } from 'components/app-icon';
import { useRootContext } from 'context/root';
import { isDekker, isDevelopment } from 'environment';
import { useShouldShowSchedulePage } from 'hooks';
import estoLogo from 'img/esto-logo.png';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { getProductTypeInfo } from 'services';
import AppHeaderDesktopContent from './AppHeaderDesktopContent';
import AppHeaderMobileContent from './AppHeaderMobileContent';

import styles from './AppHeader.module.scss';

const AppHeader = () => {
	const location = useLocation();
	const {
		isTablet,
		getPageUrlAndNavigate,
		user,
		applicationPrivateInfo,
		productType,
	} = useRootContext();
	const shouldShowSchedulePage = useShouldShowSchedulePage();
	const { is_test, logo_url } = applicationPrivateInfo ?? {};
	const hasApplicationData = Boolean(applicationPrivateInfo);
	const { isCreditLine, isCreditLimitRecalculation, isCreditLineWithdrawal } =
		getProductTypeInfo(productType || AppProductType.CREDIT_LINE);

	const shouldShowSkeletonInsteadOfLogo =
		!hasApplicationData &&
		!isCreditLine &&
		!isCreditLineWithdrawal &&
		!isCreditLimitRecalculation;

	const shouldShowBackButton = !NO_BACK_BUTTON_ROUTE_PATHS.some(
		(path) => location.pathname === path,
	);

	const shouldShowBackButtonForSchedulePage =
		productType === AppProductType.HIRE_PURCHASE &&
		location.pathname.includes(HirePurchaseRoutePaths.CHECKOUT) &&
		shouldShowSchedulePage;

	const shouldShowLogoutButton = Boolean(
		hasApplicationData ? user && is_test : user && (isDekker || isDevelopment),
	);

	const onBackButtonClick = () => {
		getPageUrlAndNavigate(false);
	};

	return (
		<div className={styles.header}>
			{shouldShowBackButton || shouldShowBackButtonForSchedulePage ? (
				<AppIcon
					className={styles['back-button']}
					name={IconNames.leftArrow}
					onClick={onBackButtonClick}
				/>
			) : null}
			<div className={styles['container-logo']}>
				{shouldShowSkeletonInsteadOfLogo ? (
					<Skeleton className={styles['skeleton-logo']} />
				) : (
					<img
						alt="ESTO_AS"
						className={styles.logo}
						src={logo_url || estoLogo}
					/>
				)}
			</div>
			<div className={styles['right-section']}>
				{isTablet ? (
					<AppHeaderMobileContent
						shouldShowLogoutButton={shouldShowLogoutButton}
					/>
				) : (
					<AppHeaderDesktopContent
						shouldShowLogoutButton={shouldShowLogoutButton}
					/>
				)}
			</div>
		</div>
	);
};

export default AppHeader;
